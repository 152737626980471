import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loading, Row } from "@cortexglobal/rla-components";

import ErrorColumn from "../../../components/global/ErrorColumn";

import QuizSummary from "./QuizSummary";
import QuizGame from "./QuizGame";
import { loadWeeklyQuiz, startQuiz, submitQuizAnswer } from "./quizActions";

class WeeklyQuiz extends Component {
    state = { error: false };
    componentDidMount() {
        this.props.loadWeeklyQuiz().catch((e) => {
            if (e.response && e.response.status && e.response.status === 500) {
                this.setState((state) => ({
                    error: e.response.data.message,
                }));
            } else {
                this.setState((state) => ({
                    error: "An error occurred while loading the quiz",
                }));
            }
        });
    }

    renderQuiz = (currentQuiz) => {
        const { error } = this.state;

        if (error) {
            return (
                <Row>
                    <ErrorColumn errorText={error} />
                </Row>
            );
        }
        if (!currentQuiz.loaded) {
            return (
                <Row>
                    <Loading align="center" />
                </Row>
            );
        }

        if (currentQuiz.completed) {
            return <QuizSummary currentQuiz={currentQuiz} />;
        }
        if (!currentQuiz.completed) {
            return (
                <QuizGame
                    quiz={currentQuiz}
                    submitQuizAnswer={this.props.submitQuizAnswer}
                    startQuiz={this.props.startQuiz}
                />
            );
        }
    };
    render() {
        const { currentQuiz } = this.props;
        return <div className="page">{this.renderQuiz(currentQuiz)}</div>;
    }
}

const mapStateToProps = (state) => {
    const { current: currentQuiz } = state.quiz;

    return { currentQuiz };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        { loadWeeklyQuiz, startQuiz, submitQuizAnswer },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyQuiz);
