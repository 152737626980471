import React, { Component } from "react";
import styled from "styled-components";

const ProgressBar = styled.div`
position:relative;
width:100%;
overflow:hidden;
margin-bottom:5em;
background: #ff0080; /* Old browsers */
    background: -moz-linear-gradient(
        left,
        #ff0080 0%,
        #ffeb00 100%
    );
    background: -webkit-linear-gradient(
        left,
        #ff0080 0%,
        #ffeb00 100%
    );
    background: linear-gradient(
        to right,
        #ff0080 0%,
        #ffeb00 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0080', endColorstr='#ffeb00',GradientType=1 ); /* IE6-9 */
height:20px;
span {
    height:20px
    display:inline-block;
    transition:all 1s ease;
    background:#5d6878;

    position:absolute;
    width:100%;

    //if using regular animation version use the below line
    right: -${props => props.percentageWidth}%;
    // otherwise use the keyframes block below
    // @keyframes run {
    //    0% { right: -100%;}
    //    100%{ right: 0;}
    // }
    // animation:linear;
    // animation-name: run;
    // animation-duration: ${props => props.percentageWidth}s;
}`;

class GameCountdown extends Component {
    calculateProgress = (currentSeconds, totalSeconds) => {
        const percentageComplete = (currentSeconds / totalSeconds) * 100;
        //console.log(currentSeconds, totalSeconds, percentageComplete);
        return percentageComplete;
    };
    render() {
        //console.log(this.props);
        const { currentSeconds, totalSeconds } = this.props;
        return (
            <ProgressBar
                percentageWidth={this.calculateProgress(
                    currentSeconds,
                    totalSeconds
                )}
            >
                <span />
            </ProgressBar>
        );
    }
}

export default GameCountdown;
