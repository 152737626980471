import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { StyledLink } from "@cortexglobal/rla-components";
import { Row, Column, Loading } from "@cortexglobal/rla-components";

import { getNewsSummaries } from "./newsActions";

import NewsFilter from "./NewsFilter";

const Thumbnail = styled.div`
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 0;
    margin-bottom: 20px;

    img {
        width: 100%;
        aspect-ratio: 165/170;
        object-fit: cover;

        @media screen and (min-width: ${(props) =>
                props.theme.breakpoints.large}px) {
            aspect-ratio: 386/170;
            min-height: 170px;
        }
    }
`;

const News = styled.article`
    position: relative;

    h4 {
        font-family: "bmw_groupbold";
        font-size: 14px;
        color: #000;
        margin-bottom: 10px;
    }

    p {
        color: #9f9f9f;
        font-size: 12px;
    }

    &.feature {
        ${Thumbnail} {
            img {
                aspect-ratio: 386/170;
            }
        }
    }

    > a {
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: ${(props) => props.theme.colors.primary};
        text-decoration: underline;
    }

    @media screen and (min-width: ${(props) =>
            props.theme.breakpoints.large}px) {
        margin-bottom: 20px;

        > a {
            opacity: 1;
            position: static;
        }
    }
`;

class NewsSection extends Component {
    componentWillMount() {
        if (!this.props.loaded) {
            this.props.getNewsSummaries();
        }
    }

    render() {
        const { data, loaded } = this.props;
        console.log({ data, loaded });
        return (
            <Row>
                <Column small={12} style={{ position: "relative" }}>
                    <NewsFilter onChange={this.props.getNewsSummaries} />
                </Column>
                <Row>
                    {loaded ? (
                        data.length === 0 ? (
                            <div>No relevant news found</div>
                        ) : (
                            data.map((story, index) => {
                                if (index === 0) {
                                    return (
                                        <Column large={4} key={index}>
                                            <News className="feature">
                                                <Thumbnail>
                                                    <img
                                                        loading="lazy"
                                                        src={story.thumbnail}
                                                        alt=""
                                                    />
                                                </Thumbnail>
                                                <h4>{story.title}</h4>
                                                <p>{story.excerpt}</p>
                                                <StyledLink
                                                    to={`/news/${story.uuid}`}
                                                >
                                                    {trans("Read more...")}
                                                </StyledLink>
                                            </News>
                                        </Column>
                                    );
                                }
                                return (
                                    <Column small={6} large={4} key={index}>
                                        <News>
                                            <Thumbnail>
                                                <img
                                                    loading="lazy"
                                                    src={story.thumbnail}
                                                    alt=""
                                                />
                                            </Thumbnail>
                                            <h4>{story.title}</h4>
                                            <p>{story.excerpt}</p>
                                            <StyledLink
                                                to={`/news/${story.uuid}`}
                                            >
                                                {trans("Read more...")}
                                            </StyledLink>
                                        </News>
                                    </Column>
                                );
                            })
                        )
                    ) : (
                        <Loading />
                    )}
                </Row>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    const { loaded, data } = state.news.summaries;
    return {
        loaded,
        data,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getNewsSummaries }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsSection);
