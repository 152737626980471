import axios from "axios";

export const GET_RETAILERS = "GET_RETAILERS";

export function getRetailerGroups() {
    return dispatch => {
        axios
            .get("/api/v1/retailers")
            .then(({ data: { data } }) => {
                dispatch({
                    type: GET_RETAILERS,
                    data
                });
            })
            .catch(e => e);
    };
}
