import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { SectionTitle } from "@cortexglobal/bmw-shared-components";
import { Card, CardLabel } from "./cardComponents";
import CarsImg from "../../img/homepage-cards/quiz-cars.png";
import styled from "styled-components";

import { loadCurrentCompetition } from "../../modules/competition/competitionActions";
const CardLink = styled(Link)`
    text-decoration: none;
`;
class MonthlyCompetitionCard extends Component {
    componentDidMount() {
        if (!this.props.current.loaded) {
            this.props.loadCurrentCompetition().catch(e => console.log(e));
        }
    }
    render() {
        const competitionUrl = this.props.current.loaded
            ? this.props.current.data.thumbnail
            : CarsImg;
        return (
            <React.Fragment>
                <Card
                    className="slit-in-vertical"
                    style={{
                        backgroundImage: `url(${competitionUrl})`,
                        animationDelay: "500ms"
                    }}
                >
                    <CardLink to="/competitions">
                        <p>Competitions</p>
                    </CardLink>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ current: state.competition.current });

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadCurrentCompetition }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthlyCompetitionCard);
