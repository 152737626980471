import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading } from "@cortexglobal/rla-components";

import SummaryLinkBack from "../../components/SummaryLinkBack";

import UserSummary from "../../components/global/UserSummary";
import QuizEntries from "../game/quiz/QuizEntries";
import QuizHistoryOverall from "../game/quiz/QuizHistoryOverall";
import QuizHistoryQuarter from "../game/quiz/QuizHistoryQuarter";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import InfoCards from "../../components/infoCards/InfoCards";
import { loadQuizHistory } from "../game/quiz/quizActions";

class ProfileQuiz extends Component {
    componentDidMount() {
        if (!this.props.history.loaded) {
            this.props.loadQuizHistory();
        }
    }
    render() {
        const { history } = this.props;
        console.log(history);
        if (!this.props.history.loaded || this.props.history.loading) {
            return <Loading align="center" />;
        }
        return (
            <div className="page page-profile">
                <UserSummary />
                <Row>
                    <Column>
                        <SummaryLinkBack to="/profile">
                            <PageTitle
                                title="My weekly quiz stats"
                                subtitle="What's your knowledge like?"
                            />
                        </SummaryLinkBack>
                    </Column>
                </Row>

                <Row>
                    <Column large={12} xlarge={8}>
                        <QuizHistoryOverall history={history} />
                    </Column>
                </Row>
                <Row equaliseChildHeight>
                    <Column
                        large={6}
                        xlarge={4}
                        style={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <QuizEntries history={history} />
                    </Column>
                    <Column large={6} xlarge={4}>
                        {" "}
                        <QuizHistoryQuarter history={history} />
                    </Column>
                </Row>
                <Row
                    style={{
                        marginTop: "3rem",
                        paddingTop: "1rem"
                    }}
                >
                    <Column>
                        <div
                            style={{
                                borderTop: "1px solid rgba(0,0,0,0.1)"
                            }}
                        />
                    </Column>
                </Row>
                <InfoCards />
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { history } = state.quiz;

    return { history };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadQuizHistory }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileQuiz);
