import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { PullRow, Column } from "@cortexglobal/rla-components";
import { getNextWeekDay } from "@cortexglobal/cortex-utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "@cortexglobal/bmw-shared-components";

import { SectionTitle } from "@cortexglobal/bmw-shared-components";
import find from "lodash/find";

const WeekRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`;
const WeekColumn = styled(Column)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`;
class QuizEntries extends Component {
    // state = { weeks: [] };
    // componentDidMount() {
    //     this.generateWeeklyStats(this.props.history.data);
    // }
    // getNextWeekDay = (startDate, isoWeekday = 1) => {
    //     const startDay = startDate.isoWeekday();

    //     // if we haven't yet passed the day of the week that I need:
    //     if (startDay <= isoWeekday) {
    //         // then just give me this week's instance of that day
    //         return moment().isoWeekday(isoWeekday);
    //     } else {
    //         // otherwise, give me *next week's* instance of that same day
    //         return moment()
    //             .add(1, "weeks")
    //             .isoWeekday(isoWeekday);
    //     }
    // };
    generateWeeklyStats = (history) => {
        //The month counter start day is the first of the month that the monday of the current week falls in
        const startOfThisWeek = moment().startOf("week");
        let weekCounter = getNextWeekDay(startOfThisWeek.startOf("month"));
        const monthIndex = startOfThisWeek.month();

        const weeks = [];
        let weekNumber = 1;
        while (weekCounter.month() === monthIndex) {
            weeks.push({
                weekNumber: weekNumber,
                weekCommencing: weekCounter.format("YYYY-MM-DD"),
                data: find(history, [
                    "week_commencing",
                    weekCounter.format("YYYY-MM-DD"),
                ]),
            });
            weekCounter.add(1, "week");
            weekNumber++;
        }
        return weeks;
    };

    render() {
        const weeks = this.generateWeeklyStats(this.props.history.data);

        return (
            <React.Fragment>
                <PullRow>
                    <Column>
                        <SectionTitle
                            title="Current competition"
                            subtitle="How many entries have you got?"
                        />
                    </Column>
                </PullRow>
                <WeekRow>
                    {weeks.map((week) => {
                        return (
                            <WeekColumn
                                key={week.weekNumber}
                                colCount={weeks.length}
                            >
                                {week.data &&
                                    [...week.data.questions]
                                        .reverse()
                                        .map((question) => {
                                            return (
                                                <div
                                                    key={question.order}
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon="square"
                                                        transform="grow-3"
                                                        style={{
                                                            marginBottom:
                                                                "0.2rem",
                                                            fontSize: "2rem",
                                                            color: question.correct
                                                                ? colors.altSuccess
                                                                : colors.alert,
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={
                                                            question.correct
                                                                ? "check"
                                                                : "times"
                                                        }
                                                        style={{
                                                            color: colors.white,
                                                            marginTop: "6px",
                                                            marginLeft:
                                                                question.correct
                                                                    ? "-23px"
                                                                    : "-20px",
                                                            position:
                                                                "absolute",
                                                            fontSize: "1.2rem",
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                Week: {week.weekNumber}
                            </WeekColumn>
                        );
                    })}
                </WeekRow>
            </React.Fragment>
        );
    }
}

export default QuizEntries;
