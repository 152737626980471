import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Column, Row } from "@cortexglobal/rla-components";
import { SectionTitle } from "@cortexglobal/bmw-shared-components";
import { Card, CardLabel } from "./cardComponents";
import styled from "styled-components";
import { get } from "lodash";

import PrizeDrawImage from "../../img/homepage-cards/November-Prize-BMW-Sweat-Jacket.jpg";
import { loadCompetitionHistory, loadCurrentCompetition } from "../../modules/competition/competitionActions";

const CardLink = styled(Link)`
    text-decoration: none;
`;

class HomepageCards extends Component {
    state = {
        currentCompetitionError: false
    };

    render() {
        const image = get(this.props, 'current.data.thumbnail', PrizeDrawImage);

        return (
            <Row style={{ marginBottom: "50px" }}>
                <Card
                    style={{
                        backgroundImage: `url(${image})`,
                        animationDelay: "0ms"
                    }}
                    className="slit-in-vertical"
                >
                    <CardLink to="monthly-prize-draw">
                        <p>Monthly Prize Draw</p>
                    </CardLink>
                </Card>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return { loggedIn: state.auth.loggedIn, current: state.competition.current };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { loadCompetitionHistory, loadCurrentCompetition },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomepageCards);