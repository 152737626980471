import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import SummaryLinkBack from "../../components/SummaryLinkBack";

import UserSummary from "../../components/global/UserSummary";
import { PageTitle, colors } from "@cortexglobal/bmw-shared-components";
import InfoCards from "../../components/infoCards/InfoCards";
import ErrorColumn from "../../components/global/ErrorColumn";
import CompetitionSummary from "../competition/CompetitionSummary";
import {
    loadCompetitionHistory,
    loadCurrentCompetition
} from "../competition/competitionActions";

const CompetitionSummaryColumn = styled(Column)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
class ProfileCompetition extends Component {
    state = {
        currentCompetitionError: false
    };
    componentDidMount() {
        if (!this.props.history.loaded) {
            this.props.loadCompetitionHistory();
        }
        this.props.loadCurrentCompetition().catch(e => {
            //console.log(e);
            if (e.response && e.response.status && e.response.status === 404) {
                this.setState({
                    currentCompetitionError: e.response.data.message
                });
            }
        });
    }

    renderCurrentCompetition = () => {
        const { currentCompetitionError } = this.state;
        const { current, user } = this.props;
        if (currentCompetitionError) {
            return (
                <ErrorColumn
                    errorText={currentCompetitionError}
                    background={colors.primary}
                />
            );
        }

        if (!current.loaded) {
            return <Loading align="center" />;
        } else {
            return (
                <Column large={8}>
                    <CompetitionSummary
                        competition={current.data}
                        user={user}
                        current
                    />
                </Column>
            );
        }
    };
    render() {
        const { history, user } = this.props;
        if (!history.loaded || history.loading) {
            return <Loading align="center" />;
        }
        return (
            <div className="page">
                <UserSummary />
                <Row>
                    <Column>
                        <SummaryLinkBack to="/profile">
                            <PageTitle
                                title="My competition stats"
                                subtitle="Check your competition entries?"
                            />
                        </SummaryLinkBack>
                    </Column>
                </Row>

                <Row>{this.renderCurrentCompetition()}</Row>

                <Row equaliseChildHeight>
                    {history.data.map(competition => {
                        return (
                            <CompetitionSummaryColumn
                                key={competition.uuid}
                                large={4}
                            >
                                <CompetitionSummary
                                    key={competition.uuid}
                                    competition={competition}
                                    user={user}
                                />
                            </CompetitionSummaryColumn>
                        );
                    })}
                </Row>
                <Row
                    style={{
                        marginTop: "3rem",
                        paddingTop: "1rem",
                        borderTop: "1px solid rgba(0,0,0,0.1)"
                    }}
                >
                    <InfoCards />
                </Row>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { history, current } = state.competition;
    const { user } = state.auth;

    return { history, current, user };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { loadCompetitionHistory, loadCurrentCompetition },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileCompetition);
