import React, { Component } from "react";
import { PullRow, Column } from "@cortexglobal/rla-components";
import CompetitionEntrySummary from "./CompetitionEntrySummary";
import {
    CompetitionTitle,
    CompetitionPrize,
    CompetitionTitleWrapperColumn,
    PrizeImage
} from "../../components/competitionComponents";
class CompetitionSummary extends Component {
    render() {
        const {
            current,
            competition: { name, prizes, thumbnail }
        } = this.props;

        return (
            <React.Fragment>
                <PullRow equaliseChildHeight>
                    <CompetitionTitleWrapperColumn>
                        <CompetitionTitle current={current}>
                            {name} {current && <span>Current Competition</span>}
                        </CompetitionTitle>
                        {prizes.map(prize => {
                            return (
                                <CompetitionPrize
                                    key={prize.name + prize.quantity}
                                >
                                    {prize.quantity} x {prize.name}
                                </CompetitionPrize>
                            );
                        })}
                    </CompetitionTitleWrapperColumn>
                </PullRow>
                <PullRow equaliseChildHeight>
                    <Column small={6} style={{ position: "relative" }}>
                        <PrizeImage thumbnail={thumbnail} />
                        {/* <CompetitionImage src={thumbnail} alt={name} /> */}
                    </Column>
                    <Column small={6}>
                        <CompetitionEntrySummary
                            competition={this.props.competition}
                            user={this.props.user}
                        />
                    </Column>
                </PullRow>
            </React.Fragment>
        );
    }
}

export default CompetitionSummary;
