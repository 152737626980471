import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
// import CountTo from "react-count-to";

import { Row, Column, Button, Loading } from "@cortexglobal/rla-components";
import { PageTitle, formatPosition } from "@cortexglobal/bmw-shared-components";

import { loadBestComparisonGame } from "./comparisonActions";
import ComparisonLeagueSummary from "./ComparisonLeagueSummary";
import ThisOrThatScore from "../../../components/ThisOrThatScore";

const ComparisonSummaryWrapper = styled.div`
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
`;

const ComparisonLeagueButtonRow = styled(Row)`
    max-width: 40rem;
    min-width: 350px;
    margin: 2rem 0 4rem 0;
    justify-self: flex-end;
`;
class ComparisonComplete extends Component {
    state = {
        summaryShowing: true
    };
    componentDidMount() {
        if (!this.props.bestGame.loaded) {
            this.props.loadBestComparisonGame();
        }
    }

    renderSummary = () => {
        const { current, bestGame } = this.props;

        if (current.total > bestGame.total) {
            return (
                <div>
                    <h2>High Score</h2>
                    <ThisOrThatScore
                        displayAsPosition={false}
                        score={current.total}
                    />
                    <h3>
                        You've beaten your previous high score! Click below to
                        view the leagues.
                    </h3>
                </div>
            );
        }
        return (
            <div>
                <h2>Your Score</h2>
                <ThisOrThatScore
                    displayAsPosition={false}
                    score={current.total}
                />
                <h3>
                    You didn't beat your previous high score. Click below to
                    view the leagues.
                </h3>
            </div>
        );
    };
    render() {
        const { summaryShowing } = this.state;

        const { current, bestGame } = this.props;
        if (current.loading || bestGame.loading) {
            return <Loading />;
        }

        return (
            <ComparisonSummaryWrapper>
                <Row>
                    <Column />
                </Row>
                {summaryShowing ? (
                    <Row>
                        <Column>{this.renderSummary()}</Column>
                    </Row>
                ) : (
                    <ComparisonLeagueSummary />
                )}
                <ComparisonLeagueButtonRow>
                    <Column medium={summaryShowing ? 6 : 12}>
                        <Button expanded onClick={this.props.restartGame}>
                            Play Again
                        </Button>
                    </Column>
                    {summaryShowing && (
                        <Column medium={6}>
                            <Button
                                expanded
                                type="altGray"
                                onClick={() => {
                                    this.setState(state => ({
                                        summaryShowing: !state.summaryShowing
                                    }));
                                }}
                            >
                                Show league
                            </Button>
                        </Column>
                    )}
                </ComparisonLeagueButtonRow>
            </ComparisonSummaryWrapper>
        );
    }
}

const mapStateToProps = state => {
    const { bestGame } = state.comparison;
    const { user, group } = state.auth;

    return { bestGame, user, group };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadBestComparisonGame }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonComplete);
