import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import styled from "styled-components";

import UserSummary from "../../components/global/UserSummary";
import {
    PageTitle,
    SectionTitle,
    colors
} from "@cortexglobal/bmw-shared-components";
import ErrorColumn from "../../components/global/ErrorColumn";
import CompetitionCards from "../../components/infoCards/CompetitionCards";
import CompetitionDetail from "../competition/CompetitionDetail";
import {
    loadCompetitionHistory,
    loadCurrentCompetition
} from "../competition/competitionActions";


class Competition extends Component {
    state = {
        currentCompetitionError: false
    };
    componentDidMount() {
        if (!this.props.history.loaded) {
            this.props.loadCompetitionHistory();
        }
        this.props.loadCurrentCompetition().catch(e => {
            //console.log(e);
            if (e.response && e.response.status && e.response.status === 404) {
                this.setState(state => ({
                    currentCompetitionError: e.response.data.message
                }));
            }
        });
    }
    renderCurrentCompetition = () => {
        const { currentCompetitionError } = this.state;
        const { current, user } = this.props;
        if (currentCompetitionError) {
            //console.log(currentCompetitionError);
            return (
                <Row>
                    <ErrorColumn
                        errorText={currentCompetitionError}
                        background={colors.primary}
                    />
                </Row>
            );
        }

        if (!current.loaded) {
            return <Loading align="center" />;
        } else {
            return <CompetitionDetail competition={current.data} user={user} />;
        }
    };
    render() {
        const { history, user } = this.props;

        if (!history.loaded || history.loading) {
            return <Loading align="center" />;
        }
        return (
            <div className="page">
                <UserSummary />
                <Row>
                    <Column large={12}>
                        <PageTitle
                            title="Competitions"
                            subtitle="Check out the huge array of competitions on offer."
                        />
                    </Column>
                </Row>
                <Row
                    style={{
                        padding: "0"
                    }}
                >
                    {" "}
                    <CompetitionCards />
                </Row>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { history, current } = state.competition;
    const { user } = state.auth;

    return { history, current, user };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { loadCompetitionHistory, loadCurrentCompetition },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Competition);
