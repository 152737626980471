import React, { Component } from "react";
import styled from "styled-components";
import { Spring } from "react-spring/renderprops";

import { Row, Column } from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemWrapper = styled(Row)`
    @media (max-width: ${props => props.theme.breakpoints.large}px) {
        @media only screen and (max-width: 400px) {
            padding: 0.1rem;
        }
        h1 {
            font-size: 1.2rem;
        }
        h2 {
            font-size: 1rem;
            @media only screen and (max-width: 400px) {
                font-size: 0.8rem;
            }
        }
        h3 {
            font-size: 0.8rem;
        }
    }
`;
const ImageWrapper = styled(Column)`
    padding: 0;
`;
const ComparisonImage = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: linear-gradient(
            ${props => props.overlayColor},
            ${props => props.overlayColor}
        ),
        center no-repeat url(${props => props.image});
    background-size: contain;
    box-shadow: rgba(0, 137, 255, 0.6) 3px 0 30px;
    width: 43vw;
    max-width: 650px !important;
    max-height: 370px;
    height: 24vw;
    svg {
        font-size: 5rem;
        color: rgba(255, 255, 255, 0.65);
    }
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
    }
`;
const ComparisonWording = styled(Column)`
    text-align: center;
    h2 {
        margin-bottom: 0;
    }
    h3 {
        color: ${props => props.theme.colors.mediumGray};
    }
`;

class ComparisonItem extends Component {
    renderAnswer() {
        const { item, displayAnswer, statistic } = this.props;
        if (!displayAnswer) {
            return <h3>&nbsp;</h3>;
        }
        return (
            <h3>
                {statistic.prefix && statistic.prefix + " "}
                {isNaN(item.answer)
                    ? item.answer
                    : Number.parseFloat(item.answer).toFixed(
                          statistic.decimal_places_to_display
                      )}
                {statistic.suffix}
            </h3>
        );
    }
    chooseOverlayColor = () => {
        const {
            item,
            selectedItemUuid,
            correctItemUuid,
            displayAnswer
        } = this.props;
        // console.log(selectedItemUuid, displayAnswer);
        //This is the correct answer
        if (
            displayAnswer &&
            item.uuid === correctItemUuid &&
            (selectedItemUuid === item.uuid || selectedItemUuid === null)
        ) {
            return "rgba(8, 255, 0, 0.44)";
        }
        //This is an incorrect answer
        if (
            displayAnswer &&
            item.uuid !== correctItemUuid &&
            (selectedItemUuid === item.uuid || selectedItemUuid === null)
        ) {
            return "rgba(255, 0, 0, 0.44)";
        }

        //If the question is still displaying but they've selected an answer
        if (selectedItemUuid === item.uuid) {
            return "rgba(0, 0, 0, 0.45)";
        }
        //No need for an overlay
        return "rgba(0, 0, 0, 0.0)";
    };
    render() {
        const {
            item,
            selectedItemUuid,
            correctItemUuid,
            displayAnswer
        } = this.props;

        return (
            <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
                {animationProps => (
                    <div style={animationProps}>
                        <ItemWrapper>
                            <ImageWrapper>
                                <ComparisonImage
                                    image={item.image}
                                    overlayColor={this.chooseOverlayColor()}
                                >
                                    {displayAnswer &&
                                        selectedItemUuid === item.uuid &&
                                        item.uuid === correctItemUuid && (
                                            <FontAwesomeIcon
                                                icon="thumbs-up"
                                                transform="grow-5"
                                            />
                                        )}
                                    {displayAnswer &&
                                        selectedItemUuid === item.uuid &&
                                        item.uuid !== correctItemUuid && (
                                            <FontAwesomeIcon
                                                icon="thumbs-down"
                                                transform="grow-5"
                                            />
                                        )}
                                </ComparisonImage>
                            </ImageWrapper>
                            <ComparisonWording>
                                <h2>{item.name}</h2>
                                {this.renderAnswer()}
                            </ComparisonWording>
                        </ItemWrapper>
                    </div>
                )}
            </Spring>
        );
    }
}

export default ComparisonItem;
