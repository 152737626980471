import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "@cortexglobal/rla-components";

const AnswerButton = styled(Button)`
    opacity: 1;
    font-size: 18px;
    line-height: 22px;
    border: 0;
    height: 80px;
    border-radius: 0;
    box-shadow: none;
    padding: 1.2em;
    height: auto;
    background: ${props => {
        switch (props.status) {
            case "correct":
                return props.theme.colors.altSuccess;
            case "incorrect":
                return props.theme.colors.alert;
            default:
                return props.theme.colors.lightGray;
        }
    }};
    color: ${props => {
        switch (props.status) {
            case "correct":
                return props.theme.colors.white;
            case "incorrect":
                return props.theme.colors.white;
            default:
                return props.theme.colors.black;
        }
    }};
    ${props =>
        props.status !== "answered-not-chosen"
            ? ""
            : "font-weight: normal; color: #9f9f9f;font-size: 16px;line-height: 20px;"};
`;

const AnswerText = styled.span`
    margin-left: 12px;
    ${props =>
        props.status !== "answered-not-chosen"
            ? ""
            : "font-weight: normal; color: #9f9f9f;font-size: 16px;line-height: 20px;"};
`;
class QuizAnswer extends Component {
    submitQuizAnswer = () => {
        this.props.submitQuizAnswer(
            true,
            this.props.questionUuid,
            this.props.answer.uuid
        );
    };

    getQuestionStatus = () => {
        const {
            answer,
            selectedAnswer,
            correctAnswer,
            questionComplete
        } = this.props;

        const thisIsSelected = answer.uuid === selectedAnswer;
        const thisIsTheCorrectAnswer =
            correctAnswer && correctAnswer.uuid === answer.uuid;

        return questionComplete && thisIsTheCorrectAnswer
            ? "correct"
            : questionComplete && thisIsSelected && correctAnswer
            ? "incorrect"
            : !questionComplete
            ? "question-unanswered"
            : "answered-not-chosen";
    };
    render() {
        const { answer, questionComplete } = this.props;

        const status = this.getQuestionStatus();
        return (
            <React.Fragment>
                <AnswerButton
                    expanded
                    hollow
                    onClick={this.submitQuizAnswer}
                    disabled={questionComplete}
                    //data-highlighted={questionComplete && thisIsSelected || correctAnswer || !correctAnswer}
                    status={status}
                >
                    <span>{String.fromCharCode(65 + this.props.index)}.</span>
                    <AnswerText>{answer.answer}</AnswerText>
                </AnswerButton>
            </React.Fragment>
        );
    }
}

export default QuizAnswer;
