import axios from "axios";

export const GETTING_NEWS_SUMMARIES = "GETTING_NEWS_SUMMARIES";
export const GET_NEWS_SUMMARIES = "GET_NEWS_SUMMARIES";

export function getNewsSummaries(date) {
    if (!date) {
        date = `${new Date().toISOString().slice(0, 7)}`;
    }
    return (dispatch) => {
        dispatch({
            type: GETTING_NEWS_SUMMARIES,
        });
        axios
            .get(`/api/v1/news`, {
                params: {
                    date: date,
                },
            })
            .then(({ data: { data } }) => {
                dispatch({
                    type: GET_NEWS_SUMMARIES,
                    data,
                });
            })
            .catch((e) => e);
    };
}

export const GET_NEWS_STORY = "GET_NEWS_STORY";

export function getNewsStory(uuid) {
    // const devMode = process.env.NODE_ENV === "development";
    // const url = devMode
    //     ? `http://localhost:40081/data/news_${uuid}.json`
    //     : `/api/v1/news/${uuid}`;
    return (dispatch) => {
        axios
            .get(`/api/v1/news/${uuid}`)
            .then(({ data: { data } }) => {
                dispatch({
                    type: GET_NEWS_STORY,
                    data,
                });
            })
            .catch((e) => e);
    };
}

export const CLEAR_NEWS_STORY = "CLEAR_NEWS_STORY";

export function clearNewsStory() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_NEWS_STORY,
        });
    };
}

// export const GET_NEWS_SECTIONS = "GET_NEWS_SECTIONS";

// export function getNewsSections(time) {
//     // let url = `http://localhost:40081/data/news.json`;
//     const date = time ? new Date(time) : new Date();
//     const year = date.getFullYear();
//     const month = date.getMonth();
//     let url = `/api/v1/news?year=${year}&month=${month}`;

//     return (dispatch) => {
//         axios
//             .get(url)
//             .then(({ data: { data } }) => {
//                 dispatch({
//                     type: GET_NEWS_SECTIONS,
//                     data,
//                 });
//             })
//             .catch((e) => e);
//     };
// }

export const SELECT_NEWS_TIME = "SELECT_NEWS_TIME";

export function selectTime(time) {
    return (dispatch) => {
        dispatch({
            type: SELECT_NEWS_TIME,
            selectedTime: time,
        });
    };
}
