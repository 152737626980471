import React, { Component } from "react";
import styled from "styled-components";
import {
    Badge,
    BadgeName,
    BadgeDescription
} from "@cortexglobal/cortex-badges";

const BadgeColumn = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
`;

const CentredDiv = styled.div`
    text-align: center;
    width: 33%;
    margin-bottom: 20px;
    padding: 0 8px;
`;

class BadgesGrid extends Component {
    progress(badge) {
        if (badge.progress > 0) {
            return badge.progress + "%";
        }
        return "1%";
    }

    render() {
        const { badges } = this.props;
        return (
            <BadgeColumn small={6} medium={12}>
                {badges.map(badge => {
                    const achieved = badge.achievement_count > 0;
                    return (
                        <CentredDiv key={badge.badge_uuid}>
                            <Badge badge={badge} />
                            <BadgeName
                                style={{ lineHeight: "1.3em" }}
                                achieved={achieved}
                            >
                                {badge.name}
                            </BadgeName>
                            <BadgeDescription>
                                {badge.description}
                            </BadgeDescription>
                            {/* <div>
                                    <div
                                        style={{
                                            height: "5px",
                                            width: this.progress(badge),
                                            backgroundColor: "#1C69D4"
                                        }}
                                    />
                                </div> */}
                        </CentredDiv>
                    );
                })}
            </BadgeColumn>
        );
    }
}

export default BadgesGrid;
