import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import styled from "styled-components";

import UserSummary from "../../components/global/UserSummary";
import {
    PageTitle,
    SectionTitle,
    colors
} from "@cortexglobal/bmw-shared-components";
import ErrorColumn from "../../components/global/ErrorColumn";
import InfoCards from "../../components/infoCards/InfoCards";
import CompetitionSummary from "../competition/CompetitionSummary";
import CompetitionDetail from "../competition/CompetitionDetail";
import {
    loadCompetitionHistory,
    loadCurrentCompetition
} from "../competition/competitionActions";

const CompetitionSummaryColumn = styled(Column)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
class Competition extends Component {
    state = {
        currentCompetitionError: false
    };
    componentDidMount() {
        if (!this.props.history.loaded) {
            this.props.loadCompetitionHistory();
        }
        this.props.loadCurrentCompetition().catch(e => {
            //console.log(e);
            if (e.response && e.response.status && e.response.status === 404) {
                this.setState(state => ({
                    currentCompetitionError: e.response.data.message
                }));
            }
        });
    }
    renderCurrentCompetition = () => {
        const { currentCompetitionError } = this.state;
        const { current, user } = this.props;
        if (currentCompetitionError) {
            //console.log(currentCompetitionError);
            return (
                <Row>
                    <ErrorColumn
                        errorText={currentCompetitionError}
                        background={colors.primary}
                    />
                </Row>
            );
        }

        if (!current.loaded) {
            return <Loading align="center" />;
        } else {
            return <CompetitionDetail competition={current.data} user={user} />;
        }
    };
    render() {
        const { history, user } = this.props;

        if (!history.loaded || history.loading) {
            return <Loading align="center" />;
        }
        return (
            <div className="page">
                <UserSummary />
                <Row>
                    <Column>
                        <PageTitle
                            title="My competition stats"
                            subtitle="Check your competition entries"
                        />
                    </Column>
                </Row>

                <Row>{this.renderCurrentCompetition()}</Row>

                <Row>
                    <Column>
                        <SectionTitle
                            title="Monthly prize draw history"
                            subtitle="How did you fare in months gone by?"
                        />
                    </Column>
                </Row>
                <Row equaliseChildHeight>
                    {history.data.length > 0 ? (
                       history.data.reverse().map(competition => {
                            return (
                                <CompetitionSummaryColumn
                                    key={competition.uuid}
                                    large={4}
                                >
                                    <CompetitionSummary
                                        key={competition.uuid}
                                        competition={competition}
                                        user={user}
                                    />
                                </CompetitionSummaryColumn>
                            );
                        })
                    ) : (
                        <Column>
                            <h5>You currently have no competition history</h5>
                        </Column>
                    )}
                </Row>
                <Row
                    style={{
                        marginTop: "3rem",
                        paddingTop: "1rem",
                        borderTop: "1px solid rgba(0,0,0,0.1)"
                    }}
                ></Row>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { history, current } = state.competition;
    const { user } = state.auth;

    return { history, current, user };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { loadCompetitionHistory, loadCurrentCompetition },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Competition);
