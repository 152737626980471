import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0.5rem 0;
    background: #ebebeb;
    display: none;
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        display: block;
        padding-top: 80px;
        justify-content: flex-start !important;
        flex-direction: column;
        width: 80px;
        height: 100%;
    }
`;

const Link = styled(NavLink)`
    flex: 1;
    color: #656565;
    text-align: center;
    font-size: 0.8rem;
    text-decoration: none;
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    &:last-of-type {
        border-right: none;
    }
    padding-top: 10px;
    &.active {
        color: #1c69d4;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        flex: none;
        display: block;
        height: 80px;
        border-right: none;
    }
`;

const Icon = styled.div`
    display: block;
    margin: auto;
    font-size: 1.5rem;
    padding-top: 10px;
    &.active {
        color: #1c69d4;
    }
`;

const QuickLinks = ({
    mobileView,
    userCanViewRewards,
    userCanViewCareerPathway,
}) => {
    return (
        <Wrapper mobileView={mobileView}>
            <Link exact to="/">
                <Icon className="fa-layers fa-fw">
                    <FontAwesomeIcon icon="home" />
                </Icon>{" "}
                Home
            </Link>
            <Link exact to="/games">
                <Icon className="fa-layers fa-fw">
                    <FontAwesomeIcon icon="gamepad" />
                </Icon>{" "}
                Games
            </Link>
            {userCanViewRewards && (
                <Link exact to="/redeem">
                    <Icon className="fa-layers fa-fw">
                        <FontAwesomeIcon icon="pound-sign" />
                    </Icon>{" "}
                    Redeem
                </Link>
            )}
            {userCanViewCareerPathway && (
                <Link exact to="/career-pathway">
                    <Icon className="fa-layers fa-fw">
                        <FontAwesomeIcon icon="suitcase" />
                    </Icon>{" "}
                    Career
                </Link>
            )}
            <Link exact to="/news">
                <Icon className="fa-layers fa-fw">
                    <FontAwesomeIcon icon="broadcast-tower" />
                </Icon>{" "}
                Resources
            </Link>
        </Wrapper>
    );
};

export default QuickLinks;
