import React, { Component } from "react";
import { Row, Column } from "@cortexglobal/rla-components";
import { QUESTION_COUNTDOWN_START } from "./comparisonReducer";
import GameCountdown from "../../../components/countdowns/GameCountdown";
import ComparisonChoice from "./ComparisonChoice";
import QuestionRow from "./QuestionRow";
import ThisOrThatHeader from "../../../components/ThisOrThatHeader";

class ComparisonQuestion extends Component {
    render() {
        const { current } = this.props;
        return (
            <div className="page">
                <Row center>
                    <Column>
                        <ThisOrThatHeader />
                    </Column>
                </Row>
                <QuestionRow
                    question={current.currentComparison.statistic.question}
                    total={current.total}
                />
                <Row center>
                    {current.currentComparison.items.map(item => {
                        return (
                            <Column small={6} key={item.uuid}>
                                <ComparisonChoice
                                    item={item}
                                    statistic={
                                        current.currentComparison.statistic
                                    }
                                    selectedAnswerUuid={
                                        current.selectedAnswerUuid
                                    }
                                    submitAnswer={this.props.submitAnswer}
                                />
                            </Column>
                        );
                    })}
                </Row>

                <Row center>
                    <Column>
                        <p
                            style={{
                                marginBottom: "0rem"
                            }}
                        >
                            Time remaining: {current.questionCountdownTimer}
                        </p>
                        <GameCountdown
                            currentSeconds={current.questionCountdownTimer}
                            totalSeconds={QUESTION_COUNTDOWN_START}
                        />
                    </Column>
                </Row>
            </div>
        );
    }
}

export default ComparisonQuestion;
