import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyledLink, Loading, Row } from "@cortexglobal/rla-components";
import AppContext from "./AppContext";
import UserDetails from "./UserDetails";
import MyBadgesSummary from "../../modules/badges/MyBadgesSummary";

const Wrapper = styled.div`
    overflow: auto;
    background: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const Rewards = styled.div`
    min-height: 3rem;
    padding: 1rem;
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    text-align: center;
    font-size: 0.7rem;
    p {
        margin: 0;
    }
    h2 {
        margin: 0;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
    }
    a {
        text-decoration: underline;
        font-weight: normal;
    }
`;

const UserSummary = ({ userCanViewRewards }) => {
    return (
        <AppContext.Consumer>
            {({ user, group, points }) => {
                //console.log(user);
                return (
                    <Row collapse>
                        <Wrapper>
                            <UserDetails />
                            {/* <BadgeSummaryWrapper to="/profile/badges">
                                    <MyBadgesSummary />
                                </BadgeSummaryWrapper> */}
                            {userCanViewRewards && (
                                <Rewards>
                                    {!points.loaded ? (
                                        <Loading width="30px" height="30px" />
                                    ) : (
                                        <React.Fragment>
                                            <p>My Rewards</p>
                                            <h2>£{points.stats.balance}</h2>
                                            <StyledLink to="/redeem">
                                                View/Redeem
                                            </StyledLink>
                                        </React.Fragment>
                                    )}
                                </Rewards>
                            )}
                        </Wrapper>
                    </Row>
                );
            }}
        </AppContext.Consumer>
    );
};
export default UserSummary;
