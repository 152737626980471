import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Row, Column, Loading } from "@cortexglobal/rla-components";

import { getPointsSummary } from "../points/pointsActions";
import { submitAmazonOrderPoints } from "./redemptionActions";

import Confirmation from "./Confirmation.jsx";
import OrderForm from "./OrderForm.jsx";
import { PageTitle, Hero } from "@cortexglobal/bmw-shared-components";
import HeroImg from "../../img/homepage-cards/redeem-amazon.png";
import RedemptionHistory from "./RedemptionHistory";
import { colors } from "../../config/theme";
// import Error from "./Error";

// const Hero = styled.div`
//     background: url(${HeroImg});
//     background-size: cover;
//     background-position: 10% 65%;
//     max-width: 100%;
//     min-height: 140px;
//     @media (min-width: ${props => props.theme.breakpoints.large}px) {
//         margin: 1rem;
//         width: 66%;
//         border-radius: 5px;
//     }
// `;

const Error = styled.p`
    color: ${colors.alert};
    text-align: center;
`;

const initialState = {
    // currentPoints: 0,
    // pointsToRedeem: 0,
    // newPoints: 0,
    // amazonValue: 0,
    order: {
        placed: false
    }
};
class Redemption extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    // componentWillReceiveProps(nextProps) {
    //     //if (nextProps.points.stats.total) {
    //     this.setState({
    //         newPoints: nextProps.points.stats.balance,
    //         currentPoints: nextProps.points.stats.balance
    //     });
    //     //}
    // }
    componentWillMount() {
        this.props.getPointsSummary();
    }

    // componentWillUnmount() {
    //     this.props.resetOrder();
    //     // add padding from outer container
    // }

    submitAmazonOrderPoints(points) {
        this.setState({ order: { placed: true } });
        this.props.submitAmazonOrderPoints(points);
        // alert("Redeeming points is unavailable in demo mode");
    }

    resetOrder = () => {
        this.props.getPointsSummary();
        this.setState({ ...initialState });
    };

    renderRedemptionStage() {
        if (this.props.redemption.processing) {
            return (
                <div align="center">
                    <Loading />
                    Please wait while we process your redemption.
                </div>
            );
        }

        if (!this.props.points.loaded || this.props.points.loading) {
            return <Loading align="center" />;
        }

        if (this.props.points.stats.balance <= 0) {
            // Account for full balance withdrawls...
            if (
                this.props.redemption.order.hasOwnProperty("ref") &&
                this.props.redemption.order.ref.constructor === String
            ) {
                return (
                    <React.Fragment>
                        <Error>You have nothing else available to redeem</Error>
                        <Confirmation
                            resetOrder={this.resetOrder}
                            redemption={this.props.redemption}
                            furtherRedemptions={false}
                        />
                    </React.Fragment>
                );
            } else {
                return <Error>You currently have nothing to redeem.</Error>;
            }
        }

        if (
            this.state.order.placed === false ||
            this.props.redemption.error === true
        ) {
            return (
                <div>
                    {this.props.redemption.error === true && (
                        <Error>
                            {this.props.redemption.errorMessage ||
                                "There was an error while redeeming with Amazon. Please try again later."}
                        </Error>
                    )}
                    <OrderForm
                        submitAmazonOrderPoints={this.submitAmazonOrderPoints.bind(
                            this
                        )}
                        maxPoints={this.props.points.stats.balance}
                        user={this.props.user}
                    />
                </div>
            );
        }

        return (
            <Confirmation
                resetOrder={this.resetOrder}
                redemption={this.props.redemption}
                furtherRedemptions={true}
            />
        );
    }

    render() {
        return (
            <div className="page page-redeem">
                <Hero backgroundImage={HeroImg} backgroundPosition="10% 65%" />
                <Row>
                    <Column>
                        <PageTitle
                            title="Redeem"
                            subtitle="Redeem your rewards as Amazon.co.uk vouchers"
                        />
                    </Column>
                </Row>

                {this.renderRedemptionStage.apply(this)}

                <RedemptionHistory />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { user } = state.auth;
    const { points, redemption } = state;
    return {
        user,
        points,
        redemption
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getPointsSummary,
            submitAmazonOrderPoints
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Redemption);
