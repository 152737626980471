import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loading } from "@cortexglobal/rla-components";
import { loadBadgeSummary } from "../badges/badgeActions";
import styled from "styled-components";
import { Badge } from "@cortexglobal/cortex-badges";

const BadgeColumn = styled.div`
    //float: right;
    //width: 24.3333%;
    text-align: center;
    padding: 0 5%;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    &:nth-of-type(2) {
        // border-right: 1px solid #dedede;
        // border-left: 1px solid #dedede;
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 1px;
            background: #dedede;
            //height: calc(100% - 15px);
            height: 100%;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
`;

const BadgeDetail = styled.h2`
    font-size: 12px;
    color: #fff;
    clear: both;
    text-align: center;
    position: absolute;
    top: 53%;
    transform: translateY(-50%);
    text-shadow: 0 0 2px #000;
    line-height: 1;
`;

class MyBadgesSummary extends Component {
    componentDidMount() {
        if (!this.props.badgeSummary.loaded) {
            this.props.loadBadgeSummary();
        }
    }

    render() {
        const { badgeSummary } = this.props;
        if (!badgeSummary.loaded) {
            return <Loading align="center" />;
        }
        return (
            <React.Fragment>
                {badgeSummary.data.map(badge => {
                    return (
                        <BadgeColumn key={badge.name}>
                            <Badge
                                badge={badge}
                                forceFullOpacity={true}
                                style={{ width: "100%", maxWidth: "45px" }}
                            />
                            <BadgeDetail>{badge.awarded}</BadgeDetail>
                        </BadgeColumn>
                    );
                })}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { badgeSummary } = state.badges;

    return { badgeSummary };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadBadgeSummary }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyBadgesSummary);
