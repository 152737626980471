import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    LoginForm,
    HeroColumn,
    Hero,
    ContentColumn,
} from "../../components/auth";
import { Row, Button } from "@cortexglobal/rla-components";

import { PageTitle } from "@cortexglobal/bmw-shared-components";

class Login extends Component {
    render() {
        return (
            <div className="page page-login">
                <Row collapse equaliseChildHeight>
                    <HeroColumn collapse>
                        <Hero />
                    </HeroColumn>
                    <ContentColumn>
                        <div className="form-container">
                            <PageTitle title="Welcome to the BMW Group Elite Portal" />
                            <LoginForm location={this.props.location} />
                            <Link
                                className="terms-conditions"
                                to="/auth/forgotten"
                            >
                                Forgotten your password...
                            </Link>

                            <Button
                                expanded
                                type="darkGray"
                                height={40}
                                element={Link}
                                to="/auth/request-account"
                                style={{ marginTop: "1rem" }}
                            >
                                Create an Account
                            </Button>
                        </div>
                    </ContentColumn>
                </Row>
            </div>
        );
    }
}

export default Login;
