import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Column, Button, Loading } from "@cortexglobal/rla-components";

const SpacedButton = styled(Button)`
    margin-bottom: 1rem;
`;

const GiftCode = styled.textarea`
    width: 100%;
    border: none;
    background: none;
    resize: none;
`;

const StyledRow = styled(Row)`
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        width: 66%;
    }
`;
class Confirmation extends React.Component {
    state = {
        copySuccess: false
    };
    copyToClipboard = e => {
        this.giftCode.select();
        document.execCommand("copy");
        // Refocus the button to deselect the highlighted text
        e.target.focus();
        this.setState({ copySuccess: true });
    };

    render() {
        const { redemption, furtherRedemptions } = this.props;
        if (redemption.processing) {
            return <Loading align="center" />;
        }

        return (
            <StyledRow>
                <Column>
                    <p>
                        Your Amazon Gift Card has been created and will be
                        emailed to your email address. You can also copy the
                        Gift Card code below, please note this will not be
                        displayed again.
                    </p>
                    <Column large={10}>
                        <GiftCode
                            ref={giftCode => (this.giftCode = giftCode)}
                            value={redemption.order.ref}
                            readonly
                            autocomplete="off"
                            autocorrect="off"
                            autocapitalize="off"
                            spellcheck="false"
                        />
                    </Column>

                    <Column large={2}>
                        {document.queryCommandSupported("copy") && (
                            <React.Fragment>
                                <SpacedButton onClick={this.copyToClipboard}>
                                    <FontAwesomeIcon icon="clipboard" />{" "}
                                    {this.state.copySuccess ? "Copied" : "Copy"}
                                </SpacedButton>
                            </React.Fragment>
                        )}
                    </Column>
                </Column>
                <Column large={6}>
                    {furtherRedemptions ? (
                        <SpacedButton
                            expanded
                            type="secondary"
                            onClick={this.props.resetOrder}
                        >
                            Generate Another Code
                        </SpacedButton>
                    ) : null}{" "}
                </Column>
                <Column large={6}>
                    <SpacedButton
                        expanded
                        element="a"
                        href="https://amazon.co.uk"
                        target="_blank"
                    >
                        Go to Amazon.co.uk
                    </SpacedButton>
                </Column>
            </StyledRow>
        );
    }
}

export default Confirmation;
