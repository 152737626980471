import styled from "styled-components";

export const UserBonusCountdownWrapper = styled.div`
    width: 100%;
    min-height: 120px;
    margin-bottom: 4rem;
    .input-range {
        padding-top: 50px;
        margin-bottom: -30px;
        pointer-events: none !important;
    }
    .input-range__track--active {
        background-color: #1c69d4;
    }
    .input-range__slider {
        background-color: black;
        width: 23px;
        height: 23px;
        top: -7px;
        color: white;
        border: none !important;
    }

    .input-range__slider:before {
        content: "£";
        display: block;
        text-align: center;
        font-weight: 800;
        line-height: 1.6em;
        margin-top: 2px;
    }
    .input-range__label--value {
        display: none;
    }
    .input-range__track {
        border-radius: 0px;
        height: 12px;
    }
    .input-range__label--min,
    .input-range__label--max {
        bottom: 2.4rem;
        font-weight: 800;
    }
    .input-range__label--max {
        display: none;
    }
    .input-range__label--max:before {
        display: inline-block;
        content: "£";
        position: relative;
        left: -5px;
    }
    // .input-range__label--max:after {
    //     display: inline-block;
    //     content: "|";
    // }
    .input-range__label--min {
        display: none;
    }
    .input-range__label--max .input-range__label-container {
        left: -5px;
    }
`;

export const LabelContainer = styled.div`
    position: relative;
    width: 100%;
`;
const Label = styled.small`
    margin: 0.2rem 0 0 0;
    position: absolute;
`;
export const RightLabel = styled(Label)`
    right: ${(props) => (props.indent ? props.indent : 0)};

    // :after {
    //     display: inline-block;
    //     content: "|";
    //     margin-left: 0.1rem;
    // }
`;
export const LeftLabel = styled(Label)`
    left: ${(props) => (props.indent ? props.indent : 0)};
`;
