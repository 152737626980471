import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { connect } from "react-redux";

import { setItem } from "@cortexglobal/cortex-utilities";
import { SET_AUTH_USER_DETAILS } from "@cortexglobal/cortex-auth-redux";
import { Modal, Button } from "@cortexglobal/rla-components";

import backgroundImage from "./img/BG.jpg";
import Box from "./img/01_Box.png";
import Quiz from "./img/02_Quiz.png";
import ThisOrThat from "./img/03_ThisOrThat.png";
import Coins from "./img/04_Coins.png";
import Stairs from "./img/05_Stairs.png";
import Logos from "./img/white-logos.png";

const StyledOnboardingModal = styled(Modal)``;

const OnboardingModalBackground = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 15% 0.5rem 15%;
    background-color: rgba(23, 90, 187, 1);
    background-image: url(${backgroundImage});
    height: 640px;
    width: 100%;
    color: white;
    text-align: center;
`;

const ImageWrapper = styled.div`
    margin: 2rem 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
    }
`;
const NextButton = styled(Button)`
    margin: 1rem 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(23, 90, 187, 1);
    &:hover {
        background-color: rgba(255, 255, 255, 0.7);
    }
`;

const ProgressDots = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem 0;
    width: 100%;
`;
const ProgressDot = styled.span`
    margin: 0 0.5rem;
    font-size: 2rem;
`;

export const OnboardingModal = connect()(({ user, dispatch }) => {
    const [onboardingViewed, setOnboardingViewed] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    let slides = [
        {
            key: "welcome",
            title: "Welcome to BMW Group Elite",
            description:
                "Explore the site and find out what's in store for you.  Win fantastic prizes, earn rewards and badges. Track your career progression. And more.",
            image: Logos,
        },
        {
            key: "claim",
            title: "Claim your loyalty reward",
            description:
                "Countdown to your big £1000 reward and start planning what you'll redeem it against at amazon.co.uk",
            image: Box,
        },
        {
            key: "quiz",
            title: "Take part in the weekly quiz",
            description:
                "Someone has to win, why not you? Test your knowledge and win big prizes in our weekly quiz.",
            image: Quiz,
        },
        {
            key: "this-or-that",
            title: "Play 'This or That'",
            description:
                "Compare your product knowledge against other BMW Group Elite colleagues in our fun 'This or That' game.",
            image: ThisOrThat,
        },
        {
            key: "earn-badges",
            title: "Earn engagement badges",
            description:
                "Build your badge count every time you log into BMW Elite and enter the weekly quiz. Track your badge total here.",
            image: Coins,
        },
        {
            key: "career-progression",
            title: "Track your career progression",
            description:
                "Move on up. Find out how to take the next exciting steps as you build your career right here.",
            image: Stairs,
        },
    ];

    //If the user isn't a technician, don't show the claim slide
    if (!user.role || user.role.type !== "technician") {
        slides = slides.filter((slide) => slide.key !== "claim");
    }

    //If the user is a service advisor, don't show the career progression slide
    if (user.role && user.role.type === "service-advisor") {
        slides = slides.filter((slide) => slide.key !== "career-progression");
    }

    const handleOnboardingViewed = () => {
        // Set the onboardingViewed state to true to optimistically close the modal
        setOnboardingViewed(true);
        // Make an api request to set the onboarding to viewed
        axios.post("/api/v1/user/onboarding-viewed").then((response) => {
            setItem("user", response.data.data);

            dispatch({
                type: SET_AUTH_USER_DETAILS,
                user: response.data.data,
            });
        });
    };

    const progressSlides = () => {
        // Move to the next slide if there is one, otherwise
        //close the modal by calling handleOnboardingViewed
        if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
        } else {
            handleOnboardingViewed();
        }
    };

    const getButtonText = () => {
        if (currentSlide === 0) {
            return "Get started with our quick tour";
        }
        if (currentSlide === slides.length - 1) {
            return "Finish";
        }
        return "Next";
    };

    return (
        <StyledOnboardingModal
            visible={
                user.additional_props &&
                user.additional_props.showOnboarding &&
                !onboardingViewed
            }
            showCloseButton={false}
            onClose={() => {
                //we don't want to close the modal when the user clicks outside of it
            }}
            padding={0}
            maxWidth="540px"
        >
            <OnboardingModalBackground>
                <h2>{slides[currentSlide].title}</h2>
                <p>{slides[currentSlide].description}</p>
                <ImageWrapper>
                    <img src={slides[currentSlide].image} alt="Box" />
                </ImageWrapper>
                <NextButton onClick={progressSlides}>
                    {getButtonText()}
                </NextButton>
                <ProgressDots>
                    {slides.map((slide, index) => {
                        return (
                            <ProgressDot
                                key={index}
                                style={{
                                    color:
                                        index === currentSlide
                                            ? "#ffc403"
                                            : "white",
                                }}
                            >
                                &#8226;
                            </ProgressDot>
                        );
                    })}
                </ProgressDots>
            </OnboardingModalBackground>
        </StyledOnboardingModal>
    );
});
