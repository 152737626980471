import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import ComparisonQuestion from "./ComparisonQuestion";
import ComparisonAnswer from "./ComparisonAnswer";
import ComparisonComplete from "./ComparisonComplete";
import ComparisonLanding from "./ComparisonLanding";
import {
    startComparisonGame,
    loadComparisonGame,
    resetComparisonGame,
    loadBestComparisonGame,
    submitComparisonGameAnswer
} from "./comparisonActions";
import ThisOrThatHeader from "../../../components/ThisOrThatHeader";
import ThisOrThatBG from "../../../img/this-or-that-bg.png";

const ThisOrThatContainer = styled.div`
    padding-bottom: 0rem;
    display: flex;
    //min-height: 85vh;
    background: #00132e url(${ThisOrThatBG}) repeat;
    color: white;
`;

class ComparisonGame extends Component {
    componentDidMount() {
        this.props.loadBestComparisonGame();
    }

    componentWillUnmount() {
        const { current } = this.props;

        if (current.loaded && current.completed) {
            this.props.resetComparisonGame();
        }
    }
    submitAnswer = answerUuid => {
        this.props.submitComparisonGameAnswer(
            this.props.current.uuid,
            answerUuid
        );
    };

    renderGameStep = current => {
        if (!current.gameStarted) {
            return (
                <ComparisonLanding
                    startComparisonGame={this.props.startComparisonGame}
                />
            );
        }

        //Displaying answer
        if (current.loaded && current.answerCountdownTimer > 0) {
            return <ComparisonAnswer current={current} />;
        }

        //Displaying completion screen
        if (current.loaded && current.completed) {
            return (
                <ComparisonComplete
                    current={current}
                    restartGame={this.props.resetComparisonGame}
                />
            );
        }

        //Displaying question
        if (current.loaded && current.questionCountdownTimer > 0) {
            return (
                <ComparisonQuestion
                    current={current}
                    submitAnswer={this.submitAnswer}
                />
            );
        }

        //Displaying loading screen
        return (
            <div className="page">
                <Row center>
                    <Column>
                        <ThisOrThatHeader />
                    </Column>
                </Row>
                <Loading />
            </div>
        );
    };

    render() {
        const { current } = this.props;

        return (
            <ThisOrThatContainer className="page">
                {this.renderGameStep(current)}
            </ThisOrThatContainer>
        );
    }
}
const mapStateToProps = state => {
    const { current, bestGame } = state.comparison;

    return { current, bestGame };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            startComparisonGame,
            loadComparisonGame,
            resetComparisonGame,
            submitComparisonGameAnswer,
            loadBestComparisonGame
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComparisonGame);
