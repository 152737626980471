import axios from "axios";

export const LOAD_ALL_BADGES = "LOAD_ALL_BADGES";
export const LOADING_ALL_BADGES = "LOADING_ALL_BADGES";

export const loadAllBadges = () => {
    return dispatch => {
        dispatch({
            type: LOADING_ALL_BADGES
        });
        return axios.get("/api/v1/badges/all-badges").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_ALL_BADGES,
                data
            });
        });
    };
};

export const LOAD_BADGE_SUMMARY = "LOAD_BADGE_SUMMARY";
export const LOADING_BADGE_SUMMARY = "LOADING_BADGE_SUMMARY";

export const loadBadgeSummary = () => {
    return dispatch => {
        dispatch({
            type: LOADING_BADGE_SUMMARY
        });
        return axios.get("/api/v1/badges/my-summary").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_BADGE_SUMMARY,
                data
            });
        });
    };
};

export const LOAD_BADGE_TYPES = "LOAD_BADGE_TYPES";
export const LOADING_BADGE_TYPES = "LOADING_BADGE_TYPES";

export const loadBadgeTypes = () => {
    return dispatch => {
        dispatch({
            type: LOADING_BADGE_TYPES
        });
        return axios.get("/api/v1/badges/types").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_BADGE_TYPES,
                data
            });
        });
    };
};
