import React, { Component } from "react";
import { Row, Column, PullRow } from "@cortexglobal/rla-components";
import styled from "styled-components";
import find from "lodash/find";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "@cortexglobal/bmw-shared-components";
import QuizQuestion from "./QuizQuestion";
import { PageTitle, Hero } from "@cortexglobal/bmw-shared-components";
import startStopIcon from "../../../img/icons/Start-Stop@2x.png";
import startStopIconHover from "../../../img/icons/Start-Stop-Hover@2x.png";
import quizImg from "../../../img/homepage-cards/1200x600px_Weekly-Quiz.jpg";

export const StartStopButton = styled.button`
    background-image: url(${startStopIcon});
    background-size: 100% 100%;
    box-shadow: none;
    border: none;
    width: 218px;
    height: 218px;
    text-indent: -100000px;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 100%;
    margin: calc(50% - 109px) auto 0;
    display: block;
    box-shadow: inset 0px 0px 3px black;
    &:hover,
    &:focus,
    &:active {
        background-image: url(${startStopIconHover});
        width: 216px;
        height: 216px;
        padding-top: 1px;
        padding-left: 1px;
        box-shadow: inset 0px 0px 20px black;
    }
`;

const QuizRow = styled(Row)`
    p {
        color: #9f9f9f;
        font-size: 16px;
        line-height: 20px;
    }
    h2 {
        font-size: 18px;
        line-height: 22px;
    }
`;
const ProgressPipWrapper = styled(Column)`
    text-align: right;
`;
export const ProgressPip = styled.span`
    width: 9px;
    height: 9px;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    margin-left: 19px;
    &:after {
        content: "";
        height: 1px;
        width: 19px;
        display: block;
        background: inherit;
        position: absolute;
        top: 4px;
        left: -19px;
    }
    &:first-of-type {
        margin-left: 0;
        &:after {
            display: none;
        }
    }
`;

class QuizGame extends Component {
    render() {
        const { quiz } = this.props;
        if (!quiz.started) {
            return (
                <Column medium={12} large={6}>
                    <Row>
                        <h1
                            style={{
                                textTransform: "uppercase",
                                fontSize: "20px",
                                lineHeight: "24px",
                                marginTop: "17px",
                            }}
                        >
                            Ready to take the weekly quiz?
                        </h1>
                        <StartStopButton onClick={this.props.startQuiz}>
                            Start Quiz
                        </StartStopButton>
                    </Row>
                </Column>
            );
        }

        const currentQuestion = find(quiz.questions, (question) => {
            return question.question.uuid === quiz.questionUuid;
        });
        const {
            question,
            answer,
            correct,
            correct_answer,
            order: currentQuestionNumber,
        } = currentQuestion;
        return (
            <React.Fragment>
                <Row>
                    <Column large={8}>
                        <PageTitle title="Take the weekly quiz" />
                    </Column>
                </Row>
                <Hero backgroundImage={quizImg} />

                <QuizRow>
                    <Column large={8}>
                        <PullRow>
                            <Column small={9}>
                                <h2>Question {currentQuestionNumber}:</h2>
                            </Column>
                            <ProgressPipWrapper small={3}>
                                {quiz.questions.map((question, index) => {
                                    //console.log(question);
                                    return (
                                        <ProgressPip
                                            key={question.question.uuid}
                                            style={{
                                                background:
                                                    question.order <=
                                                    currentQuestionNumber
                                                        ? colors.primary
                                                        : colors.darkGray,
                                            }}
                                        />
                                    );
                                })}
                            </ProgressPipWrapper>
                        </PullRow>
                    </Column>
                </QuizRow>
                <Row>
                    <Column large={8}>
                        <QuizQuestion
                            question={question}
                            answer={answer}
                            questionCountdownTimer={quiz.questionCountdownTimer}
                            answerCountdownTimer={quiz.answerCountdownTimer}
                            correct={correct}
                            correctAnswer={correct_answer}
                            submitQuizAnswer={this.props.submitQuizAnswer}
                        />{" "}
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

export default QuizGame;
