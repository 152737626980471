// Libraries
import React from "react";
import styled from "styled-components";

// Cortex
import { Column } from "@cortexglobal/rla-components";

// Images
import ExitSurveyBackground from "../../img/exit-survey.png";

export const BackgroundImage = styled(Column)`
    margin: 0;
    padding: 0;
    width: 100%;
    height: 250px;
    background-image: url(${ExitSurveyBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    img {
        width: 250px;
        height: 157px;
        // margin-top: 50px;
    }
`;

export const CenteredColumn = styled(Column)`
    // margin: auto;
    max-width: 40rem;
`;

export const ErrorMessage = styled.span`
    color: ${({ theme }) => theme.colors.alert};
    padding: 0 0 0 1rem;
`;
