import axios from 'axios';
import Raven from 'raven-js';


export const TrackingEvent = (category, action, label) => {
    axios
	.post("api/v1/page-actions", {
	    category: category,
	    action: action,
	    label: label,
	    pathname: window.location.pathname,
	    hostname: window.location.hostname,
	})
	.then(response => {})
	.catch(err => {
	    Raven.captureException(err);
	});
}
