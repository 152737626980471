import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    SubmitButton,
    FormikInput,
    FormikCheckbox,
    InputError,
    Panel,
    Loading,
    Row,
    Column,
} from "@cortexglobal/rla-components";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import TermsModal from "../../components/global/TermsModal";

import LoginImg from "../../img/bmw-service-image-tester.jpg";

const ViewTerms = styled.span`
    font-weight: 800;
    font-size: 0.7rem;
    cursor: pointer;
`;

const Hero = styled.div`
    background: url(${LoginImg});
    background-size: cover;
    background-position: center;
    max-width: 100%;
    min-height: 200px;
    margin-bottom: 30px;
`;

const StyledPanel = styled(Panel)`
    margin: 2rem;
`;
const StyledRow = styled(Row)`
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        max-width: 66%;
    }
`;
const PanelHeading = styled.h1`
    margin: 0 1.2rem;
    padding-bottom: 1.2rem;
`;
const PanelContent = styled.p`
    margin: 0 1.2rem;
    padding-bottom: 1.2rem;
`;

const LoginSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    // email: Yup.string()
    //     .email("Invalid email")
    //     .required("Required"),
    personal_email: Yup.string().email("Invalid email"),
    phone: Yup.string(),
    //start_date: Yup.string().required("Please select a start date"),
    role: Yup.string().required("Please select a role"),
    password: Yup.string()
        .min(8, "Your password must contain at least 8 characters")
        .max(70, "Too Long!")
        .matches(/^([a-zA-Z0-9]+)$/, {
            excludeEmptyString: true,
            message:
                "You must provide a password containing letters and numbers",
        }),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    // external_ref: Yup.string()
    //     .min(5, "Too Short!")
    //     .max(10, "Too Long!")
    //     .required("Required"),
    terms: Yup.boolean().oneOf([true], "Must Accept Terms and Conditions"),
});
class ActivateAccount extends Component {
    constructor(props) {
        super(props);

        this.showTermsModal.bind(this);
        this.closeTermsModal.bind(this);
    }

    state = {
        activationLoadingError: false,
        activationError: false,
        details: {},
        loading: false,
        loaded: false,
        success: false,
        termsModalVisible: false,
    };

    componentDidMount() {
        const activationCode = this.props.match.params.activation;
        if (activationCode !== "undefined") {
            axios
                .get(`/api/v1/activation/${activationCode}`)
                .then(({ data: { data } }) => {
                    this.setState({
                        details: data,
                        loaded: true,
                        loading: false,
                    });
                })
                .catch((e) => {
                    console.log(e, e.response);
                    this.setState({
                        activationLoadingError: e.response.data.message,
                    });
                });
        } else {
            this.setState({
                activationLoadingError:
                    "No activation code provided, please check the link you were provided",
            });
        }
    }

    showTermsModal = (e) => {
        this.setState({ termsModalVisible: true });
    };

    closeTermsModal = (e) => {
        this.setState({ termsModalVisible: false });
    };

    submitForm = (values, { setErrors }) => {
        // return false;
        this.setState((state) => ({
            activationError: false,
            loading: true,
        }));
        values["activation_code"] = this.props.match.params.activation;
        axios
            .post("/api/v1/activation", values)
            .then(({ data: { data } }) => {
                this.setState({ success: true, loaded: true, loading: false });
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    //console.log(setErrors, errors);
                    setErrors(errors);
                });
                this.setState({
                    loaded: true,
                    loading: false,
                });
            });

        return true;
    };

    render() {
        const {
            activationLoadingError,
            activationError,
            loading,
            loaded,
            success,
            details,
            termsModalVisible,
        } = this.state;
        if (activationLoadingError) {
            return (
                <div className="page page-register">
                    <Hero />
                    <div className="form-container">
                        <StyledRow collapse>
                            <StyledPanel type="alert">
                                <PanelHeading>
                                    We were unable to load your invitation
                                </PanelHeading>
                                <PanelContent>
                                    {activationLoadingError}
                                </PanelContent>
                            </StyledPanel>
                        </StyledRow>
                    </div>
                </div>
            );
        }
        if (!details || !loaded) {
            return (
                <div className="page page-register">
                    <Hero />
                    <div className="form-container">
                        <Loading align="center" style={{ marginTop: "2rem" }} />
                    </div>
                </div>
            );
        }

        if (success) {
            return (
                <div className="page page-register">
                    <Hero />
                    <div className="form-container">
                        <StyledRow>
                            <StyledPanel type="success">
                                <PanelHeading>
                                    You have successfully activated your account
                                </PanelHeading>
                            </StyledPanel>
                            <PanelContent>
                                <Button
                                    expanded
                                    type="primary"
                                    height={40}
                                    element={Link}
                                    to="/auth/login"
                                >
                                    Go to Login
                                </Button>{" "}
                            </PanelContent>
                        </StyledRow>
                    </div>
                </div>
            );
        }
        //console.log(details);

        return (
            <div className="page page-register">
                <Hero />
                <div className="form-container">
                    <StyledRow>
                        <p>
                            Welcome to Elite - the BMW and MINI Loyalty
                            Programme. As a member, you will be eligible for our
                            new loyalty bonus scheme, along with competitions,
                            online games with some fun prizes and other exciting
                            content. Register your details and create your
                            account so you can start to earn prizes and rewards.
                        </p>
                        <PageTitle
                            title={`Confirm your details: ${details.email}`}
                            // subtitle="Check your details and create an account"
                        />
                    </StyledRow>
                    <React.Fragment>
                        <Formik
                            initialValues={{
                                first_name: details.first_name,
                                last_name: details.last_name,
                                // email: details.email,
                                personal_email: details.personal_email
                                    ? details.personal_email
                                    : "",
                                phone: details.phone ? details.phone : "",
                                // start_date: details.start_date,
                                role: details.role.alias,
                                password: "",
                                password_confirmation: "",
                                // external_ref: details.external_ref,
                                terms: false,
                            }}
                            onSubmit={this.submitForm}
                            validationSchema={LoginSchema}
                            className="form-group"
                        >
                            {({ values, errors }) => {
                                // console.log(values, errors);
                                return (
                                    <Form className="form-group">
                                        <Row>
                                            <Column large={3}>
                                                <FormikInput
                                                    type="text"
                                                    name="first_name"
                                                    label="First Name*"
                                                    value={values.first_name}
                                                    placeholder="Joe"
                                                />
                                                <FormikInput
                                                    type="text"
                                                    name="last_name"
                                                    label="Last Name*"
                                                    value={values.last_name}
                                                    placeholder="Bloggs"
                                                />
                                                {/*<FormikInput
                                                    type="text"
                                                    name="external_ref"
                                                    label="TMSI User ID"
                                                    value={values.external_ref}
                                                    placeholder="c123456"
                                                />*/}
                                            </Column>
                                            <Column large={3}>
                                                {/*<FormikInput
                                                    type="email"
                                                    name="email"
                                                    label="Email"
                                                    value={values.email}
                                                    placeholder="joe.bloggs@email.com"
                                                    autoComplete="false"
                                                />*/}

                                                <FormikInput
                                                    tooltip="By completing this field you are
                                                    agreeing to receive emails relating
                                                    to the BMW Group Elite Programme. If
                                                    you leave this field blank your
                                                    emails will be sent to your company
                                                    email address."
                                                    type="email"
                                                    name="personal_email"
                                                    label="Personal Email (optional)"
                                                    placeholder="joe.bloggs@email.com"
                                                />
                                                <FormikInput
                                                    tooltip="By completing this field you are
                                                agreeing to receive SMS messages
                                                relating to the BMW Group Elite
                                                Programme."
                                                    type="tel"
                                                    name="phone"
                                                    label="Mobile (optional)"
                                                    placeholder="0777123456"
                                                />
                                            </Column>
                                            <Column large={3}>
                                                {/*<FormikDatePicker
                                                    name="start_date"
                                                    label="Start Date"
                                                    value={values.start_date}
                                                />*/}
                                                <FormikInput
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    label="Password*"
                                                />
                                                <FormikInput
                                                    type="password"
                                                    name="password_confirmation"
                                                    value={
                                                        values.password_confirmation
                                                    }
                                                    label="Password Confirmation*"
                                                />
                                            </Column>
                                        </Row>
                                        {activationError && (
                                            <InputError
                                                error={activationError}
                                            />
                                        )}
                                        <Row>
                                            {" "}
                                            <Column large={3}>
                                                <FormikCheckbox
                                                    name="terms"
                                                    label="I agree to the terms and conditions*"
                                                    inline
                                                    errorStyle={{
                                                        marginTop: "-0.3rem",
                                                    }}
                                                />{" "}
                                                <ViewTerms
                                                    onClick={
                                                        this.showTermsModal
                                                    }
                                                >
                                                    View
                                                </ViewTerms>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column large={3}>
                                                <SubmitButton
                                                    expanded
                                                    label="Sign Up"
                                                    submitting={loading}
                                                    disabled={
                                                        success || loading
                                                    }
                                                />
                                            </Column>
                                        </Row>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </React.Fragment>
                </div>
                <TermsModal
                    visible={termsModalVisible}
                    closeModal={this.closeTermsModal}
                    newTab={true}
                />
            </div>
        );
    }
}

export default ActivateAccount;
