import React from "react";
import { Modal, Row, Column } from "@cortexglobal/rla-components";
import styled from "styled-components";

const H1 = styled.h1``;

const H2 = styled.h2`
    color: darkblue;
`;

const H3 = styled.h3`
    color: darkblue;
`;

const DL = styled.dl`
    dt {
        padding-top: 0.5rem;
    }

    dd {
        font-weight: bold;
        margin-inline-start: 0;
    }
`;

const TroubleLoggingInModal = (props) => {
    const { visible, closeModal } = props;

    return (
        <Modal visible={visible} onClose={closeModal} maxWidth="90%">
            <Row>
                <Column>
                    <H1>Having trouble logging into your account?</H1>
                </Column>
            </Row>
            <Row>
                <Column>
                    <p>
                        If you have logged into your account and you have been
                        immediately directed back to the login page, this means
                        your account is currently inactive for one of the
                        following reasons. Simply identify the issue you&apos;re
                        having on the list below and follow the instructions on
                        how to reinstate your account.
                    </p>
                    <H2>Activation Issues</H2>
                    <H3>1. Account Awaiting Activation</H3>
                    <p>
                        If your manager has invited you to Elite, you will need
                        to activate your account before you can login. You will
                        have received an email inviting you to Elite which
                        includes an <strong>activation link</strong>. Click the
                        link and fill out the appropriate details. Then accept
                        the Terms &amp; Conditions and press <em>Sign Up</em>.
                        This will activate your account and redirect you to the
                        login page and you will be able to login. Please ensure
                        that you have checked your <strong>junk</strong> email
                        and any other <strong>email folders</strong> for this
                        email. If you cannot locate this email, please contact
                        the BMW Group Elite Team, who will be able to send you
                        your activation link.
                    </p>
                    <p>
                        <em>
                            Note: Activation links are unique to each user,
                            therefore, the same link will not work to activate
                            multiple accounts.
                        </em>
                    </p>
                    <H3>2. Account Awaiting Activation (Expired Link)</H3>
                    <p>
                        If you have clicked the <strong>activation link</strong>{" "}
                        from your email invite and a red text box appears, this
                        means that your invitation has expired. As only one
                        activation link can be generated per user, it means that
                        you have left your activation too long and your
                        activation link is permanently expired. Therefore, you
                        will need to email the BMW Group Elite Team, who will
                        manually activate your account and email your login
                        details to you.
                    </p>
                    <H3>3. Account Awaiting Approval</H3>
                    <p>
                        If you have created an account yourself on Elite, you
                        will not be able to login until your manager has
                        approved this. Your manager will need to login to the
                        manager&apos;s portal{" "}
                        <a
                            href="https://manager.bmwgroupelite.co.uk/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://manager.bmwgroupelite.co.uk/
                        </a>{" "}
                        and approve this. Any accounts awaiting approval will
                        appear in the manager&apos;s request section{" "}
                        <a
                            href="https://manager.bmwgroupelite.co.uk/requests"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://manager.bmwgroupelite.co.uk/requests
                        </a>
                        . Once your manager has approved your account, you will
                        be able to login to Elite. If for some reason your
                        manager is not able to approve your account, please
                        email the BMW Group Elite Team, who can approve your
                        account for you.
                    </p>
                    <H2>TMSi Issues</H2>
                    <p>
                        In order for your account to remain active, the details
                        on your Elite account must match the details on your
                        TMSi account. If the details on these two accounts do
                        not match, your Elite account is automatically deleted.
                    </p>
                    <p>
                        <em>
                            Note: Your Elite account should be set up to match
                            the details on your TMSi account. You should not
                            alter your TMSi to match your Elite account.
                        </em>
                    </p>
                    <H3>1. Incorrect Job Role (Apprentices)</H3>
                    <p>
                        If you are set up on Elite with a job role that does not
                        match the <strong>market function code (MFC)</strong> on
                        your TMSi account, your Elite account will be
                        automatically deleted. This usually occurs when
                        Apprentices are set up on Elite as Technicians. If you
                        are an Apprentice, you should be set up on Elite as an
                        Apprentice when you first join a BMW/MINI retailer. Once
                        you have completed your Apprenticeship, and your{" "}
                        <strong>MFC</strong> is updated to a Technician code,
                        your Elite account&apos;s job role will automatically
                        update.
                    </p>
                    <p>
                        Therefore, if you are set up as a Technician before the{" "}
                        <strong>MFC</strong> on your TMSi account has been
                        updated, your Elite account will automatically be
                        deleted. If your account is deleted, meaning you cannot
                        login, please do not try to set yourself up with a new
                        account. Please contact the BMW Group Elite Team, who
                        will set your account back to an Apprentice account,
                        matching your TMSi <strong>MFC</strong>, and will
                        reinstate your Elite account.
                    </p>
                    <H3>2. Incorrect Market Function Code (MFC)</H3>
                    <p>
                        The <strong>market function code (MFC)</strong> on your
                        TMSi account allows us to monitor which job role you
                        have within the BMW/MINI brand and if you are eligible
                        for the Elite programme. If you have an{" "}
                        <strong>MFC</strong> on your TMSi account that is not
                        eligible for the Elite programme, the Elite account
                        connected to that TMSi account is automatically deleted.
                        You will need to amend your TMSi account and update the{" "}
                        <strong>MFC</strong> to an eligible code for your job
                        role before your account can be reinstated. The eligible{" "}
                        <strong>MFC</strong>s for each job role on Elite are
                        listed below:
                    </p>
                    <DL>
                        <dt>Apprentice</dt>
                        <dd>006 - Non BMW Apprentice</dd>
                        <dd>629 - Service Apprentice (Technical)</dd>
                        <dt>
                            Technician/ Senior Technician/ Master Technician
                        </dt>
                        <dd>062 - Service Technician</dd>
                        <dd>064 - BMW Maintenance Technician</dd>
                        <dd>068 - BMW Certified Technician</dd>
                        <dd>069 - BMW Certified Senior Technician</dd>
                        <dd>070 - BMW Certified Master Technician</dd>
                        <dd>462 - MINI Service Technician</dd>
                        <dd>464 - MINI Maintenance Technician</dd>
                        <dd>468 - MINI Certified Technician</dd>
                        <dd>469 - MINI Certified Senior Technician</dd>
                        <dt>Service Advisor</dt>
                        <dd>040 - Service Advisor</dd>
                        <dd>440 - MINI Service Advisor</dd>
                        <dt>Sales Executives</dt>
                        <dd>302 - New Car Sale Executive</dd>
                        <dd>306 - Used Car Sales Executive</dd>
                        <dd>392 - Corporate Sales Executive</dd>
                        <dd>406 - MINI Used Car Sales Executive</dd>
                        <dd>426 - MINI New Car Sales Executive</dd>
                        <dd>427 - MINI Trainee Sales Executive</dd>
                        <dd>627 - Trainee Sales Executive</dd>
                    </DL>
                    <p>
                        Once your retailer has updated the <strong>MFC</strong>{" "}
                        on your TMSi account, please inform the BMW Group Elite
                        Team that this has been updated. When this updated TMSi
                        appears in the Elite system, the BMW Group Elite Team
                        will inform that your account has been reinstated.
                    </p>
                    <p>
                        <em>
                            Note: The TMSi data is updated in the Elite system
                            once every Monday. Changes made during the week will
                            not appear until the following Monday. Sometimes it
                            can take two to three weeks for the updates to
                            appear in the Elite system.
                        </em>
                    </p>
                    <H3>3. Incorrect TMSi ID</H3>
                    <p>
                        All Elite accounts are connected to a TMSi account so
                        that we can ensure that all the user&apos;s details are
                        correct and that they are eligible for the Elite
                        programme. Both accounts are connected by sharing a{" "}
                        <strong>TMSi User ID</strong> code. When your Elite
                        account is first set up, you or your manager will need
                        to add a TMSi User ID for your Elite account to be
                        valid.{" "}
                        <em>
                            Note: Your TMSi User ID will always begin with a{" "}
                            <strong>C/CM</strong>
                        </em>
                    </p>
                    <p>
                        If the wrong code is put into the TMSi User ID, your
                        Elite account will be automatically deleted, as it
                        cannot be recognised against any TMSi account. If your
                        Elite account is deleted, meaning you cannot login,
                        please email the BMW Group Elite Team with your correct
                        TMSi User ID, and we will update your Elite account and
                        reinstate this for you.
                    </p>
                    <H3>4. Inactive TMSi</H3>
                    <p>
                        As stated above, we connect every Elite account to a
                        TMSi account so that we can ensure that all users are
                        eligible. This means that the TMSi account connected to
                        an Elite account must be active. If the TMSi account is
                        inactive, the Elite account will be automatically
                        deleted.
                    </p>
                    <p>
                        Your TMSi account may become inactive if you change
                        retailers, or if you previously left the company and
                        returned at a later date. You will need to check the
                        status of your TMSi account. If it is inactive, you will
                        need to set up a new TMSi account for your retailer and
                        include an eligible <strong>MFC</strong> for your job
                        role.
                    </p>
                    <p>
                        Once your new TMSi account is set up, you will need to
                        email the BMW Group Elite Team and inform them of your
                        new TMSi account, with your new TMSi User ID. When your
                        new account appears in the Elite system, we will then be
                        able to reinstate your Elite account.
                    </p>
                    <p>
                        For any further information or queries regarding Elite,
                        please email the BMW Group Elite Team at{" "}
                        <a href="mailto:info@bmwgroupelite.co.uk">
                            info@bmwgroupelite.co.uk
                        </a>
                        .
                    </p>
                </Column>
            </Row>
        </Modal>
    );
};

export default TroubleLoggingInModal;
