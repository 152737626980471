import React, { Component } from "react";
import { PullRow, Column, Button } from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import CompetitionEntrySummary from "./CompetitionEntrySummary";

import {
    CompetitionTitle,
    CompetitionPrize,
    CompetitionTitleWrapperColumn,
    PrizeImage,
    CompetitionDescription
} from "../../components/competitionComponents";
class CompetitionDetail extends Component {
    render() {
        const { name, description, prizes, thumbnail } = this.props.competition;

        return (
            <Column large={8}>
                <PullRow>
                    <CompetitionTitleWrapperColumn large={8}>
                        <CompetitionTitle>{name}</CompetitionTitle>
                    </CompetitionTitleWrapperColumn>
                </PullRow>
                <PullRow>
                    <Column large={6}>
                        <PrizeImage thumbnail={thumbnail} />
                    </Column>
                    <Column large={6}>
                        <CompetitionEntrySummary
                            competition={this.props.competition}
                            user={this.props.user}
                        />
                    </Column>
                </PullRow>

                <PullRow>
                    <Column>
                        {prizes.map(prize => {
                            return (
                                <CompetitionPrize
                                    key={prize.name + prize.quantity}
                                    current
                                >
                                    {prize.quantity} x {prize.name}
                                </CompetitionPrize>
                            );
                        })}
                    </Column>
                    <Column>
                        <CompetitionDescription>
                            {description}
                        </CompetitionDescription>
                    </Column>
                    <Column>
                        <Button element={Link} to="/games/weekly-quiz">
                            Take the quiz
                        </Button>
                    </Column>
                </PullRow>
            </Column>
        );
    }
}

export default CompetitionDetail;
