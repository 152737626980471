import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Loading, Row, Column } from "@cortexglobal/rla-components";
import { SectionTitle } from "@cortexglobal/bmw-shared-components";

import { getNewsStory, clearNewsStory } from "./newsActions";

import UserSummary from "../../components/global/UserSummary";
import AppContext from "../../components/global/AppContext";
import RichText from "../../components/richText";

import {
    CardActionWrapper,
    CardLabel,
    CardLinkButton,
} from "../../components/infoCards/cardComponents";
import { Link } from "react-router-dom";

const Thumbnail = styled.div`
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 0;
    margin-bottom: 20px;

    img {
        width: 100%;
        aspect-ratio: 386/170;
        object-fit: cover;
    }
`;

const RowHeading = styled(Row)`
    padding-top: 20px;
    padding-bottom: 20px;

    hr {
        margin: 10px 0 0;
        background-color: rgb(70 70 70 / 0.32);
    }

    @media screen and (min-width: ${(props) =>
            props.theme.breakpoints.large}px) {
        hr {
            display: none;
        }

        p {
            font-size: 1rem;
        }
    }
`;

const BackLink = styled(Link)`
    text-decoration: none;

    h2 {
        margin: 0;
    }
`;

class NewsDetail extends Component {
    componentWillMount() {
        this.props.getNewsStory(this.props.match.params.uuid);
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.props.clearNewsStory();
    }

    render() {
        const { story } = this.props;
        return (
            <AppContext.Consumer>
                {({ user, mobileView }) => {
                    return (
                        <div className="page page-news">
                            <UserSummary />
                            <RowHeading>
                                <Column small={12}>
                                    <BackLink to="/news">
                                        <h2>&lt; Back to News</h2>
                                    </BackLink>
                                    <hr />
                                </Column>
                            </RowHeading>
                            <Row>
                                {!this.props.loaded ? (
                                    <Loading />
                                ) : (
                                    <Column large={4}>
                                        <CardLabel>
                                            <SectionTitle
                                                title={story.title}
                                                subtitle={story.subtitle}
                                            />
                                        </CardLabel>
                                        <Thumbnail>
                                            <img
                                                loading="lazy"
                                                src={story.thumbnail}
                                                alt=""
                                            />
                                        </Thumbnail>

                                        <div style={{ marginBottom: "1rem" }}>
                                            <RichText content={story.body} />
                                        </div>

                                        {story.cta && (
                                            <CardActionWrapper>
                                                <CardLinkButton
                                                    to={story.cta.link}
                                                >
                                                    {story.cta.text ||
                                                        "Read More"}
                                                </CardLinkButton>
                                            </CardActionWrapper>
                                        )}
                                    </Column>
                                )}
                            </Row>
                        </div>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

function mapStateToProps(state) {
    let { loaded, data: story } = state.news.current;
    console.log(state.news.current);
    return { loaded, story };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getNewsStory, clearNewsStory }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetail);
