import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Column, Row } from "@cortexglobal/rla-components";
import { SectionTitle } from "@cortexglobal/bmw-shared-components";
import { Card, CardLabel } from "./cardComponents";
import styled from "styled-components";
import RedeemAmazonImg from "../../img/homepage-cards/redeem-amazon.png";
import TechImg from "../../img/bmw-service-image-tester.jpg";

const CardLink = styled(Link)`
    text-decoration: none;
`;
class HomepageCards extends Component {
    render() {
        return (
            <Row style={{ marginBottom: "50px" }}>
                <Card
                    style={{
                        backgroundImage: `url(${TechImg})`,
                        animationDelay: "0ms"
                    }}
                    className="slit-in-vertical"
                >
                    <CardLink to="/vehicle-health-check-competition">
                        <p>Vehicle health check video wall</p>
                    </CardLink>
                </Card>
            </Row>
        );
    }
}

const mapStateToProps = state => ({ loggedIn: state.auth.loggedIn });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomepageCards);
