import axios from "axios";
import { CHANGE_POINTS_BALANCE } from "../points/pointsActions";

export const AMAZON_GIFT_CARD_ORDER_PROCESSING =
    "AMAZON_GIFT_CARD_ORDER_PROCESSING";
export const LOAD_AMAZON_GIFT_CARD_ORDER = "LOAD_AMAZON_GIFT_CARD_ORDER";
export const LOAD_AMAZON_GIFT_CARD_ORDER_ERROR =
    "LOAD_AMAZON_GIFT_CARD_ORDER_ERROR";

export function submitAmazonOrderPoints(points) {
    return (dispatch, getState) => {
        dispatch({ type: AMAZON_GIFT_CARD_ORDER_PROCESSING, processing: true });
        return axios
            .post(`api/v1/amazon/order`, {
                points
            })
            .then(response => response.data.data)
            .then(data => {
                dispatch({
                    type: LOAD_AMAZON_GIFT_CARD_ORDER,
                    order: data.order
                });
                dispatch({
                    type: CHANGE_POINTS_BALANCE,
                    amount: points
                });
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    // with a 400 error we should be safe to show the user the output
                    dispatch({
                        type: LOAD_AMAZON_GIFT_CARD_ORDER_ERROR,
                        error: error.response.data.message
                    });
                } else {
                    dispatch({
                        type: LOAD_AMAZON_GIFT_CARD_ORDER_ERROR,
                        error:
                            "There was an error while redeeming with Amazon. Please try again later."
                    });
                }
            });
    };
}

export const LOAD_REDEMPTION_HISTORY = "LOAD_REDEMPTION_HISTORY";
export const REDEMPTION_HISTORY_LOADING = "REDEMPTION_HISTORY_LOADING";

export function getRedemptionHistory() {
    return dispatch => {
        dispatch({ type: REDEMPTION_HISTORY_LOADING, loading: true });
        return axios
            .get(`api/v1/redemptions`)
            .then(response => response.data.data)
            .then(data => {
                dispatch({
                    type: LOAD_REDEMPTION_HISTORY,
                    data
                });
            });
    };
}
