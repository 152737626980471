import React, { Component } from "react";
import styled from "styled-components";
import { Row, Column, Loading } from "@cortexglobal/rla-components";

const LeagueTable = styled.table`
    border-spacing: 0;
    width: 100%;
`;
const LeagueRow = styled.tr`
    background: ${props =>
        props.usersRow ? "rgba(150,150,150,0.3)" : "rgba(255,255,255,0.0)"};
    padding: 0.3rem;
    td {
        padding: 0.5rem;
        font-weight: ${props => (props.usersRow ? "bold" : "normal")};
    }
`;
class ComparisonLeague extends Component {
    render() {
        const { loading, league, user } = this.props;

        if (loading) {
            return <Loading />;
        }
        return (
            <React.Fragment>
                <Row>
                    <Column>
                        <LeagueTable>
                            <tbody>
                                {league.map(userTopScore => {
                                    return (
                                        <LeagueRow
                                            key={userTopScore.user_uuid}
                                            usersRow={
                                                user &&
                                                userTopScore.user_uuid ===
                                                    user.uuid
                                            }
                                        >
                                            <td>
                                                {userTopScore.group_position}
                                            </td>
                                            <td>{userTopScore.user_name}</td>
                                            {/* <td>{userTopScore.group_name}</td> */}
                                            <td>
                                                {userTopScore.total_correct}
                                            </td>
                                        </LeagueRow>
                                    );
                                })}
                            </tbody>
                        </LeagueTable>
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

export default ComparisonLeague;
