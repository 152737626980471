import React, { Component } from "react";
import styled from "styled-components";
import { Row, Column, Button } from "@cortexglobal/rla-components";
import { NavLink } from "react-router-dom";
import PrivateNav from "./PrivateNav";
import AppContext from "./AppContext";
import logos from "../../img/logos.png";
import alarmIcon from "../../img/icons/alarm-bell.svg";
import userIcon from "../../img/icons/messenger-user-avatar.svg";
import { app } from "@cortexglobal/bmw-shared-components";

const headerSpacing = "1rem";

const HeaderPadding = {
    paddingTop: "1rem",
    paddingBottom: "0rem",
};

const Header = styled.div`
    color: ${(props) => props.theme.header.color};
    background: ${(props) => props.theme.header.background};
    width: 100%;
    position: fixed;
    z-index: 1;
    box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.1);
    min-height: 64px;
`;

const NavIconWrapper = styled.div`
    display: inline-block;
    min-width: 3rem;
    &.active {
        color: #1c69d4;
    }
    a {
        text-decoration: none;
    }
`;

const Icon = styled.span`
    display: inline-block;
    width: 25px;
    height: 25px;
    position: relative;
    margin-top: -10px;
    top: 7px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.userIcon {
        background-image: url(${userIcon});
    }
    &.alarmIcon {
        background-image: url(${alarmIcon});
    }
    &:hover {
        color: #1c69d4;
    }
`;

const Roundel = styled.sup`
    position: relative;
    left: -10px;
    background: #1c69d4;
    color: #ffffff;
    padding: 2px 6px;
    border-radius: 50%;
`;

const ManagerLink = styled.div`
    text-align: center;
    padding-top: 0.3rem;
`;

class PrivateHeader extends Component {
    render() {
        const { userCanViewRewards, userCanViewCareerPathway } = this.props;
        return (
            <AppContext.Consumer>
                {({ user, mobileView, notifications }) => (
                    <React.Fragment>
                        <Header>
                            {user &&
                                user.role &&
                                user.role.type === "head-office" &&
                                process.env.REACT_APP_MANAGER_URL && (
                                    <ManagerLink>
                                        <small>
                                            You are currently using the
                                            technician app with a non technician
                                            role.
                                        </small>{" "}
                                        <Button
                                            style={{
                                                fontWeight: "normal",
                                                height: "auto",
                                                lineHeight: "initial",
                                                padding:
                                                    "0.2rem 0.4rem 0rem 0.4rem",
                                            }}
                                            type="alert"
                                            hollow
                                            height={40}
                                            element={"a"}
                                            href={
                                                process.env
                                                    .REACT_APP_MANAGER_URL
                                            }
                                        >
                                            Go to Manager Site
                                        </Button>
                                    </ManagerLink>
                                )}
                            <Row
                                style={{
                                    maxWidth: mobileView
                                        ? "1400px"
                                        : `${1400 + app.quickLinkWidth + 20}px`,
                                }}
                            >
                                <Column
                                    small={9}
                                    style={{
                                        paddingTop: ".3em",
                                    }}
                                >
                                    <NavIconWrapper>
                                        <PrivateNav
                                            spacing={headerSpacing}
                                            mobileView={mobileView}
                                            userCanViewRewards={
                                                userCanViewRewards
                                            }
                                            userCanViewCareerPathway={
                                                userCanViewCareerPathway
                                            }
                                        />{" "}
                                        &nbsp;
                                    </NavIconWrapper>
                                    <NavIconWrapper style={HeaderPadding}>
                                        <NavLink exact to="/profile">
                                            <Icon className="userIcon" />
                                        </NavLink>
                                    </NavIconWrapper>
                                    <NavIconWrapper style={HeaderPadding}>
                                        <NavLink exact to="/notifications">
                                            <span>
                                                <Icon
                                                    className={
                                                        "alarmIcon" +
                                                        (!!notifications.unread
                                                            ? " active"
                                                            : "")
                                                    }
                                                />
                                                {!!notifications.unread && (
                                                    <Roundel>
                                                        {notifications.unread}
                                                    </Roundel>
                                                )}
                                            </span>
                                        </NavLink>
                                    </NavIconWrapper>
                                </Column>
                                <Column
                                    small={3}
                                    style={{
                                        paddingBottom:
                                            HeaderPadding.paddingBottom,
                                        paddingTop: HeaderPadding.paddingTop,
                                        textAlign: "right",
                                    }}
                                >
                                    <NavLink to="/">
                                        <img
                                            src={logos}
                                            style={{ width: "90px" }}
                                            alt="BMW and Mini Logos"
                                        />
                                    </NavLink>
                                </Column>
                            </Row>
                        </Header>
                    </React.Fragment>
                )}
            </AppContext.Consumer>
        );
    }
}

export default PrivateHeader;
