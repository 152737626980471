import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { Theme } from "@cortexglobal/rla-components";
import { IntlProvider } from "react-intl";
import { Switch, Route } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import { ConnectedRouter } from "react-router-redux";
import { merge } from "lodash";
import "@babel/polyfill";

import { getMessages } from "./i18n/languageSetup";

import configureStore from "./redux/configureStore";
import { setupAuth } from "@cortexglobal/cortex-auth-react-router-dom";

import { PrivateRoute } from "@cortexglobal/cortex-auth-react-router-dom";
import PrivateApp from "./components/global/PrivateApp";
import PublicApp from "./components/global/PublicApp";
import withTracker from "./components/routing/withTracker";

import HeadContent from "./components/global/HeadContent";

import { theme as customTheme } from "@cortexglobal/bmw-shared-components";

import globalCss from "./config/globalCss";
require("./config/icons");
require("./config/axiosConfig");

//Setup the store
const history = createHistory();
export const store = configureStore({}, history);
store.dispatch(setupAuth());

//Add Global CSS
const GlobalStyle = createGlobalStyle`${globalCss(customTheme)}`;

//Function to render the app, used on initial load and during HMR
function renderApp() {
    //Language setup
    let language = store.getState().auth.user.language
        ? store.getState().auth.user.language
        : "en-GB";
    //let language = "fr";
    const messages = getMessages(language);
    return (
        <Provider store={store}>
            <IntlProvider locale={language} messages={messages}>
                <ThemeProvider theme={merge(Theme, customTheme)}>
                    <ConnectedRouter history={history}>
                        <React.Fragment>
                            <GlobalStyle />
                            <HeadContent />
                            <Switch>
                                <Route
                                    path="/auth"
                                    component={withTracker(PublicApp)}
                                />
                                <Route
                                    path="/info"
                                    component={withTracker(PublicApp)}
                                />
                                <Route
                                    path="/exit-survey"
                                    component={withTracker(PublicApp)}
                                />
                                <PrivateRoute
                                    path="/"
                                    component={withTracker(PrivateApp)}
                                />
                            </Switch>
                        </React.Fragment>
                    </ConnectedRouter>
                </ThemeProvider>
            </IntlProvider>
        </Provider>
    );
}

ReactDOM.render(renderApp(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
    module.hot.accept(
        ["./components/global/PrivateApp", "./components/global/PublicApp"],
        () => {
            ReactDOM.render(renderApp(), document.getElementById("root"));
        }
    );
}
