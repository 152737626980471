import axios from "axios";

export const LOADING_COUNTDOWN = "LOADING_COUNTDOWN";
export const LOAD_COUNTDOWN = "LOAD_COUNTDOWN";
export const LOAD_COUNTDOWN_ERROR = "LOAD_COUNTDOWN_ERROR";

export function getCountdown() {
    return (dispatch, getState) => {
        dispatch({ type: LOADING_COUNTDOWN, loading: true });
        return axios
            .get(`api/v1/countdown`)
            .then(response => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_COUNTDOWN,
                    data
                });
            })
            .catch(error => {
                if (
                    error.response &&
                    (error.response.status === 400 ||
                        error.response.status === 404)
                ) {
                    // with a 400 error we should be safe to show the user the output
                    dispatch({
                        type: LOAD_COUNTDOWN_ERROR,
                        error: error.response.data.message
                    });
                } else {
                    dispatch({
                        type: LOAD_COUNTDOWN_ERROR,
                        error: "There was an error loading your countdown data."
                    });
                }
            });
    };
}
