import React, { Component } from "react";
import styled from "styled-components";

import { Button } from "@cortexglobal/rla-components";
import ThisOrThatHeader from "../../../components/ThisOrThatHeader";
const ThisOrThatLanding = styled.div`
    display: flex;
    width: 100%;
    //min-height: 84vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
class ComparisonLanding extends Component {
    render() {
        return (
            <ThisOrThatLanding>
                <ThisOrThatHeader />
                <p>How many can you get right?</p>
                <Button onClick={this.props.startComparisonGame}>
                    Play Now
                </Button>
            </ThisOrThatLanding>
        );
    }
}

export default ComparisonLanding;
