import { GET_RETAILERS } from "./groupActions";

const initialState = {
    loaded: false,
    data: null
};

export default function groups(state = initialState, action) {
    switch (action.type) {
        case GET_RETAILERS:
            return {
                ...state,

                data: action.data.map(group => {
                    return {
                        text: group.name,
                        value: group.uuid
                    };
                }),
                loaded: true
            };

        default:
            return state;
    }
}
