import styled from "styled-components";

import { Column } from "@cortexglobal/rla-components";

export const CompetitionTitleWrapperColumn = styled(Column)`
    margin: 1.2rem 0;
`;
export const CompetitionTitle = styled.h3`
    margin-bottom: 0.2rem;
    font-size: ${props => (props.current ? "1.2rem" : "1rem")};
    span {
        color: ${props => props.theme.colors.primary};
    }
    font-size: 16px;
    line-height: 15px;
    margin-bottom: 0.2rem;
`;

export const CompetitionPrize = styled.h5`
    margin: 0.3rem 0;
    font-size: ${props => (props.current ? "1.2rem" : "0.9rem")};
    color: ${props =>
        props.current
            ? props.theme.colors.darkGray
            : props.theme.colors.mediumGray};
`;

export const PrizeImage = styled.div`
    min-height: 150px;
    margin-bottom: 1rem;
    background-size:cover!important;
    background: url(${props => props.thumbnail}) no-repeat center center};
    box-shadow: rgba(0,0,0,.15) 0 3px 16px;
    border-radius:5px;
`;
export const CompetitionEntry = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 150px;
    padding: 1rem 0;
    background: ${props =>
        props.winner
            ? props.theme.colors.altSuccess
            : props.theme.colors.lightGray};
    color: ${props => props.theme.colors.black};
    text-align: center;
    border-radius: ${props => (props.winner ? "5px" : "0")};
    p {
        color: ${props =>
            props.winner
                ? props.theme.colors.black
                : props.theme.colors.altGray};
    }
    strong {
        font-size: 1.8rem;
        line-height: 15px;
        display: block;
        text-transform: uppercase;
    }
`;
export const CompetitionDescription = styled.div`
    margin: 1rem 0 2rem 0;
    //columns: 2;
`;
