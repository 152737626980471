import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Range, Loading } from "@cortexglobal/rla-components";
import { PageTitle, SectionTitle } from "@cortexglobal/bmw-shared-components";

import { getCountdown } from "./countDownActions";
import {
    UserBonusCountdownWrapper,
    LabelContainer,
    RightLabel,
} from "./CountdownComponents";
class RewardCountdown extends Component {
    componentDidMount() {
        this.props.getCountdown();
    }

    state = {
        total: 0,
        current: 0,
    };

    calculateRewardInfo = (startDate, rewardDateString, total, today) => {
        const rewardDate = moment(rewardDateString);
        const daysFromStart = rewardDate.diff(startDate, "days");
        const daysToComplete = rewardDate.diff(today, "days");

        return { indent: 100 - (daysFromStart / total) * 100, daysToComplete };
    };
    render() {
        const { loading, loaded, data, error } = this.props.countdown;

        if (error) {
            return null;
        }

        if (!loaded || loading) {
            return <Loading />;
        }

        const {
            date,
            name,
            amount,
            interim_rewards,
            show_countdown,
            awaitingApproval,
        } = data;
        if (!show_countdown) {
            return null;
        }
        const today = moment();
        const rewardDate = moment(date);
        const startDate = moment(date).subtract(1, "year");
        const total = rewardDate.diff(startDate, "days");
        const current = today.diff(startDate, "days");
        // console.log(today, rewardDate, startDate, total, current);
        if (total === 0) {
            return null;
        }
        const daysLeft = total - current;

        return (
            <React.Fragment>
                <UserBonusCountdownWrapper>
                    <SectionTitle
                        title="Countdown to bonus"
                        subtitle="How long until your loyalty bonus?"
                        margin="0rem 0 0.2rem 0"
                    />
                    {awaitingApproval === true && (
                        <div>
                            <p>Reward currently with manager for approval</p>
                        </div>
                    )}

                    <LabelContainer>
                        {interim_rewards &&
                            interim_rewards.length > 0 &&
                            interim_rewards.map((reward) => {
                                const { indent } = this.calculateRewardInfo(
                                    startDate,
                                    reward.date,
                                    total,
                                    today
                                );
                                return (
                                    <RightLabel
                                        title={reward.name}
                                        indent={`${indent}%`}
                                    >
                                        &pound;{reward.amount}
                                    </RightLabel>
                                );
                            })}
                        <RightLabel title={name}>&pound;{amount}</RightLabel>
                    </LabelContainer>
                    <Range
                        name="countdown"
                        label=""
                        value={current}
                        minValue={0}
                        maxValue={total}
                        onChange={() => {}}
                        disabled={false}
                    />
                    <LabelContainer>
                        {interim_rewards &&
                            interim_rewards.length > 0 &&
                            interim_rewards.map((reward) => {
                                const { indent, daysToComplete } =
                                    this.calculateRewardInfo(
                                        startDate,
                                        reward.date,
                                        total,
                                        today
                                    );
                                return (
                                    <RightLabel indent={`${indent}%`}>
                                        {daysToComplete > 0
                                            ? `${daysToComplete} days to go`
                                            : `complete`}
                                    </RightLabel>
                                );
                            })}
                        <RightLabel>
                            {daysLeft} day{daysLeft > 1 && "s"} to go
                        </RightLabel>
                    </LabelContainer>
                </UserBonusCountdownWrapper>{" "}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    const { countdown } = state;

    return { countdown };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ getCountdown }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RewardCountdown);
