import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import { Row, Column, Loading } from "@cortexglobal/rla-components";
import { loadAllBadges } from "../badges/badgeActions";
import UserSummary from "../../components/global/UserSummary";
import { Badge } from "@cortexglobal/cortex-badges";
import { Link } from "react-router-dom";

const MainText = styled.div`
    font-size: 1.3rem;
    color: #000;
    line-height: 1.5rem;
    font-weight: bold;
    font-family: "bmw_groupregularbold", "Arial", sans-serif;
`;

const SubText = styled.div`
    font-size: 1rem;
    color: #979797;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
`;

class ViewBadge extends Component {
    componentDidMount() {
        if (!this.props.allBadges.loaded) {
            this.props.loadAllBadges();
        }
    }

    getBadge(badges, uuid) {
        if (badges) {
            let items = badges.filter(function(badge) {
                return badge.badge_uuid === uuid;
            });
            if (items) {
                return items[0];
            }
        }
        return null;
    }

    achieved(badge) {
        return badge.achievement_count > 0 ? true : false;
    }

    progress(badge) {
        if (badge.progress > 0) {
            return badge.progress + "%";
        }
        return "1%";
    }

    render() {
        const { allBadges } = this.props;
        const { uuid } = this.props.match.params;
        if (!allBadges.loaded || allBadges.loading) {
            return <Loading align="center" />;
        }
        const badge = this.getBadge(allBadges.data, uuid);
        return (
            <div className="page page-profile">
                <UserSummary />
                <Row>
                    <Column style={{ textAlign: "center" }}>
                        <Badge
                            showCount={false}
                            style={{
                                width: "50%",
                                maxWidth: "400px",
                                margin: "auto"
                            }}
                            badge={badge}
                        />
                        <MainText>
                            You earned the <br />
                            {badge.name} badge!
                        </MainText>
                        <SubText>{badge.description}</SubText>
                        <Link style={{ color: "#1C69D4" }} to="/profile/badges">
                            View my badges
                        </Link>
                    </Column>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { allBadges } = state.badges;

    return { allBadges };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadAllBadges }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewBadge);
