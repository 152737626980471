import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { bindActionCreators } from "redux";
import { Column } from "@cortexglobal/rla-components";
const ProfileList = styled.ul`
    list-style: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
`;
const ProfileListItem = styled.li`
    margin: 0px;
    padding: 1rem;
    font-weight: 800;
    span {
        font-weight: normal;
    }
    &:nth-of-type(even) {
        background-color: ${props => props.theme.colors.lightGray};
    }
`;
class ProfileDetails extends Component {
    render() {
        const { user } = this.props;

        return (
            user && (
                <React.Fragment>
                    <Column large={6}>
                        <ProfileList className="profileDetails">
                            <ProfileListItem>
                                Name:
                                <span>
                                    {" "}
                                    {user.first_name + " " + user.last_name}
                                </span>
                            </ProfileListItem>
                            <ProfileListItem>
                                Job Title:<span> {user.role.name}</span>
                            </ProfileListItem>
                            <ProfileListItem>
                                Retailer:<span> {user.group.name}</span>
                            </ProfileListItem>
                            <ProfileListItem>
                                TSMI User ID:<span> {user.external_ref}</span>
                            </ProfileListItem>
                        </ProfileList>
                    </Column>
                    <Column large={6}>
                        <ProfileList className="profileDetails">
                            <ProfileListItem>
                                Start Date:
                                <span> {user.additional_props.start_date}</span>
                            </ProfileListItem>

                            <ProfileListItem>
                                Email:<span> {user.email}</span>
                            </ProfileListItem>
                            <ProfileListItem>
                                Mobile:
                                <span> {user.additional_props.phone}</span>
                            </ProfileListItem>
                            <ProfileListItem>
                                Personal Email:
                                <span>
                                    {" "}
                                    {user.additional_props.personal_email}
                                </span>
                            </ProfileListItem>
                        </ProfileList>
                    </Column>
                </React.Fragment>
            )
        );
    }
}

const mapStateToProps = state => ({ loggedIn: state.auth.loggedIn });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDetails);
