import React, { Component } from "react";
import { Row, Column } from "@cortexglobal/rla-components";

import CurrentQuizSummary from "./CurrentQuizSummary";
import QuizHistory from "./QuizHistory";
class QuizSummary extends Component {
    state = {};

    render() {
        const { currentQuiz } = this.props;

        return (
            <React.Fragment>
                <Row>
                    <Column>
                        <h1
                            style={{
                                textTransform: "uppercase",
                                fontSize: "20px",
                                lineHeight: "24px",
                                margin: "17px 0 0"
                            }}
                        >
                            Weekly Quiz Results
                        </h1>
                    </Column>
                </Row>
                <CurrentQuizSummary currentQuiz={currentQuiz} />
                <Row>
                    <Column large={12} xlarge={8}>
                        <QuizHistory />
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

export default QuizSummary;
