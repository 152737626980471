import {
    GET_NEWS_SUMMARIES,
    GET_NEWS_STORY,
    CLEAR_NEWS_STORY,
    GETTING_NEWS_SUMMARIES,
    SELECT_NEWS_TIME,
} from "./newsActions";

const initialState = {
    current: {
        loaded: false,
        data: null,
    },
    summaries: {
        loaded: false,
        data: null,
    },
    news: {
        loaded: false,
        data: null,
    },
    selectedTime: null,
};

export default function imports(state = initialState, action) {
    switch (action.type) {
        case GETTING_NEWS_SUMMARIES:
            return {
                ...state,
                summaries: {
                    data: null,
                    loaded: false,
                },
            };
        case GET_NEWS_SUMMARIES:
            return {
                ...state,
                summaries: {
                    data: action.data,
                    loaded: true,
                },
            };

        case GET_NEWS_STORY:
            return {
                ...state,
                current: {
                    data: action.data,
                    loaded: true,
                },
            };

        case CLEAR_NEWS_STORY:
            return {
                ...state,
                current: initialState.current,
            };

        // case GET_NEWS_SECTIONS:
        //     return {
        //         ...state,
        //         news: {
        //             data: action.data,
        //             loaded: true
        //         }
        //     };

        case SELECT_NEWS_TIME:
            return {
                ...state,
                selectedTime: action.selectedTime,
            };

        default:
            return state;
    }
}
