import axios from "axios";

// export const LOADING_WEEKLY_QUIZ = "LOADING_WEEKLY_QUIZ";
// export const LOAD_WEEKLY_QUIZ = "LOAD_WEEKLY_QUIZ";
// export const loadWeeklyQuiz = () => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: LOADING_WEEKLY_QUIZ
//         });
//         return axios.get("/api/v1/quiz").then(response => {
//             const {
//                 completed,
//                 started,
//                 uuid,
//                 questions,
//                 current_question_uuid
//             } = response.data.data;
//             dispatch({
//                 type: LOAD_WEEKLY_QUIZ,
//                 completed,
//                 started,
//                 uuid,
//                 questions,
//                 current_question_uuid
//             });

//             if (started && !completed) {
//                 displayQuestionCountdown(dispatch, getState);
//             }
//         });
//     };
// };

export const LOADING_CURRENT_COMPETITION = "LOADING_CURRENT_COMPETITION";
export const LOAD_CURRENT_COMPETITION = "LOAD_CURRENT_COMPETITION";
export const loadCurrentCompetition = () => {
    return dispatch => {
        dispatch({
            type: LOADING_CURRENT_COMPETITION
        });
        return axios.get("/api/v1/competition/current").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_CURRENT_COMPETITION,
                data
            });
        });
    };
};

export const LOADING_COMPETITION_HISTORY = "LOADING_COMPETITION_HISTORY";
export const LOAD_COMPETITION_HISTORY = "LOAD_COMPETITION_HISTORY";
export const loadCompetitionHistory = () => {
    return dispatch => {
        dispatch({
            type: LOADING_COMPETITION_HISTORY
        });
        return axios.get("/api/v1/competition/history").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_COMPETITION_HISTORY,
                data
            });
        });
    };
};
