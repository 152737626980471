import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Card = styled.div`
    min-height: 180px;
    max-width: 100%;
    aspect-ratio: 350/180;
    margin: 0 auto;
    padding: 0px;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 20px;
    background-color: grey;
    a {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        display: block;
        transition: all ease 0.5s;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        -moz-box-shadow: inset 0 -45px 30px -10px rgba(0, 0, 0, 0.52);
        -webkit-box-shadow: inset 0 -45px 30px -10px rgba(0, 0, 0, 0.52);
        box-shadow: inset 0 -45px 30px -10px rgba(0, 0, 0, 0.52);
        &:hover {
            background-color: rgba(0, 0, 0, 0.3);
        }
        p {
            margin: 0 0 10px 10px;
            bottom: 15px;
            color: white;
            text-decoration: none;
            text-transform: uppercase;
        }
    }

    &.coming-soon {
        a {
            background-color: rgba(229, 229, 229, 0.92);
            cursor: not-allowed;

            &::before {
                content: "Coming soon";
                width: 100%;
                text-align: center;
                height: 50px;
                position: absolute;
                bottom: 30%;
                text-transform: uppercase;
                font-size: 2rem;
                color: rgba(0, 0, 0, 0.3);
            }

            small {
                position: absolute;
                bottom: 15px;
                right: 10px;
                color: white;
                font-weight: normal;
                text-decoration: underline;
            }
        }
    }

    &.slit-in-vertical {
        // -webkit-animation: fadeIn 0.5s ease-out both;
        animation: fadeIn 0.5s ease-out both;
    }

    &.slit-in-vertical-2 {
        // -webkit-animation: slit-in-vertical 0.5s ease-out both;
        animation: slit-in-vertical 0.5s ease-out both;
    }

    &.flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.solid {
        padding: 10px 30px;
        background: #f0f0f0;
        color: #000000;
        min-height: 0;

        a {
            box-shadow: none;
            color: #000;

            p {
                color: #000;
            }
        }
    }

    @media (min-width: 1051px) {
        min-height: 100px;

        &.solid {
            aspect-ratio: auto;
            padding: 20px 30px;
        }
    }

    // @-webkit-keyframes slit-in-vertical {
    //     0% {
    //         -webkit-transform: translateZ(-800px) rotateY(90deg);
    //         transform: translateZ(-800px) rotateY(90deg);
    //         opacity: 0;
    //     }
    //     54% {
    //         -webkit-transform: translateZ(-160px) rotateY(87deg);
    //         transform: translateZ(-160px) rotateY(87deg);
    //         opacity: 1;
    //     }
    //     100% {
    //         -webkit-transform: translateZ(0) rotateY(0);
    //         transform: translateZ(0) rotateY(0);
    //     }
    // }
    @keyframes slit-in-vertical {
        0% {
            // -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
            opacity: 0;
        }
        54% {
            // -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
            opacity: 1;
        }
        100% {
            // -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
        }
    }
    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const CardLabel = styled.div`
    h1,
    h2 {
        font-size: 1.125rem;
        line-height: 1.2;
        margin: 0px;
    }

    small,
    p {
        position: relative;
        letter-spacing: -0.3px;
        color: #888889;
        margin-bottom: 5px;
        font-size: 0.75rem;
    }
`;

export const CardActionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 -6px 20px;

    a,
    span,
    button,
    div {
        flex: 1;
        max-width: 50%;
    }
`;

export const CardLink = styled(Link)`
    text-decoration: none;
`;

export const CardButtonStyle = css`
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    overflow: hidden;
    padding: 20px 20px;
    text-align: center;
    margin: 0 6px;
    font-size: 16px;
`;

export const CardLinkButton = styled(Link)`
    ${CardButtonStyle}
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    font-family: "bmw_groupbold";
`;
export const CardInfoButton = styled.div`
    ${CardButtonStyle}
    background: ${(props) => props.theme.colors.black};
    span {
        color: ${(props) => props.theme.colors.secondary};

        strong {
            color: ${(props) => props.theme.colors.white};
            font-family: "bmw_groupbold";
            @media (min-width: 1051px) {
                display: block;
            }
        }
    }

    &.white {
        background: #f5f5f5;
        span {
            color: rgb(0 0 0 / 0.6);

            strong {
                color: #000000;
            }
        }
    }
`;
