import { GET_ROLES } from "./roleActions";

const initialState = {
    loaded: false,
    data: null
};

export default function roles(state = initialState, action) {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,

                data: action.data.map(role => {
                    return {
			...role,
                        text: role.name,
                        value: role.alias
                    };
                }),
                loaded: true
            };

        default:
            return state;
    }
}
