import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "@cortexglobal/rla-components";
import ChampionsLeagueBackground from "../../img/Rectangle_4350.png";
import ChampionsLeagueLogo from "../../img/champions_league_logo.png";

export const CardLink = styled(Link)`
    text-decoration: none;
`;

function positionSuffix(position) {
    let suffix = "th";

    // Position Suffix
    switch (position) {
        case 1:
            suffix = "st";
            break;

        case 2:
            suffix = "nd";
            break;

        case 3:
            suffix = "rd";
            break;
    }

    return suffix;
}

export const MobileChampionStats = props => {
    const suffix = positionSuffix(props.championEntry.position);

    return (
        <React.Fragment>
            <MobileStatisticBlock>
                <span>Your national position:</span>
            </MobileStatisticBlock>
            <MobileStatistic>
                <span>
                    {props.championEntry.national_position}
                    {suffix}
                </span>
            </MobileStatistic>
        </React.Fragment>
    );
};

export const ChampionStats = props => (
    <React.Fragment>
        <StatisticBlock>
            <span>{props.title}</span>
        </StatisticBlock>
        <Statistic>
            <span>{props.stat}</span>
        </Statistic>
    </React.Fragment>
);

export const ChampionPosition = props => {
    const suffix = positionSuffix(props.stat);

    return (
        <React.Fragment>
            <StatisticBlock>
                <span>{props.title}</span>
            </StatisticBlock>
            <Statistic>
                <span>
                    {props.stat}
                    {suffix}
                </span>
            </Statistic>
        </React.Fragment>
    );
};

export const ChampionsLeagueTable = props => (
    <LeagueTable>
        <thead>
            <tr>
                <th>Position</th>
                <th
                    style={{
                        textAlign: "left"
                    }}
                >
                    Technician Name
                </th>
                <th>Points</th>
            </tr>
        </thead>
        <tbody>
            {props.champions.data.map((leagueEntry, index) => {
                const leagueStyle = {};
                const leagueStyleHighlight = {
                    backgroundColor: "#1C69D4",
                    color: "white"
                };

                // Catch null entries
                if (leagueEntry.position == 0 || leagueEntry.score == null) {
                    return null;
                }

                const style =
                    props.user.uuid === leagueEntry.user_uuid
                        ? leagueStyleHighlight
                        : leagueStyle;

                return (
                    <LeagueRow style={style} key={index}>
                        <td>{leagueEntry.position}</td>
                        <td
                            style={{
                                textAlign: "left",
                                textTransform: "capitalize"
                            }}
                        >
                            {leagueEntry.user.toLowerCase()}
                        </td>
                        <td>{leagueEntry.score}</td>
                    </LeagueRow>
                );
            })}
        </tbody>
    </LeagueTable>
);

class MobileButton_ extends Component {
    render = () => {
        return <Button {...this.props}>{this.props.children}</Button>;
    };
}

export const SmallText = styled.small`
    display: block;
    color: #9f9f9f;
    margin-top: 5px;
`;

export const BackgroundImage = styled.div`
    width: 100%;
    height: 250px;
    background-image: url(${ChampionsLeagueBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;

    img {
        width: 250px;
        height: 157px;
        margin-top: 50px;
    }
`;

export const StatisticBlock = styled.div`
    width: 70%;
    height: 60px;
    float: left;
    background-color: #f5f5f5;
    display: block;

    span {
        margin: 20px;
        display: block;
        font-weight: bold;
        font-size: 1.2em;
    }

    @media only screen and (max-width: 400px) {
        display: none;
    }
`;

export const MobileStatisticBlock = styled.div`
    width: 70%;
    height: 60px;
    float: left;
    background-color: #f5f5f5;
    display: block;
    span {
        margin: 20px;
        display: block;
        font-weight: bold;
        font-size: 1.2em;
    }

    @media only screen and (min-width: 400px) {
        display: none;
    }
`;

export const MobileButton = styled(MobileButton_)`
    @media only screen and (max-width: 350px) {
        margin: 0.5em 0;
    }
`;

export const MobileStatistic = styled.div`
    height: 60px;
    width: 30%;
    float: left;
    background-color: #1C69D4;
    display: block;
    color: white;
    font-weight: bold;
    font-size: 1.4em;

    span {
        text-align: center;
        display: block
        margin: 15px 0;
        font-weight: bold;
        font-size: 1.4em;
    }

    @media only screen and (min-width: 400px) {
        display: none;
    }
`;

export const Statistic = styled.div`
    height: 60px;
    width: 30%;
    float: left;
    background-color: #1C69D4;
    display: block;
    color: white;
    font-weight: bold;
    font-size: 1.4em;

    span {
        text-align: center;
        display: block
        margin: 15px 0;
        font-weight: bold;
        font-size: 1.4em;
    }

    @media only screen and (max-width: 400px) {
        display: none;
    }
`;

export const VideoBackground = styled.div`
    max-width: 1400px;
    background-color: #f5f5f5;
    display: block;
    margin-top: 2em;
    text-align: center;

    video {
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 550px;
        padding: 50px 0;
    }
`;

export const Phase = styled.li`
    button {
        margin: 0 2em 1.5em 0;
        width: 100%;
        font-size: 13px;

        @media only screen and (max-width: 400px) {
            margin: 0px 2px 20px 2px;
            width: 18.5% !important;
            display: inline;
            line-height: 16px !important;
            float: left;
            font-size: 10px;
            font-weight: bold;
        }
    }
`;

export const PhaseName = styled.div`
    @media only screen and (max-width: 400px) {
        display: none;
    }
`;

export const MobilePhaseName = styled.div`
    @media only screen and (min-width: 401px) {
        display: none;
    }
`;

export const LeagueTable = styled.table`
    border-spacing: 0;
    width: 100%;
    text-align: center;
    th {
        padding: 0.5rem;
        color: #939393;
        text-align: center;
        font-size: 1.1em;
    }
    td {
        padding: 0.5rem;
    }
`;

export const LeagueRow = styled.tr`
    background: #f0f0f0;
    padding: 0.3rem;
    :nth-of-type(even) {
        background: #d9d9d9;
    }
`;

export const ALink = props => <a {...props}>{props.children}</a>;

export const LeagueLink = styled.a`
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 1.2em;
    margin-bottom: 0.6em;
    color: #888889;
    font-size: 0.95em;
`;
