import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import { formatPosition } from "@cortexglobal/bmw-shared-components";
import HeroImg from "../../img/bmw-service-image-tester.jpg";
import UserSummary from "../../components/global/UserSummary";
import RewardCountdown from "../../components/countdowns/RewardCountdown";
import MyBadgesInfoCard from "../badges/MyBadgesInfoCard";

import { loadQuizHistory } from "../game/quiz/quizActions";
import { loadLeague } from "../game/comparison/comparisonActions";
import { loadCurrentCompetition } from "../competition/competitionActions";

import InfoCards from "../../components/infoCards/InfoCards";

import AppContext from "../../components/global/AppContext";
const Hero = styled.div`
    background: url(${HeroImg});
    background-size: cover;
    background-position: 10% 65%;
    max-width: 100%;
    min-height: 120px;
`;

const DesktopPlaceHolder = styled(Row)`
    @media (min-width: ${(props) => props.theme.breakpoints.xlarge}px) {
        min-height: 200px;
    }
`;

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            score: "...",
            position: "...",
        };
    }

    componentDidMount() {
        if (!this.props.quizHistory.loaded) {
            this.props.loadQuizHistory();
        } else {
            this.setState({
                score: this.generateQuizTotal(this.props.quizHistory),
            });
        }

        // if (!this.props.comparisonLeague.loaded) {
        this.props
            .loadLeague()
            .then((data) => this.generateComparisonPosition());
        // }
        // this.setState({
        //     position: formatPosition(this.generateComparisonPosition()),
        // });
        if (!this.props.currentCompetition.loaded && !this.props.currentCompetition.loading) {
            this.props.loadCurrentCompetition().then(() => {
                this.setState({
                    competition: this.props.currentCompetition
                });
            });
        } else {
            this.setState({
                competition: this.props.currentCompetition
            });
        }

    }

    generateQuizTotal = (history) => {
        const totals = history.data.reduce(
            (totals, quiz) => {
                quiz.questions.map((question) => {
                    totals.total++;
                    question.correct ? totals.correct++ : totals.wrong++;
                    return question;
                });
                return totals;
            },
            { total: 0, correct: 0, wrong: 0 }
        );

        return totals.total > 0
            ? Math.round((totals.correct / totals.total) * 100)
            : 0;
    };

    generateComparisonPosition = () => {
        const { comparisonLeague, user } = this.props;
        // console.log({ comparisonLeague, user });
        const userLeaguePosition =
            comparisonLeague.data.findIndex((userEntry) => {
                return userEntry.user_uuid === user.uuid;
            }) + 1;
        this.setState({
            position: formatPosition(userLeaguePosition),
        });
        return userLeaguePosition;
    };

    render() {
        const { userCanViewRewards } = this.props;
        return (
            <AppContext.Consumer>
                {({ user, points, mobileView }) => {
                    // const isNewEmployee =
                    //     user.additional_props.start_date !== "" &&
                    //     user.additional_props.probation_date !== "" &&
                    //     moment(
                    //         user.additional_props.start_date,
                    //         "DD/MM/YYYY"
                    //     ).isAfter(PROGRAM_START_DATE);

                    return (
                        <div className="page page-home">
                            {/* {mobileView && <Hero />} */}
                            <UserSummary
                                userCanViewRewards={userCanViewRewards}
                            />

                            <div
                                style={{
                                    position: "relative",
                                    maxWidth: "1400px",
                                    marginTop: "20px",
                                }}
                            >
                                {userCanViewRewards && (
                                    <DesktopPlaceHolder>
                                        <Column
                                            medium={12}
                                            xlarge={8}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                minHeight: "200px",
                                                alignItems: "start",
                                            }}
                                        >
                                            <RewardCountdown user={user} />
                                        </Column>
                                    </DesktopPlaceHolder>
                                )}
                                <InfoCards
                                    extend={true}
                                    user={user}
                                    userCanViewRewards={userCanViewRewards}
                                    points={points}
                                    score={this.state.score}
                                    position={this.state.position}
                                    competition={this.state.competition}
                                />
                                <MyBadgesInfoCard
                                    userCanViewRewards={userCanViewRewards}
                                />
                            </div>
                        </div>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.auth;
    const { history: quizHistory } = state.quiz;
    const { league: comparisonLeague } = state.comparison;
    const { current: currentCompetition } = state.competition;

    return {
        user,
        quizHistory,
        comparisonLeague,
        currentCompetition,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            loadQuizHistory,
            loadLeague,
            loadCurrentCompetition,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
