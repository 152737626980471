import {
    LOADING_CHAMPIONS_LEAGUE,
    LOAD_CHAMPIONS_LEAGUE
} from "./championsActions";

export const initialState = {
    loaded: false,
    loading: false,
    data: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_CHAMPIONS_LEAGUE:
            return {
                ...state,
                loading: true
            };

        case LOAD_CHAMPIONS_LEAGUE:
            return {
                ...state,
                loaded: true,
                loading: false,
                data: action.data
            };

        default:
            return state;
    }
};
