import React, { Component } from "react";
import styled from "styled-components";

const StyledHeader = styled.header`
    text-align: center;
    text-transform: uppercase;
    h1 {
        margin-top: 2rem;
        font-size: 3rem;
        text-shadow: #0089ff 1px 0 10px;
        @media only screen and (max-width: 400px) {
            font-size: 2rem;
            margin-top: 1rem;
            margin-bottom: 0.2rem;
        }
        span {
            color: #00132e;
            margin-left: -11px;
            margin-right: -11px;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
                1px 1px 0 #fff;
        }
    }
`;

class ThisOrThatHeader extends Component {
    render() {
        return (
            <StyledHeader>
                <h1>
                    This <span>Or</span> That
                </h1>
            </StyledHeader>
        );
    }
}

export default ThisOrThatHeader;
