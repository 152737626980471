import React, { Component } from "react";
import { Row, Column } from "@cortexglobal/rla-components";
import ComparisonItem from "./ComparisonItem";
import QuestionRow from "./QuestionRow";
import ThisOrThatHeader from "../../../components/ThisOrThatHeader";

class ComparisonAnswer extends Component {
    render() {
        const { current } = this.props;
        return (
            <div className="page">
                <Row center={true}>
                    <Column>
                        <ThisOrThatHeader />
                    </Column>
                </Row>
                <QuestionRow
                    center={true}
                    question={current.previousComparison.statistic.question}
                    total={current.total}
                />
                <Row center={true}>
                    {current.previousComparison.detail.map(item => (
                        <Column small={6} key={item.uuid}>
                            <ComparisonItem
                                item={item}
                                statistic={current.previousComparison.statistic}
                                selectedItemUuid={
                                    current.previousComparison
                                        .selected_item_uuid
                                }
                                correctItemUuid={
                                    current.previousComparison.correct_item_uuid
                                }
                                displayAnswer={true}
                            />
                        </Column>
                    ))}
                </Row>

                <Row center={true}>
                    <Column>
                        <h5 style={{ textAlign: "center" }}>
                            {current.previousComparison.correct
                                ? "Next question"
                                : "Summary in"}
                            : {current.answerCountdownTimer}
                        </h5>
                    </Column>
                </Row>
            </div>
        );
    }
}

export default ComparisonAnswer;
