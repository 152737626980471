import React, { Component } from "react";
import styled from "styled-components";
import { PullRow, Column } from "@cortexglobal/rla-components";
import { colors } from "@cortexglobal/bmw-shared-components";

import { SectionTitle } from "@cortexglobal/bmw-shared-components";

const TotalRow = styled.div`
    display: flex;
    flex-direction: row;
`;
const TotalColumn = styled(Column)`
    position: relative;
    text-align: center;
    padding: 1rem;
    background: ${colors.lightGray};
    h4 {
        color: ${colors.darkGray};
    }
    strong {
        font-size: 40px;
        line-height: 15px;
        color: ${colors.black};
    }
    &:after {
        content: "";
        width: 1px;
        position: absolute;
        background: #dedede;
        right: 0;
        top: 18.5px;
        bottom: 18.5px;
    }
    &:last-of-type {
        &:after {
            display: none;
        }
    }
`;
class QuizHistoryOverall extends Component {
    generateTotals = (history) => {
        return history.data.reduce(
            (totals, quiz) => {
                quiz.questions.map((question) => {
                    totals.total++;
                    question.correct ? totals.correct++ : totals.wrong++;
                    return question;
                });
                return totals;
            },
            { total: 0, correct: 0, wrong: 0 }
        );
    };
    render() {
        const { history } = this.props;

        if (!history || !history.data || !history.loaded) {
            return null;
        }
        const { total, correct, wrong } = this.generateTotals(history);

        return (
            <React.Fragment>
                <PullRow>
                    <Column>
                        <SectionTitle
                            title="Overall Stats"
                            subtitle="What's your knowledge like?"
                        />
                    </Column>
                </PullRow>
                <PullRow>
                    <Column large={3}>
                        <TotalRow>
                            <TotalColumn>
                                <h4>Current score</h4>
                                <strong>
                                    {total > 0
                                        ? Math.round((correct / total) * 100)
                                        : 0}
                                    %
                                </strong>
                            </TotalColumn>
                        </TotalRow>
                    </Column>
                    <Column large={9}>
                        <TotalRow>
                            <TotalColumn medium={4}>
                                <h4>Questions</h4>
                                <strong>{total}</strong>
                            </TotalColumn>
                            <TotalColumn medium={4}>
                                <h4>Correct</h4>
                                <strong>{correct}</strong>
                            </TotalColumn>
                            <TotalColumn medium={4}>
                                <h4>Wrong</h4>
                                <strong>{wrong}</strong>
                            </TotalColumn>
                        </TotalRow>
                    </Column>
                </PullRow>
            </React.Fragment>
        );
    }
}

export default QuizHistoryOverall;
