import React, { Component } from "react";
import styled from "styled-components";
import QuizAnswer from "./QuizAnswer";
import { Column, PullRow } from "@cortexglobal/rla-components";
import { initialState } from "./quizReducer";
const QuestionText = styled.h3`
    color: ${props => props.theme.colors.black}
    font-size: 16px;
    line-height: 20px;
    span {
        float: right;
    }
`;
const LightText = styled.p`
    color: ${props => props.theme.colors.mediumGray}
    font-size: 16px;
    line-height: 20px;
    span {
        float: right;
    }
`;
const ProgressBar = styled.div`
position:relative;
width:100%;
overflow:hidden;
margin-bottom:5em;
background: #ff0080; /* Old browsers */
    background: -moz-linear-gradient(
        left,
        #ff0080 0%,
        #ffeb00 100%
    );
    background: -webkit-linear-gradient(
        left,
        #ff0080 0%,
        #ffeb00 100%
    );
    background: linear-gradient(
        to right,
        #ff0080 0%,
        #ffeb00 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0080', endColorstr='#ffeb00',GradientType=1 ); /* IE6-9 */
height:20px;
span {
    height:20px
    display:inline-block;
    transition:all 1s ease;
    background:${props => {
        return props.theme.colors.lightGray;
    }};
    position:absolute;
    width:100%;

    //if using regular animation version use the below line
    right: -${props => props.percentageWidth}%;
    // otherwise use the keyframes block below
    // @keyframes run {
    //    0% { right: -100%;}
    //    100%{ right: 0;}
    // }
    // animation:linear;
    // animation-name: run;
    // animation-duration: ${props => props.percentageWidth}s;
}`;

const QuestionImage = styled.img`
    max-width: 100%;
`;

class QuizQuestion extends Component {
    calculateProgress = questionComplete => {
        const { questionCountdownTimer, answerCountdownTimer } = this.props;
        const {
            answerCountdownTimer: initialAnswerCountdownTimer,
            questionCountdownTimer: initialQuestionCountdownTimer
        } = initialState.current;
        if (questionComplete) {
            return (answerCountdownTimer / initialAnswerCountdownTimer) * 100; //for standard animation
            //return initialAnswerCountdownTimer; //for keyframe animation
        } else {
            return (
                (questionCountdownTimer / initialQuestionCountdownTimer) * 100 //for standard animation
            );
            //return initialQuestionCountdownTimer; //for keyframe animation
        }
    };
    render() {
        const {
            question,
            answer,
            correct,
            correctAnswer,
            questionCountdownTimer,
            answerCountdownTimer
        } = this.props;
        const questionComplete = answer !== null || correct !== null;
        return (
            <React.Fragment>
                {question.image && (
                    <PullRow>
                        <Column>
                            <QuestionImage
                                src={question.image}
                                alt={question.question}
                            />
                        </Column>
                    </PullRow>
                )}
                <PullRow>
                    <Column>
                        <QuestionText>{question.question}</QuestionText>
                    </Column>
                </PullRow>
                <PullRow equaliseChildHeight>
                    {question.answers.map((possibleAnswer, index) => {
                        return (
                            <Column
                                key={possibleAnswer.uuid}
                                medium={6}
                                style={{
                                    marginBottom: "1.2em",
                                    display: "flex"
                                }}
                            >
                                <QuizAnswer
                                    index={index}
                                    answer={possibleAnswer}
                                    questionUuid={question.uuid}
                                    questionComplete={questionComplete}
                                    selectedAnswer={answer}
                                    correctAnswer={correctAnswer}
                                    submitQuizAnswer={
                                        this.props.submitQuizAnswer
                                    }
                                />
                            </Column>
                        );
                    })}
                </PullRow>
                <PullRow>
                    <Column>
                        <LightText>
                            {questionComplete
                                ? "Next question in: "
                                : "Time remaining: "}
                            <span>
                                {questionComplete
                                    ? answerCountdownTimer
                                    : questionCountdownTimer}{" "}
                                Seconds
                            </span>
                        </LightText>
                        {!questionComplete && (
                            <ProgressBar
                                percentageWidth={this.calculateProgress(
                                    questionComplete
                                )}
                            >
                                <span />
                            </ProgressBar>
                        )}
                    </Column>
                </PullRow>
            </React.Fragment>
        );
    }
}

export default QuizQuestion;
