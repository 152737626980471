import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Row,
    Column,
    FormikRadioArray,
    FormikInput,
    Button,
    Modal
} from "@cortexglobal/rla-components";

import ExitSurveyQuestion from "./ExitSurveyQuestion";

const validationSchema = Yup.object().shape({
    consent_given: Yup.string()
        .oneOf(["0", "1"], "Please select Yes or No")
        .nullable()
        .required("Required"),
    email: Yup.string().when("consent_given", {
        is: val => val == "1",
        then: Yup.string()
            .email()
            .required("Required"),
        otherwise: Yup.string().nullable()
    })
});

const ConsentModal = ({ submitConsent, showConsentDialog, initialValues }) => {
    return (
        <Modal
            visible={showConsentDialog}
            onClose={() => {}}
            showCloseButton={false}
        >
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={submitConsent}
                validationSchema={validationSchema}
            >
                {props => {
                    const {
                        values,
                        errors,
                        isSubmitting,
                        handleSubmit
                    } = props;
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Column>
                                    <h2>
                                        Thank you, your survey has been
                                        submitted.
                                    </h2>
                                    <p>
                                        Please answer the below before exiting
                                        this page to avoid missing out on
                                        receiving your gift.
                                    </p>
                                </Column>
                                <Column>
                                    <h2>Consent to Contact</h2>
                                    <p>
                                        In order to receive a &pound;10 Amazon
                                        e-Voucher as a thank you for filling in
                                        the survey, please state if you are
                                        happy to receive an email from us.
                                    </p>
                                </Column>
                                <Column>
                                    <FormikRadioArray
                                        options={[
                                            { value: "1", text: "Yes" },
                                            { value: "0", text: "No" }
                                        ]}
                                        label=""
                                        name="consent_given"
                                        inlineRadioButtons={true}
                                    />
                                </Column>
                                {values.consent_given &&
                                values.consent_given === "1" ? (
                                    <Column style={{ marginTop: "1.6rem" }}>
                                        <FormikInput
                                            label="Please enter your email address"
                                            name="email"
                                            value={values.email}
                                        />
                                    </Column>
                                ) : (
                                    <Column style={{ marginTop: "0.6rem" }}>
                                        <p>
                                            Note: By selecting no, you will be
                                            ineligible to receive the &pound;10
                                            Amazon e-Voucher.
                                        </p>
                                    </Column>
                                )}
                            </Row>
                            <Row>
                                <Column medium={6} style={{ margin: "1rem 0" }}>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        expanded
                                    >
                                        Finish
                                    </Button>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default ConsentModal;
