import {
    LOADING_POINTS_SUMMARY,
    LOAD_POINTS_SUMMARY,
    CHANGE_POINTS_BALANCE
} from "./pointsActions";

export const initialState = {
    loaded: false,
    loading: false,
    stats: {}
};

export default function summary(state = initialState, action) {
    switch (action.type) {
        case LOADING_POINTS_SUMMARY:
            return { ...state, loading: true };

        case LOAD_POINTS_SUMMARY:
            return {
                ...state,
                loaded: true,
                loading: false,
                stats: action.data
            };

        case CHANGE_POINTS_BALANCE:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    balance: state.stats.balance - action.amount
                }
            };

        default:
            return state;
    }
}
