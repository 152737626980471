import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { PullRow, Column, Loading } from "@cortexglobal/rla-components";
import UserSummary from "../../components/global/UserSummary";
import BadgesGrid from "./BadgesGrid";
import { loadAllBadges, loadBadgeTypes } from "../badges/badgeActions";

// const FlexColumn = styled(Column)`
//     display: flex;
//     flex-direction: column;
//     flex-wrap:
// `;
// const CloseSpace = styled.div`
//     margin-top: -1.8rem;
// `;

const LinedDiv = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 1.5em;
    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background: #707070;
    }
    &:before {
        display: none;
    }
    &:after {
        display: none;
    }
    @media screen and (min-width: 700px) {
        &:before {
            left: 33.33333%;
            display: block;
        }
        &:after {
            right: 33.33333%;
            display: block;
        }
    }
`;

const SectionTitle = styled.div`
    margin: 0;
    font-weight: bold;
    font-size: 1rem;
`;

const SubText = styled.div`
    margin: 0;
    font-size: 0.7rem;
    color: #9f9f9f;
    padding-bottom: 3em;
`;

class UserBadges extends Component {
    componentDidMount() {
        if (!this.props.types.loaded) {
            this.props.loadBadgeTypes();
        }
        if (!this.props.allBadges.loaded) {
            this.props.loadAllBadges();
        }
    }

    achieved(badge) {
        return badge.achievement_count > 0 ? true : false;
    }

    progress(badge) {
        if (badge.progress > 0) {
            return badge.progress + "%";
        }
        return "1%";
    }

    distinctTypes(allBadges) {
        const uniqueArr = [...new Set(allBadges.map(data => data.type_name))];
        return uniqueArr.sort();
    }

    filterBadges = key => {
        const { data } = this.props.allBadges;
        return data.filter(badge => badge.type_name === key);
    };

    render() {
        const { allBadges, types } = this.props;

        if (
            !allBadges.loaded ||
            allBadges.loading ||
            !types.loaded ||
            types.loading
        ) {
            return <Loading align="center" />;
        }
        return (
            <div className="page page-profile">
                <UserSummary />
                {/*<Row>
                    <Column>
                        <SummaryLinkBPack to="/profile">
                            <PageTitle
                                title="My Badges"
                                subtitle="can you complete all the challenges?"
                            />
                        </SummaryLinkBPack>
                    </Column>
                </Row>*/}
                <LinedDiv>
                    {types.data.map(badgeType => {
                        return (
                            <Column large={4} key={badgeType.id}>
                                <Column>
                                    <SectionTitle>
                                        {badgeType.name}
                                    </SectionTitle>
                                    <SubText>{badgeType.description}</SubText>
                                </Column>
                                <PullRow>
                                    <BadgesGrid
                                        badges={this.filterBadges(
                                            badgeType.name
                                        )}
                                    />
                                </PullRow>
                            </Column>
                        );
                    })}
                </LinedDiv>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { allBadges, types } = state.badges;

    return { allBadges, types };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadAllBadges, loadBadgeTypes }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserBadges);
