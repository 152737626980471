import {
    LOADING_ALL_BADGES,
    LOAD_ALL_BADGES,
    LOADING_BADGE_SUMMARY,
    LOAD_BADGE_SUMMARY,
    LOADING_BADGE_TYPES,
    LOAD_BADGE_TYPES
} from "./badgeActions";

const baseTemplate = {
    loaded: false,
    loading: false,
    data: {}
};

export const initialState = {
    types: baseTemplate,
    allBadges: baseTemplate,
    badgeSummary: baseTemplate
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ALL_BADGES:
            return {
                ...state,
                allBadges: {
                    ...state.allBadges,
                    loading: true
                }
            };
        case LOAD_ALL_BADGES:
            return {
                ...state,
                allBadges: {
                    ...state.allBadges,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        case LOADING_BADGE_SUMMARY:
            return {
                ...state,
                badgeSummary: {
                    ...state.badgeSummary,
                    loading: true
                }
            };
        case LOAD_BADGE_SUMMARY:
            return {
                ...state,
                badgeSummary: {
                    ...state.badgeSummary,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };

        case LOADING_BADGE_TYPES:
            return {
                ...state,
                types: {
                    ...state.types,
                    loading: true
                }
            };
        case LOAD_BADGE_TYPES:
            return {
                ...state,
                types: {
                    ...state.types,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        default:
            return state;
    }
};
