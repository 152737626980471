import axios from "axios";

export const DECREMENT_COMPARISON_QUESTION_COUNTDOWN_TIMER =
    "DECREMENT_COMPARISON_QUESTION_COUNTDOWN_TIMER";
const displayQuestionCountdown = (dispatch, getState) => {
    const { questionCountdownTimer, uuid, completed, selectedAnswerUuid } =
        getState().comparison.current;
    //If the user has completed the game
    //(e.g. the server 'completed' the game as they got one incorrect)
    //or they've aswered the question,
    //Just return to stop the countdown
    if (completed || selectedAnswerUuid !== "") {
        return;
    }
    //If there's still time left decrement the timer
    //and loop back round by calling this again.
    //Otherwise submit 'false' as the answer because they've run out of time
    if (questionCountdownTimer > 0) {
        dispatch({
            type: DECREMENT_COMPARISON_QUESTION_COUNTDOWN_TIMER,
        });

        setTimeout(displayQuestionCountdown, 1000, dispatch, getState);
    } else {
        dispatch(submitComparisonGameAnswer(uuid, false));
    }
};

export const START_COMPARISON_GAME = "START_COMPARISON_GAME";
export const startComparisonGame = () => {
    return (dispatch) => {
        dispatch({
            type: START_COMPARISON_GAME,
        });
        dispatch(loadComparisonGame());
    };
};

export const RESET_COMPARISON_GAME = "RESET_COMPARISON_GAME";
export const resetComparisonGame = () => {
    return (dispatch) => {
        dispatch(loadBestComparisonGame());
        dispatch({
            type: RESET_COMPARISON_GAME,
        });
    };
};
export const RESET_COMPARISON_QUESTION = "RESET_COMPARISON_QUESTION";
const displayNextQuestion = (dispatch, getState) => {
    const { completed } = getState().comparison.current;
    //If the game hasn't finished reset their question timer and start counting down again
    if (!completed) {
        dispatch({ type: RESET_COMPARISON_QUESTION });
    }
    displayQuestionCountdown(dispatch, getState);
};

export const DECREMENT_COMPARISON_ANSWER_COUNTDOWN_TIMER =
    "DECREMENT_COMPARISON_ANSWER_COUNTDOWN_TIMER";
const displayAnswerCountdown = (dispatch, getState) => {
    const { answerCountdownTimer } = getState().comparison.current;
    if (answerCountdownTimer > 0) {
        dispatch({
            type: DECREMENT_COMPARISON_ANSWER_COUNTDOWN_TIMER,
        });

        setTimeout(displayAnswerCountdown, 1000, dispatch, getState);
    } else {
        displayNextQuestion(dispatch, getState);
    }
};

export const LOADING_COMPARISON_GAME_LEAGUE = "LOADING_COMPARISON_GAME_LEAGUE";
export const LOAD_COMPARISON_GAME_LEAGUE = "LOAD_COMPARISON_GAME_LEAGUE";
export const loadLeague = () => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADING_COMPARISON_GAME_LEAGUE,
        });
        return axios.get("/api/v1/comparison/league").then((response) => {
            const { data } = response.data;
            dispatch({
                type: LOAD_COMPARISON_GAME_LEAGUE,
                data,
            });
            return data;
        });
    };
};

export const LOADING_BEST_COMPARISON_GAME = "LOADING_BEST_COMPARISON_GAME";
export const LOAD_BEST_COMPARISON_GAME = "LOAD_BEST_COMPARISON_GAME";
export const loadBestComparisonGame = () => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADING_BEST_COMPARISON_GAME,
        });
        return axios
            .get("/api/v1/comparison/best-game")
            .then((response) => {
                const { completed, total_correct, game_uuid } =
                    response.data.data;
                dispatch({
                    type: LOAD_BEST_COMPARISON_GAME,
                    completed,
                    total: total_correct,
                    uuid: game_uuid,
                });
            })
            .catch((error) => {
                //If this is the first time the user has played they will get a 404
                //this is handled by setting some empty defaults in the reducer so
                //can be ignored, other errors should throw again
                if (error.response && error.response.status !== 404) {
                    throw new Error("Error loading best game");
                }
            });
    };
};

export const LOADING_COMPARISON_GAME = "LOADING_COMPARISON_GAME";
export const LOAD_COMPARISON_GAME = "LOAD_COMPARISON_GAME";
export const loadComparisonGame = () => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADING_COMPARISON_GAME,
        });
        return axios.get("/api/v1/comparison").then((response) => {
            const { completed, started, total, uuid, current, previous } =
                response.data.data;
            dispatch({
                type: LOAD_COMPARISON_GAME,
                completed,
                started,
                total,
                uuid,
                current,
                previous,
            });
            displayQuestionCountdown(dispatch, getState);
        });
    };
};

export const SUBMIT_COMPARISON_GAME_ANSWER = "SUBMIT_COMPARISON_GAME_ANSWER";
export const SELECT_COMPARISON_GAME_ANSWER = "SELECT_COMPARISON_GAME_ANSWER";
export const submitComparisonGameAnswer = (
    comparisonUuid,
    selectedAnswerUuid
) => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADING_COMPARISON_GAME,
        });
        dispatch({
            type: SELECT_COMPARISON_GAME_ANSWER,
            selectedAnswerUuid,
        });
        return axios
            .put(`/api/v1/comparison/${comparisonUuid}`, { selectedAnswerUuid })
            .then((response) => {
                const { completed, started, total, uuid, current, previous } =
                    response.data.data;
                dispatch({
                    type: SUBMIT_COMPARISON_GAME_ANSWER,
                    completed,
                    selectedAnswerUuid,
                    started,
                    total,
                    uuid,
                    current,
                    previous,
                });

                displayAnswerCountdown(dispatch, getState);
            });
    };
};
