import React, { Component } from "react";
import moment from "moment";
import {
    VictoryChart,
    VictoryLine,
    VictoryScatter,
    VictoryAxis,
    VictoryContainer
} from "victory";
import { PullRow, Column } from "@cortexglobal/rla-components";
import { getNextWeekDay } from "@cortexglobal/cortex-utilities";
import { colors } from "@cortexglobal/bmw-shared-components";
import { SectionTitle } from "@cortexglobal/bmw-shared-components";
import find from "lodash/find";

class QuizHistoryQuarter extends Component {
    //TODO - Look at extracting this out into a helper
    // getNextWeekDay = (startDate, isoWeekday = 1) => {
    //     const startDay = startDate.isoWeekday();

    //     // if we haven't yet passed the day of the week that I need:
    //     if (startDay <= isoWeekday) {
    //         // then just give me this week's instance of that day
    //         return moment().isoWeekday(isoWeekday);
    //     } else {
    //         // otherwise, give me *next week's* instance of that same day
    //         return moment()
    //             .add(1, "weeks")
    //             .isoWeekday(isoWeekday);
    //     }
    // };
    generateQuarter = history => {
        //Start on the first Monday of the quarter
        const weekCounter = getNextWeekDay(moment().startOf("quarter"));
        const endDate = moment().endOf("quarter");
        const today = moment();
        const weeks = [];
        while (weekCounter.isSameOrBefore(endDate)) {
            //Summarise any data
            let correct = null;
            if (weekCounter.isSameOrBefore(today)) {
                correct = 0;
                const quizDataForWeek = find(history, [
                    "week_commencing",
                    weekCounter.format("YYYY-MM-DD")
                ]);
                if (quizDataForWeek) {
                    correct = quizDataForWeek.questions.reduce(
                        (runningTotal, question) => {
                            if (question.correct) {
                                runningTotal++;
                            }
                            return runningTotal;
                        },
                        0
                    );
                }
            }

            weeks.push({
                weekCommencing: weekCounter.format("YYYY-MM-DD"),
                correct: correct
            });
            weekCounter.add(1, "week");
        }
        return weeks;
    };
    render() {
        const { history } = this.props;
        const data = this.generateQuarter(history.data);
        // console.log(data);

        return (
            <React.Fragment>
                <PullRow>
                    <Column>
                        <SectionTitle
                            title="Quarterly Results"
                            subtitle="Track your scores"
                        />
                    </Column>
                </PullRow>
                <PullRow>
                    <Column>
                        <VictoryChart
                            domain={{ y: [0, 3] }}
                            domainPadding={15}
                            containerComponent={
                                <VictoryContainer responsive={true} />
                            }
                            height={250}
                        >
                            <VictoryAxis
                                label="Week Commencing"
                                style={{
                                    axisLabel: { padding: 30 },
                                    tickLabels: { padding: 5 }
                                }}
                                fixLabelOverlap={true}
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: colors.primary },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                x="weekCommencing"
                                y="correct"
                                data={data}
                            />
                            <VictoryScatter
                                style={{
                                    data: { fill: colors.primary },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                x="weekCommencing"
                                y="correct"
                                data={data}
                            />
                            <VictoryAxis
                                dependentAxis
                                label="Questions Correct"
                                style={{
                                    axisLabel: { padding: 30 },
                                    tickLabels: { padding: 5 }
                                }}
                                tickCount={3}
                                tickFormat={t => `${Math.round(t)}`}
                            />
                        </VictoryChart>
                    </Column>
                </PullRow>
            </React.Fragment>
        );
    }
}

export default QuizHistoryQuarter;
