import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const BodyRow = styled(Row)`
    background-color: #eaeaea;
    // box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.8);
`;
export const HeaderRow = styled(Row)`
    display: ${props => (props.alwaysShow ? "block" : "none")};
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        display: block;
    }
`;

export const RowWrapper = styled.div`
    margin-bottom: 0.5rem;
    overflow: hidden;
`;
export const HeaderColumn = styled(Column)`
    color: grey;
    height: 30px;
`;
export const BodyColumn = styled(Column)`
    height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
`;
export const BodyLabel = styled.span`
    display: ${props => (props.block ? "block" : "inline")};
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        display: none;
    }
    @media (max-width: ${props => props.theme.breakpoints.medium}px) {
        display: none;
    }
`;
