import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// import { StyledLink, Loading, Row } from "@cortexglobal/rla-components";
import AppContext from "./AppContext";

const UserDetailsWrapper = styled.div`
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 3rem;
    font-size: 0.7rem;
    line-height: 1;
    p {
        margin: 0;
    }
    h2 {
        margin: 0;
    }
`;

const ProfileName = styled.h2`
    font-size: 1.25rem;
    line-height: 1.5rem;
`;

const ProfileImg = styled.div`
    background: url(${(props) => props.avatar});
    background-size: cover;
    background-position: 80% 65%;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin: 1rem;
`;

const ProfileGroup = styled.p`
    font-size: 1rem;
    letter-spacing: normal;
    line-height: 1;
`;

class UserDetails extends React.Component {
    render() {
        const { showGroup } = this.props;
        return (
            <AppContext.Consumer>
                {({ user, group }) => {
                    //console.log(user);
                    return (
                        <UserDetailsWrapper>
                            <ProfileImg avatar={user.additional_props.avatar} />
                            <div>
                                <ProfileName>{`${user.first_name} ${user.last_name}`}</ProfileName>
                                {group && (
                                    <ProfileGroup>
                                        {group.role}{" "}
                                        {showGroup &&
                                            group.name &&
                                            `- ${group.name}`}
                                    </ProfileGroup>
                                )}
                            </div>
                        </UserDetailsWrapper>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

UserDetails.propTypes = {
    showGroup: PropTypes.bool,
};
UserDetails.defaultProps = {
    showGroup: true,
};
export default UserDetails;
