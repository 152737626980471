import {
    LOADING_COMPETITION_HISTORY,
    LOAD_COMPETITION_HISTORY,
    LOADING_CURRENT_COMPETITION,
    LOAD_CURRENT_COMPETITION
} from "./competitionActions";

export const initialState = {
    current: {
        loaded: false,
        loading: false,
        data: {}
    },
    history: {
        loaded: false,
        loading: false,
        data: []
    }
};
export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_CURRENT_COMPETITION:
            return {
                ...state,
                current: {
                    ...state.current,
                    loading: true
                }
            };
        case LOAD_CURRENT_COMPETITION:
            return {
                ...state,
                current: {
                    ...state.current,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        case LOADING_COMPETITION_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    loading: true
                }
            };
        case LOAD_COMPETITION_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        default:
            return state;
    }
};
