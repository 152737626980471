import React, { Component } from "react";
import { Row, Column, Modal } from "@cortexglobal/rla-components";
import LinkStyleButton from "../../components/LinkStyleButton";
import { colors } from "@cortexglobal/bmw-shared-components";
import { CompetitionEntry } from "../../components/competitionComponents";

class CompetitionEntrySummary extends Component {
    state = {
        showingModal: false
    };

    showModal = () => {
        this.setState({ showingModal: true });
    };
    hideModal = () => {
        this.setState({ showingModal: false });
    };
    render() {
        const { showingModal } = this.state;
        const {
            // start_date,
            // end_date,
            user_entries,
            winning_entries
        } = this.props.competition;
        const winner = winning_entries.reduce((winning, entry) => {
            return winning || entry.uuid === this.props.user.uuid;
        }, false);
        const hasWinners = winning_entries.length > 0;
        return (
            <React.Fragment>
                <CompetitionEntry winner={winner}>
                    <p>
                        Entries: {winner && <span>{user_entries.length}</span>}
                    </p>
                    <strong>{winner ? "Winner!" : user_entries.length}</strong>
                    {hasWinners && (
                        <LinkStyleButton
                            color={winner ? colors.black : colors.darkGray}
                            onClick={this.showModal}
                        >
                            See winners
                        </LinkStyleButton>
                    )}
                </CompetitionEntry>

                {hasWinners && showingModal && (
                    <Modal visible={showingModal} onClose={this.hideModal}>
                        <Row>
                            <Column small={6} medium={6} large={6}>
                                <h2>Winners</h2>
                            </Column>
                            <Column small={6} medium={6} large={6}>
				<h2>Prize Won</h2>
			    </Column>
                        </Row>
                        <Row>
                            {winning_entries.map(winner => (
				<React.Fragment key={winner.uuid}>
				    <Column small={6} medium={6} large={6}>
					<p>{winner.name}</p>
				    </Column>
				    <Column small={6} medium={6} large={6}>
					<p>{winner.prize}</p>
				    </Column>
				</React.Fragment>
                            ))}
                        </Row>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

export default CompetitionEntrySummary;
