import React, { Component } from "react";
import styled from "styled-components";
import ComparisonItem from "./ComparisonItem";
const ComparisonWrapper = styled.div`
    cursor: pointer;
`;

class ComparisonChoice extends Component {
    submitAnswer = () => {
        this.props.submitAnswer(this.props.item.uuid);
    };
    render() {
        const { item, selectedAnswerUuid } = this.props;
        return (
            <ComparisonWrapper onClick={this.submitAnswer}>
                <ComparisonItem
                    item={item}
                    selectedItemUuid={selectedAnswerUuid}
                    displayAnswer={false}
                />
            </ComparisonWrapper>
        );
    }
}

export default ComparisonChoice;
