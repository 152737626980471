import storage from "local-storage-fallback";
import axios from "axios";
import "nprogress/nprogress.css";
import NProgress from "nprogress";

// Set default base url.
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// Count all requests which were made...
let requestsCounter = 0;
NProgress.configure({ showSpinner: false });

// Add a request interceptor
axios.interceptors.request.use(request => {
    if (requestsCounter++ <= 0) {
        NProgress.start();
    }

    return request;
});

// Add a response interceptor
axios.interceptors.response.use(
    response => {
        if (--requestsCounter === 0) {
            NProgress.done();
        }

        return response;
    },
    error => {
        if (--requestsCounter === 0) {
            NProgress.done();
        }

        if (
            error.response &&
            error.response.status === 401 &&
            (!error.response.data ||
                error.response.data.error !== "invalid_credentials")
        ) {
            storage.clear();

            window.location.reload(true);
        }

        throw error;
    }
);
