import {
    LOADING_COMPARISON_GAME_LEAGUE,
    LOAD_COMPARISON_GAME_LEAGUE,
    LOADING_BEST_COMPARISON_GAME,
    LOAD_BEST_COMPARISON_GAME,
    LOADING_COMPARISON_GAME,
    START_COMPARISON_GAME,
    LOAD_COMPARISON_GAME,
    RESET_COMPARISON_GAME,
    SELECT_COMPARISON_GAME_ANSWER,
    SUBMIT_COMPARISON_GAME_ANSWER,
    DECREMENT_COMPARISON_ANSWER_COUNTDOWN_TIMER,
    DECREMENT_COMPARISON_QUESTION_COUNTDOWN_TIMER,
    RESET_COMPARISON_QUESTION
} from "./comparisonActions";

export const QUESTION_COUNTDOWN_START = 10;
export const ANSWER_COUNTDOWN_START = 4;

export const initialState = {
    current: {
        gameStarted: false,
        loaded: false,
        loading: false,
        started: false,
        completed: false,
        selectedAnswerUuid: "",
        previousComparison: {},
        currentComparison: {},
        total: 0,
        questionCountdownTimer: QUESTION_COUNTDOWN_START,
        answerCountdownTimer: ANSWER_COUNTDOWN_START
    },
    bestGame: {
        loaded: false,
        loading: false,
        uuid: null,
        total: 0,
        completed: null
    },
    league: {
        loaded: false,
        loading: false,
        data: []
    }
};
export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_COMPARISON_GAME_LEAGUE:
            return {
                ...state,
                league: {
                    ...state.league,
                    loading: true
                }
            };
        case LOAD_COMPARISON_GAME_LEAGUE:
            return {
                ...state,
                league: {
                    ...state.league,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };

        case LOADING_BEST_COMPARISON_GAME:
            return {
                ...state,
                bestGame: {
                    ...state.bestGame,
                    loading: true
                }
            };
        case LOAD_BEST_COMPARISON_GAME:
            return {
                ...state,
                bestGame: {
                    ...state.bestGame,
                    loaded: true,
                    loading: false,
                    uuid: action.uuid,
                    total: action.total,
                    completed: action.completed
                }
            };

        case LOADING_COMPARISON_GAME:
            return {
                ...state,
                current: {
                    ...state.current,
                    loading: true
                }
            };
        case START_COMPARISON_GAME:
            return {
                ...state,
                current: {
                    ...state.current,
                    gameStarted: true
                }
            };
        case LOAD_COMPARISON_GAME:
            return {
                ...state,
                current: {
                    ...state.current,
                    loaded: true,
                    loading: false,
                    uuid: action.uuid,
                    started: action.started,
                    total: action.total,
                    completed: action.completed,
                    selectedAnswerUuid: "",
                    previousComparison: action.previous,
                    currentComparison: action.current,
                    answerCountdownTimer: 0,
                    questionCountdownTimer: QUESTION_COUNTDOWN_START
                }
            };
        case RESET_COMPARISON_GAME:
            return {
                ...state,
                current: { ...initialState.current }
            };
        case SELECT_COMPARISON_GAME_ANSWER:
            return {
                ...state,
                current: {
                    ...state.current,
                    selectedAnswerUuid: action.selectedAnswerUuid
                }
            };
        case SUBMIT_COMPARISON_GAME_ANSWER:
            return {
                ...state,
                current: {
                    ...state.current,
                    loaded: true,
                    loading: false,
                    uuid: action.uuid,
                    started: action.started,
                    total: action.total,
                    completed: action.completed,
                    selectedAnswerUuid: action.selectedAnswerUuid,
                    previousComparison: action.previous,
                    currentComparison: action.current,
                    answerCountdownTimer: ANSWER_COUNTDOWN_START,
                    questionCountdownTimer: 0
                }
            };
        case DECREMENT_COMPARISON_ANSWER_COUNTDOWN_TIMER:
            return {
                ...state,
                current: {
                    ...state.current,
                    answerCountdownTimer: --state.current.answerCountdownTimer
                }
            };
        case DECREMENT_COMPARISON_QUESTION_COUNTDOWN_TIMER:
            return {
                ...state,
                current: {
                    ...state.current,
                    questionCountdownTimer: --state.current
                        .questionCountdownTimer
                }
            };
        case RESET_COMPARISON_QUESTION:
            return {
                ...state,
                current: {
                    ...state.current,
                    questionCountdownTimer: QUESTION_COUNTDOWN_START,
                    selectedAnswerUuid: ""
                }
            };
        default:
            return state;
    }
};
