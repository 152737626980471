import React from "react";
import styled from "styled-components";
import { withTheme } from "@cortexglobal/rla-components";

const QuestionTitle = styled.h1`
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin: 3.8rem 0 0.2rem 0;
`;

const SubHeading = styled.p`
    width: 100%;
    font-size: 0.8rem;
    line-height: 20px;
    color: ${props => props.theme.colors.darkGray};
    display: block;
    margin: 0 0 0.8rem 0;
`;

const ExitSurveyQuestion = props => {
    const { question, helpText, children } = props;

    return (
        <React.Fragment>
            <QuestionTitle>{question}</QuestionTitle>
            <SubHeading>{helpText}</SubHeading>
            {children}
        </React.Fragment>
    );
};

export default ExitSurveyQuestion;
