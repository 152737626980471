import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Row,
    Column,
    FormikCheckbox,
    FormikCheckboxArray,
    FormikRadioArray,
    FormikInput,
    FormikTextarea,
    Button,
    Loading,
    Panel,
    Modal
} from "@cortexglobal/rla-components";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { CenteredColumn, ErrorMessage } from "./exitSurveyComponents";
import ExitSurveyQuestion from "./ExitSurveyQuestion";
import ConsentModal from "./ConsentModal";

// caption really belongs with the form type...
const makeForm = (questionType, id, formOptions = []) => {
    const inputName = `${id}`;
    const options = formOptions || [];
    switch (questionType) {
        case "text":
            return <FormikTextarea label="" name={inputName} />;

        case "checkbox":
            return (
                <FormikCheckbox
                    name={inputName}
                    label={options[0] ? options[0].text : ""}
                />
            );

        case "choice":
            return (
                <FormikRadioArray
                    options={options}
                    label=""
                    name={inputName}
                    // inlineRadioButtons={true}
                />
            );

        case "rating":
            return (
                <FormikRadioArray
                    options={options}
                    label=""
                    name={inputName}
                    inlineRadioButtons={true}
                />
            );

        case "multiSelect":
            return (
                <FormikCheckboxArray
                    options={options}
                    label=""
                    name={inputName}
                    // inline={true}
                />
            );

        default:
            return <FormikTextarea label="" name={inputName} />;
    }
};

const makeValidation = questions => {
    const schema = questions.reduce((object, question, index) => {
        const { uuid, type, required } = question;

        const inputName = `${uuid}`;

        switch (type) {
            case "text":
                return {
                    ...object,
                    [inputName]: required
                        ? Yup.string().required("Required")
                        : Yup.string()
                };
            case "checkbox":
                return {
                    ...object,
                    [inputName]: required
                        ? Yup.string().required("Required")
                        : Yup.string()
                };

            case "rating":
                return {
                    ...object,
                    [inputName]: required
                        ? Yup.string().required("Required")
                        : Yup.string()
                };

            case "choice":
                return {
                    ...object,
                    [inputName]: required
                        ? Yup.string().required("Required")
                        : Yup.string()
                };

            case "multiSelect":
                return {
                    ...object,
                    [inputName]: required
                        ? Yup.array().required(
                              "Please select at least one option"
                          )
                        : Yup.array()
                };
        }

        return object;
    }, {});

    return schema;
};

const setInitialValues = (questions, email) => {
    const values = questions.reduce((object, question, index) => {
        const { uuid } = question;

        return {
            ...object,
            [`${uuid}`]: ""
        };
    }, {});
    return values;
};

const ExitSurveyQuestions = ({ surveyCode }) => {
    const [email, setEmail] = useState("");
    const [questions, setQuestions] = useState([]);
    const [questionLoaded, setQuestionsLoaded] = useState(false);
    const [surveyError, setSurveyError] = useState("");
    const [surveyCompleted, setSurveyCompleted] = useState(false);

    const [showConsentDialog, setShowConsentDialog] = useState(false);
    let validationSchema = makeValidation(questions);

    useEffect(() => {
        axios
            .get(`/api/v1/survey/exit-survey/${surveyCode}`)
            .then(({ data: { data } }) => {
                setQuestions(data.questions);
                setEmail(data.email);
                setQuestionsLoaded(true);
                validationSchema = makeValidation(data.questions);
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setSurveyError(e.response.data.message);
                }
            });
    }, []);

    // console.log("validationSchema", validationSchema);

    const submitSurvey = (values, { setErrors, setSubmitting }, event) => {
        // return false;
        axios
            .post("/api/v1/survey/exit-survey", {
                ...values,
                survey_code: surveyCode
            })
            .then(({ data: { data } }) => {
                setShowConsentDialog(true);
            })
            .catch(e =>
                parseValidationErrors(e).then(({ errors }) => {
                    // console.log(errors);
                    setErrors(errors);
                    setSubmitting(false);
                })
            );
    };

    const submitConsent = (values, { setErrors, setSubmitting }, event) => {
        axios
            .put(`/api/v1/survey/exit-survey/${surveyCode}`, {
                ...values
            })
            .then(({ data: { data } }) => {
                setShowConsentDialog(false);
                setSurveyCompleted(true);
            })
            .catch(e =>
                parseValidationErrors(e).then(({ errors }) => {
                    // console.log(errors);
                    setErrors(errors);
                    setSubmitting(false);
                })
            );
    };

    if (surveyError !== "") {
        return (
            <CenteredColumn centered={true}>
                <Panel heading="Sorry there's been an error">
                    <p style={{ padding: "1rem" }}>{surveyError}</p>
                </Panel>
            </CenteredColumn>
        );
    }

    if (surveyCompleted) {
        return (
            <CenteredColumn centered={true}>
                <Panel heading="Thanks for completing the survey">
                    <p style={{ padding: "1rem" }}>
                        If you consented to be contacted, you'll be sent an
                        email with your amazon code.
                    </p>
                </Panel>
            </CenteredColumn>
        );
    }

    if (!questionLoaded) {
        return <Loading />;
    }
    return (
        <React.Fragment>
            <Formik
                enableReinitialize={true}
                initialValues={setInitialValues(questions, email)}
                onSubmit={submitSurvey}
                validationSchema={Yup.lazy(() =>
                    Yup.object().shape(
                        validationSchema
                        // email: Yup.string()
                        // .email()
                        // .required("Required")
                    )
                )}
            >
                {props => {
                    const {
                        values,
                        errors,
                        isSubmitting,
                        handleSubmit,
                        submitCount,
                        ...rest
                    } = props;
                    const errorCount = Object.keys(errors).length;
                    // console.log(rest);
                    return (
                        <Form
                            className="form-container"
                            onSubmit={handleSubmit}
                        >
                            {questions.map((item, index) => {
                                const {
                                    caption: helpText,
                                    question,
                                    type: questionType,
                                    options: formOptions,
                                    uuid
                                } = item;

                                return (
                                    <CenteredColumn key={uuid} centered={true}>
                                        <ExitSurveyQuestion
                                            question={question}
                                            helpText={helpText}
                                        >
                                            {makeForm(
                                                questionType,
                                                uuid,
                                                formOptions
                                            )}
                                        </ExitSurveyQuestion>
                                    </CenteredColumn>
                                );
                            })}
                            <CenteredColumn
                                centered={true}
                                style={{ marginTop: "2rem" }}
                            >
                                <Button
                                    height={45}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Button>{" "}
                                {errorCount > 0 && submitCount > 0 && (
                                    <ErrorMessage>
                                        Please check your form for errors
                                    </ErrorMessage>
                                )}
                            </CenteredColumn>
                        </Form>
                    );
                }}
            </Formik>
            <ConsentModal
                submitConsent={submitConsent}
                showConsentDialog={showConsentDialog}
                initialValues={{
                    email: email,
                    consent_given: null
                }}
            />
        </React.Fragment>
    );
};

export default ExitSurveyQuestions;
