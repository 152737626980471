import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { LogoutWrapper } from "@cortexglobal/cortex-auth-react-router-dom";
import { Button } from "@cortexglobal/rla-components";
import CanAccessLeague from "../routing/CanAccessLeague";

import Menu from "./BurgerMenu";

import MainNavLink from "./MainNavLink";

const LogoutButton = styled(Button)`
    width: 66%;
    margin: 0 1rem 0 1rem;
`;
const loginUrl = "/auth/login";
class PrivateNav extends React.Component {
    state = {
        menuOpen: false,
    };

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange = (state) => {
        this.setState({ menuOpen: state.isOpen });
    };

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu = () => {
        this.setState({ menuOpen: false });
    };
    disabledClick = (e) => {
        e.preventDefault();
    };
    render() {
        const {
            spacing,
            mobileView,
            userCanViewRewards,
            userCanViewCareerPathway,
        } = this.props;

        return (
            <Menu
                spacing={spacing}
                mobileView={mobileView}
                isOpen={this.state.menuOpen}
                onStateChange={this.handleStateChange}
            >
                <MainNavLink to="/" onClick={this.closeMenu}>
                    Home
                </MainNavLink>
                {userCanViewRewards && (
                    <MainNavLink to="/redeem" onClick={this.closeMenu}>
                        Redeem
                    </MainNavLink>
                )}
                <MainNavLink to="/profile" onClick={this.closeMenu}>
                    Profile
                </MainNavLink>
                {userCanViewCareerPathway && (
                    <MainNavLink to="/career-pathway" onClick={this.closeMenu}>
                        Career Pathway
                    </MainNavLink>
                )}
                <MainNavLink to="/games" onClick={this.closeMenu}>
                    Games
                </MainNavLink>
                <MainNavLink to="/competitions" onClick={this.closeMenu}>
                    Competitions
                </MainNavLink>
                <CanAccessLeague>
                    <MainNavLink
                        to="/champions-league"
                        onClick={this.closeMenu}
                    >
                        Champions League
                    </MainNavLink>
                </CanAccessLeague>
                <MainNavLink to="/contact" onClick={this.closeMenu}>
                    Contact us
                </MainNavLink>
                <MainNavLink to="/notifications" onClick={this.closeMenu}>
                    Notifications
                </MainNavLink>
                <LogoutWrapper loginUrl={loginUrl}>
                    {({ logout }) => (
                        <LogoutButton
                            href={loginUrl}
                            onClick={logout}
                            height={45}
                            expanded
                            style={{
                                fontSize: "1em",
                                fontWeight: "800",
                                textTransform: "none",
                                marginTop: "20px",
                            }}
                        >
                            <FontAwesomeIcon
                                icon="sign-out-alt"
                                transform="grow-3"
                            />{" "}
                            Logout
                        </LogoutButton>
                    )}
                </LogoutWrapper>
            </Menu>
        );
    }
}

export default PrivateNav;
