import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import moment from "moment";

import { Loading } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import {
    Row,
    Column,
    Button,
    Carousel,
    CarouselItem
} from "@cortexglobal/rla-components";

import AppContext from "../../components/global/AppContext";
import UserSummary from "../../components/global/UserSummary";
import { TrackableClick } from "../../components/routing/TrackableClick";
import ChampionsLeagueLogo from "../../img/champions_league_logo.png";
import { loadChampionsLeague } from "./championsActions";
import {
    ALink,
    LeagueLink,
    ChampionStats,
    MobileChampionStats,
    ChampionPosition,
    MobileStatistic,
    StatisticBlock,
    Statistic,
    MobileStatisticBlock,
    LeagueRow,
    LeagueTable,
    ChampionsLeagueTable,
    Phase,
    PhaseName,
    MobilePhaseName,
    BackgroundImage,
    VideoBackground,
    SmallText,
    CardLink,
    ChampionsLeague,
    MobileButton
} from "./championsComponents";

const MobileLink = styled.div`
    @media only screen and (max-width: 700px) {
        display: none;
        margin-bottom: 1rem;
    }
`;

const DesktopLink = styled.div`
    @media only screen and (min-width: 700px) {
        display: none;
        margin-bottom: 1rem;
    }
`;

class ChampionsOverview extends Component {
    constructor(props) {
        super(props);
        this.changePhase = this.changePhase.bind(this);
    }

    state = {
        shownPhase: 0,
        currentPhase: null
    };

    componentDidMount() {
        const {
            user: {
                group: { uuid: groupUuid }
            }
        } = this.props;

        if (!this.props.loaded) {
            this.props.loadChampionsLeague(groupUuid);
        }

        this.setInitialPhase();
    }

    setInitialPhase() {
        // Champions League Phase Dates
        const phases = [
            { from: "2019-11-11", to: "2019-11-14" },
            { from: "2019-11-18", to: "2019-11-22" },
            { from: "2019-11-25", to: "2019-11-29" },
            { from: "2019-12-02", to: "2019-12-06" },
            { from: "2019-11-09", to: "2019-11-13" }
        ];
        const count = phases.length;
        const today = moment();

        for (let i = 0; i < count; i++) {
            if (today.isBetween(phases[i].from, phases[i].to, null, "[]")) {
                this.setState({ shownPhase: i, currentPhase: i });
                break;
            }
        }
    }

    changePhase(e) {
		 let phase;

       if (e.target.nodeName == 'DIV') {
			 phase = parseInt(e.target.parentElement.getAttribute("data-phase"));
		 }
		 else {
			 phase = parseInt(e.target.getAttribute("data-phase"));
		 }

       this.setState({ shownPhase: phase });
    }

    render() {
        if (this.props.loading) {
            return <Loading align="center" />;
        }

        const { champions } = this.props;

        return (
            <div className="page" style={{ overflow: "hidden" }}>
                <AppContext.Consumer>
                    {({ user }) => {
                        const championEntry = champions.data.reduce(
                            (found, item, index) => {
                                if (item.user_uuid === user.uuid) {
                                    return (found = item);
                                } else {
                                    return found;
                                }
                            },
                            {}
                        );

                        const leaguePhases = [
                            {
                                phase: 1,
                                phaseName: "Preliminary Phase",
                                date: "November 11th - 14th",
                                title: "Get Practicing:",
                                subtitle: "",
                                content:
                                    "Use this week to practise answering 5 non-technical questions each day and get used to how things work. Your scores in this Phase will not count towards your final online challenge score and will be reset back to zero."
                            },
                            {
                                phase: 2,
                                phaseName: "Phase 1",
                                date: "November 18th - 22nd",
                                title: "Scoring starts:",
                                subtitle: "",
                                content:
                                    "The online challenge begins! Each day you will be asked to answer 10 technical questions in as few attempts as possible to maximise your points."
                            },
                            {
                                phase: 3,
                                phaseName: "Phase 2",
                                date: "November 25th - 29th",
                                title: "The Challenge Phase:",
                                subtitle: "",
                                content:
                                    "As well as receiving 10 daily technical questions, you can score extra points by challenging other players. You will each be served the same 5 questions and whoever answers quickest wins. (Max of 2 challenges to send and 2 to be received per day – Monday to Thursday.) "
                            },
                            {
                                phase: 4,
                                phaseName: "Break Week",
                                date: "December 2nd - 6th",
                                title: "Time to catch-up:",
                                subtitle: "",
                                content:
                                    "This week you will not receive any new daily questions, so use this time to catch up on any unanswered questions from week 2 and 3."
                            },
                            {
                                phase: 5,
                                phaseName: "Phase 3",
                                date: "December 9th - 13th",
                                title: "The Super Challenge Phase:",
                                subtitle: "",
                                content:
                                    "As well as receiving 10 daily technical questions, you can issue a Super Challenge to other players for the chance to win TRIPLE points. Don’t forget, other players may also challenge you! (Max of 1 Super Challenge to send and 1 to be received per day – Monday to Thursday.)"
                            }
                        ];

                        return (
                            <React.Fragment>
                                <Row
                                    style={{
                                        padding: "0",
                                        textAlign: "center",
                                        width: "100%"
                                    }}
                                >
                                    <BackgroundImage style={{ width: "100%" }}>
                                        <img
                                            src={ChampionsLeagueLogo}
                                            alt="Technician Champions League"
                                        />
                                    </BackgroundImage>
                                </Row>
                                <Row style={{ padding: "0" }}>
                                    <Column style={{ padding: "0" }}>
                                        <MobileChampionStats
                                            championEntry={championEntry}
                                        />
                                    </Column>
                                </Row>

                                <Row>
                                    <Column medium={12} large={6} xlarge={4}>
                                        <PageTitle
                                            title="Welcome to the Champions League"
                                            subtitle="A new, exciting online competition."
                                        />
                                    </Column>
                                    <Column
                                        medium={12}
                                        large={6}
                                        xlarge={8}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            position: "relative"
                                        }}
                                    >
                                        <LeagueLink href="#championsLeagueTable">
                                            View the league
                                        </LeagueLink>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column medium={12} large={3} xlarge={4}>
                                        <p>
                                        Technicians can perfect their product knowledge and challenge other players to head-to-head contests during the online challenge. The top 24 scoring Technicians will be invited to the next stage of the competition at the BMW Group UK Academy to take part in a practical assessment to establish which 3 Technicians will represent the UK and Ireland in the finals against the best BMW Group Technicians in Europe, taking place in Munich 2020.
                                        </p>
                                        <MobileLink>
                                            The online challenge is divided into
                                            different phases. Scroll down or
                                            <a href="#phases"> click here</a> to
                                            view more details on each phase.
                                        </MobileLink>
                                    </Column>
                                    <Column medium={12} large={3} xlarge={4}>
                                        <p>
                                            Please download and read through the
                                            game instructions to ensure you
                                            fully understand the scoring system
                                            and game play. Initially, you’ll
                                            have the chance to familiarise
                                            yourself with how the questions and
                                            answers work before the real
                                            challenge and scoring begins.
                                        </p>
                                        <DesktopLink>
                                            The online challenge is divided into
                                            different phases. Scroll down or
                                            <a href="#phases"> click here</a> to
                                            view more details on each phase.
                                        </DesktopLink>
                                    </Column>
                                    <Column medium={12} large={6} xlarge={4}>
                                        <Row
                                            style={{
                                                padding: "0"
                                            }}
                                        >
                                            <Column>
                                                <ChampionPosition
                                                    title="Your national position:"
                                                    stat={
                                                        championEntry.national_position
                                                    }
                                                />
                                            </Column>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: "1em",
                                                padding: "0"
                                            }}
                                        >
                                            <Column>
                                                <ChampionPosition
                                                    title="Your retailer position:"
                                                    stat={
                                                        championEntry.position
                                                    }
                                                />
                                            </Column>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: "1em",
                                                padding: "0"
                                            }}
                                        >
                                            <Column>
                                                <ChampionStats
                                                    title="Your points total:"
                                                    stat={championEntry.score}
                                                />
                                            </Column>
                                        </Row>
                                    </Column>
                                </Row>

                                <Row>
                                    <Column
                                        small={12}
                                        medium={6}
                                        large={3}
                                        xlarge={2}
                                    >
                                        <TrackableClick
                                            category="click"
                                            action="visitLeague"
                                            label="Click through to Champions League"
                                        >
                                            <MobileButton
                                                element={ALink}
                                                href={`http://bmw-tech-championsleague.com/?auth=${user.league_token}`}
                                                target="_blank"
                                                style={{
                                                    height: "50px",
                                                    lineHeight: "48px",
                                                    width: "100%"
                                                }}
                                            >
                                                Start Challenge now
                                            </MobileButton>
                                        </TrackableClick>
                                    </Column>
                                    <Column
                                        small={12}
                                        medium={6}
                                        large={3}
                                        xlarge={2}
                                    >
                                        <MobileButton
                                            target="_blank"
                                            element={ALink}
                                            href={
                                                "https://bmw-video-wall-dev.s3.eu-west-2.amazonaws.com/TCL+Game+Instructions+FINAL+(1).pdf"
                                            }
                                            style={{
                                                height: "50px",
                                                lineHeight: "48px",
                                                width: "100%"
                                            }}
                                        >
                                            Download Instructions
                                        </MobileButton>
                                    </Column>
                                </Row>
                                <VideoBackground>
                                    <video
                                        className="video-container video-container-overlay"
                                        autoplay=""
                                        controls="true"
                                        loop=""
                                        muted=""
                                        data-reactid=".0.1.0.0"
                                    >
                                        <source
                                            type="video/mp4"
                                            data-reactid=".0.1.0.0.0"
                                            src="https://bmw-video-wall-dev.s3.eu-west-2.amazonaws.com/Teaser_Movie_Technician_Champions_League.mp4"
                                        />
                                    </video>
                                </VideoBackground>
                                <div id="phases">&nbsp;</div>
                                <Row>
                                    <Column medium={12} large={12} xlarge={12}>
                                        <PageTitle
                                            title="The online challenge is divided into 5 different phases"
                                            subtitle="Keep up to date to give yourself the best chance."
                                        />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column large={2}>
                                        <ul
                                            style={{
                                                marginTop: "0",
                                                listStyle: "none",
                                                paddingInlineStart: "0px"
                                            }}
                                        >
                                            {leaguePhases.map(
                                                (phase, index) => {
                                                    const activeStyle = {
                                                        marginTop: "0",
                                                        height: "55px",
                                                        lineHeight: "48px",
                                                        width: "100%"
                                                    };

                                                    const style = {
                                                        height: "55px",
                                                        lineHeight: "48px",
                                                        backgroundColor:
                                                            "#e1e1e1",
                                                        boxShadow:
                                                            "inset 0 0 0 1px #e1e1e1",
                                                        width: "100%"
                                                    };

                                                    return (
                                                        <Phase key={index}>
                                                            <Button
                                                                style={
                                                                    phase.phase -
                                                                        1 ===
                                                                    this.state
                                                                        .shownPhase
                                                                        ? activeStyle
                                                                        : style
                                                                }
                                                                data-phase={
                                                                    phase.phase -
                                                                    1
                                                                }
                                                                onClick={
                                                                    this
                                                                        .changePhase
                                                                }
                                                            >
                                                                <PhaseName>
                                                                    {
                                                                        phase.phaseName
                                                                    }
                                                                </PhaseName>
                                                                <MobilePhaseName>
                                                                    {
                                                                        phase.phase
                                                                    }
                                                                </MobilePhaseName>
                                                            </Button>
                                                        </Phase>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </Column>
                                    <Column large={6}>
                                        <Carousel
                                            showItem={this.state.shownPhase}
                                            arrows={false}
                                            dots={false}
                                            layout={{ w: 1, h: 1 }}
                                            height="354px"
                                            style={{ width: "100%" }}
                                        >
                                            {leaguePhases.map(
                                                (phase, index) => (
                                                    <CarouselItem
                                                        key={index}
                                                        style={{
                                                            backgroundColor:
                                                                "#f5f5f5",
                                                            padding: "2em"
                                                        }}
                                                    >
                                                        <h4
                                                            style={{
                                                                display:
                                                                    "inline-block"
                                                            }}
                                                        >
                                                            {phase.date}
                                                        </h4>
                                                        <span
                                                            style={{
                                                                float: "right",
                                                                color: "#888"
                                                            }}
                                                        >
                                                            {this.state
                                                                .currentPhase +
                                                                1 ===
                                                            phase.phase
                                                                ? "(Current Phase)"
                                                                : null}
                                                        </span>
                                                        <hr />
                                                        <PageTitle
                                                            title={phase.title}
                                                            subtitle={
                                                                phase.subtitle
                                                            }
                                                        />
                                                        <p>{phase.content}</p>
                                                        <Button
                                                            element={ALink}
                                                            href={`http://bmw-tech-championsleague.com/?auth=${user.league_token}`}
                                                            style={{
                                                                height: "50px",
                                                                lineHeight:
                                                                    "50px"
                                                            }}
                                                        >
                                                            Start challenge Now
                                                        </Button>
                                                    </CarouselItem>
                                                )
                                            )}
                                        </Carousel>
                                    </Column>
                                </Row>
                                <Row
                                    id="championsLeagueTable"
                                    name="championsLeagueTable"
                                    style={{ paddingTop: "3em" }}
                                >
                                    <hr style={{ margin: "2em 0" }} />
                                </Row>
                                <Row>
                                    <Column medium={12} large={12} xlarge={12}>
                                        <PageTitle
                                            title="The Champions League"
                                            subtitle="Check out where you are in the league."
                                        />
                                    </Column>
                                </Row>
                                <Row style={{ padding: "0" }}>
                                    <Column style={{ padding: "0" }}>
                                        <MobileStatisticBlock
                                            style={{ marginBottom: "2em" }}
                                        >
                                            <span>Your national position:</span>
                                        </MobileStatisticBlock>
                                        <MobileStatistic>
                                            <span>
                                                {
                                                    championEntry.national_position
                                                }
                                            </span>
                                        </MobileStatistic>
                                    </Column>
                                </Row>
                                <Row style={{ padding: "0" }}>
                                    <Column style={{ padding: "0" }}>
                                        <MobileStatisticBlock
                                            style={{ marginBottom: "1em" }}
                                        >
                                            <span>Your retailer position:</span>
                                        </MobileStatisticBlock>
                                        <MobileStatistic>
                                            <span>
                                                {championEntry.position}
                                            </span>
                                        </MobileStatistic>
                                    </Column>
                                </Row>

                                <Row style={{ marginBottom: "10em" }}>
                                    <Column medium={12} large={6} xlarge={8}>
                                        <ChampionsLeagueTable
                                            champions={champions}
                                            user={user}
                                        />
                                    </Column>
                                    <Column medium={12} large={6} xlarge={4}>
                                        <Row
                                            style={{
                                                marginTop: "39px",
                                                padding: "0"
                                            }}
                                        >
                                            <Column>
                                                <ChampionPosition
                                                    title="Your national position"
                                                    stat={
                                                        championEntry.national_position
                                                    }
                                                />
                                            </Column>
                                        </Row>
                                        <Row
                                            style={{
                                                padding: "0",
                                                marginTop: "1em"
                                            }}
                                        >
                                            <Column>
                                                <ChampionPosition
                                                    title="Your retailer position:"
                                                    stat={
                                                        championEntry.position
                                                    }
                                                />
                                            </Column>
                                        </Row>
                                        <Row
                                            style={{
                                                marginTop: "1em",
                                                padding: "0"
                                            }}
                                        >
                                            <Column>
                                                <ChampionStats
                                                    title="Your points total:"
                                                    stat={championEntry.score}
                                                />
                                            </Column>
                                        </Row>
                                    </Column>
                                </Row>
                            </React.Fragment>
                        );
                    }}
                </AppContext.Consumer>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        allState: state,
        auth: state.auth,
        user: state.auth.user,
        champions: state.champions,
        loaded: state.champions.loaded,
        loading: state.champions.loading
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadChampionsLeague
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChampionsOverview);
