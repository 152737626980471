import axios from "axios";

export const GET_ROLES = "GET_ROLES";

// export function getTechnicianRoles() {
//     return (dispatch) => {
//         axios
//             .get("/api/v1/roles/technician?requirements&training")
//             .then(({ data: { data } }) => {
//                 dispatch({
//                     type: GET_ROLES,
//                     data,
//                 });
//             })
//             .catch((e) => e);
//     };
// }

export function getStaffRoles(
    roleTypes = "technician,apprentice,service-advisor,sales-executive"
) {
    return (dispatch) => {
        axios
            .get(`/api/v1/roles/${roleTypes}?requirements&training`)
            .then(({ data: { data } }) => {
                dispatch({
                    type: GET_ROLES,
                    data,
                });
            })
            .catch((e) => e);
    };
}
