import React from 'react';
import AppContext from "../global/AppContext";

const CanAccessLeague = props => {
    return (
	<AppContext.Consumer>
	    {({ user }) => {
		if (
		    typeof user.league_token == 'string'
		    && user.league_token.length > 0
		) {
		    return (
			<React.Fragment>
			    {props.children}
			</React.Fragment>
		    )
		} else {
		    return null
		}
	    }}
	</AppContext.Consumer>
    );
};

export default CanAccessLeague;
