// Libraries
import React from "react";

// Cortex
import { Column, Row } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

// Components
import { BackgroundImage, CenteredColumn } from "./exitSurveyComponents";
import ExitSurveyQuestions from "./ExitSurveyQuestions";

const ExitSurvey = ({ surveyCode }) => {
    return (
        <React.Fragment>
            <Row expanded style={{ padding: 0 }}>
                <BackgroundImage />
            </Row>
            <Row expanded>
                <CenteredColumn
                    centered={true}
                    style={{ marginTop: "2.5rem", marginBottom: "1.8rem" }}
                >
                    <PageTitle title="Exit Survey" />
                </CenteredColumn>
            </Row>
            <Row expanded>
                <CenteredColumn centered={true}>
                    <p>
                        We are sorry to be parting ways, but your service to the
                        BMW Group has been greatly appreciated and we wish you
                        the best of luck in all your future endeavours.
                    </p>
                    <p>
                        The following exit survey will take 5 minutes to
                        complete. We appreciate you taking the time to answer
                        the following questions as honestly as possible. Your
                        responses are important and will help us to make
                        positive changes within the organisation.
                    </p>
                    <p>
                        Please note that all responses will be viewable by the
                        BMW Elite team and sent directly to BMW Head Office in
                        report format.
                    </p>
                    <hr
                        style={{
                            margin: "3rem 0",
                            border: 0,
                            height: "1px",
                            width: "100%",
                            backgroundColor: "#999"
                        }}
                    />
                </CenteredColumn>
            </Row>
            <Row expanded>
                <ExitSurveyQuestions surveyCode={surveyCode} />{" "}
            </Row>
        </React.Fragment>
    );
};

export default ExitSurvey;
