import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Row, Column, Loading } from "@cortexglobal/rla-components";

import RichText from "../../components/richText";
import { getStaffRoles } from "../../components/roles/roleActions";

import {
    PageTitle,
    SectionTitle,
    colors,
} from "@cortexglobal/bmw-shared-components";

const SubTitle = styled.p`
    color: #9f9f9f;
    margin: 0;
    font-size: 1.2em;
`;

const TrainingTitle = styled.h5`
    position: absolute;
    bottom: 2em;
    margin: 0 14% 0 5%;
    color: white;
    font-weight: bold;
    font-size: 1em;
    text-shadow: 2px 2px 4px #000000;
`;

const TrainingImg = styled.img`
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
`;

const ExternalLink = styled.a`
    font-weight: bold;
    color: black;
`;

class CareerPathwayDetail extends Component {
    componentWillMount() {
        if (!this.props.roles.loaded) {
            this.props.getStaffRoles("technician,apprentice");
        }
    }
    render() {
        const {
            roles,
            match: {
                params: { roleAlias },
            },
        } = this.props;
        if (!roles.loaded) {
            return <Loading align="center" />;
        }
        const role = roles.data.find((role) => role.alias === roleAlias);

        if (typeof role === "undefined") {
            return (
                <div
                    style={{
                        paddingBottom: "100px",
                        width: "1000px",
                    }}
                >
                    <Row>
                        <Column>
                            <PageTitle title="Role not found" subtitle="" />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <p>The role {roleAlias} could no be found</p>
                        </Column>
                    </Row>
                </div>
            );
        }
        return (
            <div
                style={{
                    paddingBottom: "100px",
                    width: "1000px",
                }}
            >
                <Row>
                    <Column>
                        <PageTitle title={role.name} subtitle="" />
                    </Column>
                </Row>
                <Row style={{ maxWidth: "1000px" }}>
                    <Column large={6}>
                        <SectionTitle title="Requirements" />
                        <SubTitle>
                            <p>
                                What do you need to be considered for this role?
                            </p>
                        </SubTitle>

                        {role.requirements.map((requirement) => {
                            return (
                                <React.Fragment key={requirement.id}>
                                    <div>{requirement.name}</div>
                                    <hr />
                                </React.Fragment>
                            );
                        })}
                    </Column>

                    <Column large={6}>
                        <SectionTitle title="Overview" />
                        <SubTitle>
                            <p>What does the job entail?</p>
                        </SubTitle>
                        <div>
                            <RichText content={role.description} />
                        </div>
                    </Column>
                </Row>
                <Row style={{ marginTop: "2em" }}>
                    <Column>
                        <SectionTitle title="Recommended Training" />
                        <SubTitle>
                            <p style={{ marginBottom: "0px" }}>
                                What training is needed before you start?
                            </p>
                            <p>
                                (Please note: S-gate is only accessible within
                                the BMW network)
                            </p>
                        </SubTitle>
                    </Column>
                </Row>
                <Row style={{ maxWidth: "1000px" }}>
                    {role.training.map((training) => {
                        return (
                            <Column
                                key={training.id}
                                small={6}
                                medium={6}
                                large={3}
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div>
                                    <ExternalLink
                                        href={`${training.link}`}
                                        title={training.name}
                                    >
                                        <TrainingImg src={training.thumbnail} />
                                        <TrainingTitle>
                                            {training.name}
                                        </TrainingTitle>
                                    </ExternalLink>
                                </div>
                            </Column>
                        );
                    })}
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roles: state.roles,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getStaffRoles,
        },
        dispatch
    );

// const mergeProps = (stateProps, dispatchProps, ownProps) => {
//     const roles = stateProps.roles.data;
//     const role_alias = ownProps.match.path.replace("/career-pathway/", "");
//     let role_aliases = [];
//     let role = {};

//     if (roles != null) {
//         role_aliases = roles.map(x => x.alias);
//         const role_index = role_aliases.indexOf(role_alias);
//         role = roles[role_index];
//     }

//     return {
//         ...stateProps,
//         ...dispatchProps,
//         ...ownProps,
//         role
//     };
// };

export default connect(
    mapStateToProps,
    mapDispatchToProps
    // mergeProps
)(CareerPathwayDetail);
