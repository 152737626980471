import {
    LOADING_COUNTDOWN,
    LOAD_COUNTDOWN,
    LOAD_COUNTDOWN_ERROR
} from "./countDownActions";

export const initialState = {
    loaded: false,
    loading: false,
    data: {},
    error: false,
    errorMessage: ""
};

export default function countdown(state = initialState, action) {
    switch (action.type) {
        case LOAD_COUNTDOWN:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                errorMessage: "",
                data: action.data
            };

        case LOADING_COUNTDOWN:
            return {
                ...state,
                loading: action.loading,
                error: false,
                errorMessage: ""
            };

        case LOAD_COUNTDOWN_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: true,
                errorMessage: action.error
            };

        default:
            return state;
    }
}
