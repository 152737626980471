import {
    LOADING_WEEKLY_QUIZ,
    LOAD_WEEKLY_QUIZ,
    DECREMENT_ANSWER_COUNTDOWN_TIMER,
    DECREMENT_QUESTION_COUNTDOWN_TIMER,
    LOAD_NEXT_QUESTION,
    SELECT_QUIZ_ANSWER,
    DISPLAY_QUIZ_ANSWER,
    START_QUIZ,
    LOADING_QUIZ_HISTORY,
    LOAD_QUIZ_HISTORY
} from "./quizActions";

export const initialState = {
    current: {
        loaded: false,
        loading: false,
        started: false,
        completed: false,
        questionUuid: "",
        correctAnswerUuid: "",
        selectedAnswerUuid: "",
        answered: null,
        uuid: "",
        questions: [],
        questionCountdownTimer: 30,
        answerCountdownTimer: 5
    },
    history: {
        loaded: false,
        loading: false,
        data: []
    }
};
export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_WEEKLY_QUIZ:
            return {
                ...state,
                current: {
                    ...state.current,
                    loading: true
                }
            };
        case LOAD_WEEKLY_QUIZ:
            return {
                ...state,
                current: {
                    ...state.current,
                    loaded: true,
                    loading: false,
                    started: action.started,
                    completed: action.completed,
                    questionUuid: action.current_question_uuid,
                    uuid: action.uuid,
                    questions: action.questions,
                    answerCountdownTimer: 0
                }
            };
        case SELECT_QUIZ_ANSWER:
            return {
                ...state,
                current: {
                    ...state.current,
                    answered: action.answered,
                    questions: state.current.questions.map(question => {
                        if (
                            question.question.uuid ===
                            state.current.questionUuid
                        ) {
                            question.answer = action.selectedAnswerUuid;
                        }
                        return question;
                    }),
                    questionCountdownTimer:
                        initialState.current.questionCountdownTimer,
                    selectedAnswerUuid: action.selectedAnswerUuid
                }
            };
        case DISPLAY_QUIZ_ANSWER:
            return {
                ...state,
                current: {
                    ...state.current,
                    questions: action.questions,
                    answerCountdownTimer:
                        initialState.current.answerCountdownTimer
                }
            };
        case DECREMENT_ANSWER_COUNTDOWN_TIMER:
            return {
                ...state,
                current: {
                    ...state.current,
                    answerCountdownTimer: --state.current.answerCountdownTimer
                }
            };
        case DECREMENT_QUESTION_COUNTDOWN_TIMER:
            return {
                ...state,
                current: {
                    ...state.current,
                    questionCountdownTimer: --state.current
                        .questionCountdownTimer
                }
            };
        case LOAD_NEXT_QUESTION:
            return {
                ...state,
                current: {
                    ...state.current,
                    started: action.started,
                    completed: action.completed,
                    questionUuid: action.current_question_uuid,
                    correctAnswerUuid: "",
                    selectedAnswerUuid: "",
                    answered: null,
                    answerCountdownTimer: 0,
                    questionCountdownTimer:
                        initialState.current.questionCountdownTimer
                }
            };
        case START_QUIZ:
            return {
                ...state,
                current: {
                    ...state.current,
                    started: action.started,
                    questionUuid: action.current_question_uuid,
                    answerCountdownTimer: 0,
                    questionCountdownTimer:
                        initialState.current.questionCountdownTimer
                }
            };
        case LOADING_QUIZ_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    loading: true
                }
            };
        case LOAD_QUIZ_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        default:
            return state;
    }
};
