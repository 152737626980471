import React, { Component } from "react";
import ReactGA from "react-ga";

import { TrackingEvent } from "./TrackingEvent";



export const TrackableClick = props => (
    <div
	onClick={()=> {TrackingEvent(
	    props.category,
	    props.action,
	    props.label
	)}}
    >
	{props.children}
    </div>
);
