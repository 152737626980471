import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loading } from "@cortexglobal/rla-components";
import { Column, Row } from "@cortexglobal/rla-components";
import { SectionTitle } from "@cortexglobal/bmw-shared-components";
import { loadBadgeSummary } from "../badges/badgeActions";
import {
    Card,
    CardLabel,
    CardActionWrapper,
    CardInfoButton,
    CardLinkButton,
    CardLink,
} from "../../components/infoCards/cardComponents";

import styled from "styled-components";
import { Badge } from "@cortexglobal/cortex-badges";
const DesktopCompact = styled(Row)`
    @media (min-width: ${(props) => props.theme.breakpoints.xlarge}px) {
        position: absolute;
        width: 100%;
        right: 0;
        top: 0;
        min-height: 200px;
        /* margin-top: 20px; */
        > div {
            display: flex;
            justify-content: flex-end;
        }

        .xlarge-hide {
            display: none;
        }
    }
`;
const BadgeColumn = styled.div`
    text-align: center;
    padding: 0 5%;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    &:nth-of-type(2) {
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 1px;
            background: #dedede;
            height: 100%;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
`;

const BadgeDetail = styled.h4`
    font-size: 12px;
    color: #000;
    text-align: center;
    line-height: 1;
    margin: 0;
`;

class MyBadgesInfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalBadges: 0,
        };
    }

    componentDidMount() {
        if (!this.props.badgeSummary.loaded) {
            this.props.loadBadgeSummary();
        } else {
            let total = 0;
            this.props.badgeSummary.data.map((badge) => {
                total += badge.awarded;
            });
            this.setState({ totalBadges: total });
        }
    }

    render() {
        const { badgeSummary } = this.props;
        if (!badgeSummary.loaded) {
            return null;
        }
        return (
            <DesktopCompact>
                <Column xlarge={4}>
                    <CardLabel>
                        <SectionTitle
                            title="My badges"
                            subtitle="Can you complete all the challenges?"
                        />
                    </CardLabel>
                    <Card className="slit-in-vertical flex solid">
                        {badgeSummary.data.map((badge) => {
                            return (
                                <BadgeColumn key={badge.name}>
                                    <Badge
                                        badge={badge}
                                        forceFullOpacity={true}
                                        style={{
                                            width: "100%",
                                            maxWidth: "65px",
                                            marginBottom: "8px",
                                        }}
                                    />
                                    <BadgeDetail>
                                        {badge.name} - {badge.awarded}
                                    </BadgeDetail>
                                </BadgeColumn>
                            );
                        })}
                        <CardLink to="/profile/badges">
                            <p className="xlarge-hide">My Badges</p>
                        </CardLink>
                    </Card>
                    <CardActionWrapper className="xlarge-hide">
                        <CardLinkButton to="/profile/badges">
                            View all
                        </CardLinkButton>
                        <CardInfoButton className="white">
                            <span>
                                Total badges:{" "}
                                <strong>{this.state.totalBadges}</strong>
                            </span>
                        </CardInfoButton>
                    </CardActionWrapper>
                </Column>
            </DesktopCompact>
        );
    }
}

const mapStateToProps = (state) => {
    const { badgeSummary } = state.badges;

    return { badgeSummary };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ loadBadgeSummary }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyBadgesInfoCard);
