import React from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';


const isValidJsonString = (str) => {
    try {
	JSON.parse(str);
    } catch (e) {
	return false;
    }
    return true;
}


export default class RichText extends React.Component {
    constructor(props) {
	super(props);

	const { readOnly } = this.props;
	let contentState;
	let content = this.props.content;


	// Load existing content if valid json
	if (content !== null && isValidJsonString(content)) {
	    contentState = EditorState.createWithContent(
		convertFromRaw(JSON.parse(content))
	    );
	} else {
	    contentState = EditorState.createEmpty();
	}

	this.state = {
	    editorState: contentState,
	}
    }

    render() {
	const { editorState } = this.state;
	const { readOnly } = this.props;

	return (
	  <Editor
	      editorState={editorState}
	      readOnly={true}
	      toolbarHidden={true}
	  />
	);
    }
}

