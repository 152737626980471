import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
const ScoreWrapper = styled.div`
    margin: 0 auto 2rem auto;
    //text-align: center;
    text-transform: uppercase;
    width: ${props => (props.circular ? "120px" : "90%")};
    height: ${props => (props.circular ? "120px" : "115px")};
    max-width: ${props => (props.circular ? "120px" : "380px")};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${props => (props.circular ? "50%" : "10px")};

    box-shadow: -3px -3px 0 #fff, 3px -3px 0 #fff, -3px 3px 0 #fff,
        3px 3px 0 #fff, #0089ff 3px 0 10px;
    // h3 {
    //     margin-top: 2rem;
    //     font-size: 5rem;
    //     color: #00132e;
    //     text-shadow: -3px -3px 1px #fff, 3px -3px 1px #fff, -3px 3px 1px #fff,
    //         3px 3px 1px #fff, #0089ff 3px 0 10px;
    // }
    svg {
        width: 100%;
        height: 100%;
    }

    text {
        text-anchor: middle;
        font-size: 60px;
        fill: none;
        stroke: white;
        stroke-width: 3px;
        stroke-linejoin: round;
    }
`;

class ThisOrThatScore extends Component {
    render() {
        const { circular } = this.props;
        return (
            <ScoreWrapper circular={circular}>
                <svg height="100%" width="100%">
                    <text
                        x="50%"
                        y="54%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                    >
                        {this.props.score}
                    </text>
                </svg>
            </ScoreWrapper>
        );
    }
}

ThisOrThatScore.propTypes = {
    circular: PropTypes.bool
};

ThisOrThatScore.defaultProps = {
    circular: true
};

export default ThisOrThatScore;
