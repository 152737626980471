import { combineReducers } from "redux";

import { routerReducer } from "react-router-redux";

import { authReducer as auth } from "@cortexglobal/cortex-auth-react-router-dom";
import news from "../modules/news/newsReducer";
import points from "../modules/points/pointsReducer";
import redemption from "../modules/redemption/redemptionReducer";
import roles from "../components/roles/roleReducer";
import groups from "../components/groups/groupReducer";
import quiz from "../modules/game/quiz/quizReducer";
import badges from "../modules/badges/badgeReducer";
import comparison from "../modules/game/comparison/comparisonReducer";
import competition from "../modules/competition/competitionReducer";
// import notifications from "../modules/notifications/notificationsReducer";
import {
    notificationsReducer as notifications,
    videoCompetitionReducer as videoCompetition
} from "@cortexglobal/bmw-shared-components";
// import videoCompetition from "../modules/videoCompetition/videoCompetitionReducer";
import champions from "../modules/championsLeague/championsReducer";
import countdown from "../components/countdowns/countDownReducer";

const rootReducer = combineReducers({
    auth,
    news,
    points,
    redemption,
    roles,
    groups,
    router: routerReducer,
    quiz,
    badges,
    comparison,
    competition,
    notifications,
    videoCompetition,
    champions,
    countdown
});

export default rootReducer;
