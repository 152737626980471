import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PullRow, Column, Loading } from "@cortexglobal/rla-components";

import QuizEntries from "./QuizEntries";
import QuizHistoryOverall from "./QuizHistoryOverall";
import QuizHistoryQuarter from "./QuizHistoryQuarter";
import { loadQuizHistory } from "./quizActions";

class QuizHistory extends Component {
    state = {};
    componentDidMount() {
        this.props.loadQuizHistory();
    }

    render() {
        const { history } = this.props;
        // console.log(history);
        if (!history.loaded) {
            return <Loading align="center" />;
        }
        return (
            <React.Fragment>
                <QuizHistoryOverall history={history} />
                <PullRow equaliseChildHeight style={{ marginTop: "1.2rem" }}>
                    <Column
                        small={12}
                        large={6}
                        xlarge={6}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: "2rem",
                        }}
                    >
                        <QuizEntries history={history} />
                    </Column>
                    <Column small={12} large={6} xlarge={6}>
                        <QuizHistoryQuarter history={history} />
                    </Column>
                </PullRow>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { history } = state.quiz;

    return { history };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ loadQuizHistory }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuizHistory);
