import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Column, Row } from "@cortexglobal/rla-components";

import MonthlyCompetitionCard from "./MonthlyCompetitionCard";
import ChampionsLeagueCard from "../../components/infoCards/ChampionsLeagueCard";
import MonthlyPrizeDraw from "../../components/infoCards/MonthlyPrizeDraw";
import VideoWallCard from "../../components/infoCards/VideoWallCard";
import styled from "styled-components";
import CanAccessLeague from "../routing/CanAccessLeague";
import moment from "moment";

const CardLink = styled(Link)`
    text-decoration: none;
`;
const now = moment();
const videoCompetitionClose = moment("2020-04-01", "YYYY-MM-DD");

class HomepageCards extends Component {
    render() {
        const { user } = this.props;

        return (
            <Row style={{ marginBottom: "50px" }}>
                <Column large={4}>
                    <MonthlyPrizeDraw />
                </Column>
                {now.isBefore(videoCompetitionClose) && (
                    <Column large={4}>
                        <VideoWallCard />
                    </Column>
                )}
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn,
    user: state.auth.user
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomepageCards);
