import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import ApprenticeTechnicianImg from "../../img/technician-apprentice.png";

import { getStaffRoles } from "../../components/roles/roleActions";

import { PageTitle, colors } from "@cortexglobal/bmw-shared-components";
import {
    CareerPathwayBlock,
    CurrentCareerPathwayBlock,
} from "../../components/careerPathwayComponents";

const SubTitle = styled.p`
    color: #9f9f9f;
    margin: 0;
    font-size: 1.2em;
`;

class CareerPathway extends Component {
    componentWillMount() {
        if (!this.props.roles.loaded) {
            this.props.getStaffRoles("technician,apprentice");
        }
    }
    render() {
        const { roles, user_start_date, user_role } = this.props;
        // console.log(user_role);
        const next_role_order = user_role.order + 1;

        if (!roles.loaded) {
            return <Loading align="center" />;
        }

        return (
            <div>
                <Row>
                    <Column>
                        <PageTitle title="My career path" />
                        <SubTitle>Your career path to the top</SubTitle>
                    </Column>
                </Row>
                {roles.data.length > 0
                    ? roles.data.map((role) => {
                          console.log(user_role.order, role.order);
                          // Previous Role
                          if (user_role.order > role.order) {
                              return (
                                  <Row
                                      key={role.id}
                                      style={{
                                          marginTop: "1em",
                                      }}
                                  >
                                      <Link
                                          to={`/career-pathway/${role.alias}`}
                                      >
                                          <Column large={8} small={8}>
                                              <CareerPathwayBlock
                                                  role={role.name}
                                                  text="Previous Position:"
                                              />
                                          </Column>
                                          <Column large={4} small={4}>
                                              <div
                                                  style={{
                                                      marginLeft: "-38px",
                                                  }}
                                              >
                                                  <img
                                                      style={{
                                                          width: "99%",
                                                          minHeight: "125px",
                                                          minWidth: "125px",
                                                      }}
                                                      src={role.thumbnail}
                                                  />
                                              </div>
                                          </Column>
                                      </Link>
                                  </Row>
                              );
                          }

                          // Current Role
                          if (user_role.order == role.order) {
                              return (
                                  <Row
                                      key={role.id}
                                      style={{
                                          marginTop: "1em",
                                      }}
                                  >
                                      <Link
                                          to={`/career-pathway/${role.alias}`}
                                      >
                                          <Column large={8} small={8}>
                                              <CurrentCareerPathwayBlock
                                                  role={role.name}
                                                  text="Current Position:"
                                              />
                                          </Column>
                                          <Column large={4} small={4}>
                                              <div
                                                  style={{
                                                      marginLeft: "-38px",
                                                  }}
                                              >
                                                  <img
                                                      style={{
                                                          width: "99%",
                                                          minHeight: "125px",
                                                          minWidth: "125px",
                                                      }}
                                                      src={role.thumbnail}
                                                  />
                                              </div>
                                          </Column>
                                      </Link>
                                  </Row>
                              );
                          }

                          // Next Role
                          if (next_role_order == role.order) {
                              return (
                                  <Row
                                      key={role.id}
                                      style={{
                                          marginTop: "1em",
                                      }}
                                  >
                                      <Link
                                          to={`/career-pathway/${role.alias}`}
                                      >
                                          <Column large={8} small={8}>
                                              <CareerPathwayBlock
                                                  role={role.name}
                                                  text="The next step:"
                                              />
                                          </Column>
                                          <Column large={4} small={4}>
                                              <div
                                                  style={{
                                                      marginLeft: "-38px",
                                                  }}
                                              >
                                                  <img
                                                      style={{
                                                          width: "99%",
                                                          minHeight: "125px",
                                                          minWidth: "125px",
                                                      }}
                                                      src={role.thumbnail}
                                                  />
                                              </div>
                                          </Column>
                                      </Link>
                                  </Row>
                              );
                          }

                          // Other Roles
                          return (
                              <Row
                                  style={{
                                      marginTop: "1em",
                                  }}
                                  key={role.id}
                              >
                                  <Link to={`/career-pathway/${role.alias}`}>
                                      <Column large={8} small={8}>
                                          <CareerPathwayBlock
                                              role={role.name}
                                              text={
                                                  role.name.replace(
                                                      "Technician",
                                                      ""
                                                  ) + " Status:"
                                              }
                                          />
                                      </Column>
                                      <Column large={4} small={4}>
                                          <div
                                              style={{
                                                  marginLeft: "-38px",
                                              }}
                                          >
                                              <img
                                                  style={{
                                                      width: "99%",
                                                      minHeight: "125px",
                                                      minWidth: "125px",
                                                  }}
                                                  src={role.thumbnail}
                                              />
                                          </div>
                                      </Column>
                                  </Link>
                              </Row>
                          );
                      })
                    : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roles: state.roles,
        user_role: state.auth.user.role,
        user_start_date: state.auth.user.additional_props.start_date,
        ...state,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getStaffRoles,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CareerPathway);
