import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormikSelect } from "@cortexglobal/rla-components";

import { getRetailerGroups } from "./groupActions";

class GroupSelect extends Component {
    componentDidMount() {
        if (!this.props.loaded) {
            this.props.getRetailerGroups();
        }
    }
    render() {
        const { label, value, groups, loading } = this.props;
        if (loading) {
            return null;
        }
        return (
            <FormikSelect
                name="group"
                label={label}
                value={value}
                options={groups}
            />
        );
    }
}

const mapStateToProps = state => {
    const { loaded, data: groups } = state.groups;
    return { groups, loaded };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getRetailerGroups
        },
        dispatch
    );

GroupSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
};
GroupSelect.defaultProps = {
    label: "Retailer"
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupSelect);
