import styled from "styled-components";
import React from "react";
import { breakpoints, app } from "@cortexglobal/bmw-shared-components";

const CareerPathwayBox = styled.div`
    background-color: #f0f0f0;
    color: #797979;
    padding-top: 0.5em;
    margin-bottom: 2em;
    padding-bottom: 0.2em;
    text-align: center;
    width: 326px;
    margin-block-start: 0;
    margin-block-end: 0;
    min-height: 125px;
    @media (min-width: ${breakpoints.small}px) {
        width: 285px;
    }
`;

export const CurrentCareerPathwayBox = styled.div`
    background-color: #1c69d4;
    color: white;
    padding-top: 0.5em;
    margin-bottom: 2em;
    padding-bottom: 0.2em;
    text-align: center;
    width: 326px;
    margin-block-start: 0;
    margin-block-end: 0;
    min-height: 125px;
    @media (min-width: ${breakpoints.small}px) {
        width: 285px;
    }
`;

export const CareerPathwaySubtitle = styled.h5`
    text-align: left;
    padding-left: 10px;
    text-align: left;
    margin-block-start: 6px;
    margin-block-end: 6px;
`;

export const CareerPathwayTitle = styled.h2`
    color: #000000;
    text-align: left;
    padding-left: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 1.5em;
    @media (max-width: ${breakpoints.medium}px) {
        font-size: 1.1em;
    }
`;

export const CareerPathwayInfo = styled.h5`
    text-align: left;
    padding-left: 10px;
`;

export const CareerPathwayLinkText = styled.h5`
    text-align: left;
    padding-left: 10px;
    margin-block-end: 0;
    margin-block-start: 0;
    color: #1a69d4;
    margin-top: 2em;
    margin-bottom: 5px;
    font-size: 1em;
`;

export const CareerPathwayBlock = props => (
    <React.Fragment>
        <CareerPathwayBox>
            <CareerPathwaySubtitle>{props.text}</CareerPathwaySubtitle>
            <CareerPathwayTitle>{props.role}</CareerPathwayTitle>
            <CareerPathwayInfo>{props.info}</CareerPathwayInfo>
            <a href="{`/career-pathway/${role.alias}`">
                <CareerPathwayLinkText style={{ color: "#1A69D4" }}>
                    See Requirements
                </CareerPathwayLinkText>
            </a>
        </CareerPathwayBox>
    </React.Fragment>
);

export const CurrentCareerPathwayBlock = props => (
    <React.Fragment>
        <CurrentCareerPathwayBox>
            <CareerPathwaySubtitle style={{ color: "white" }}>
                {props.text}
            </CareerPathwaySubtitle>
            <CareerPathwayTitle>{props.role}</CareerPathwayTitle>
            <CareerPathwayInfo>{props.info}</CareerPathwayInfo>
            <a
                href="{`/career-pathway/${role.alias}`"
                style={{ color: "white" }}
            >
                <CareerPathwayLinkText style={{ color: "white" }}>
                    See Requirements
                </CareerPathwayLinkText>
            </a>
        </CurrentCareerPathwayBox>
    </React.Fragment>
);
