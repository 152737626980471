import React, { Component } from "react";
import styled from "styled-components";

import { Row, Column } from "@cortexglobal/rla-components";
const QuestionWrapper = styled(Row)`
    @media (max-width: ${props => props.theme.breakpoints.large}px) {
        h1 {
            font-size: 1.2rem;
            @media only screen and (max-width: 400px) {
                margin-bottom: 0.5rem;
            }
        }
        h2 {
            font-size: 1rem;
        }
    }
`;
class ComparisonQuestionRow extends Component {
    render() {
        const { question, total } = this.props;
        return (
            <QuestionWrapper center={true}>
                <Column small={9}>
                    <small>Question:</small>
                    <h1>{question}</h1>
                </Column>
                <Column small={3} style={{ textAlign: "right" }}>
                    <small>Current Score:</small>
                    <h1>{total}</h1>
                </Column>
            </QuestionWrapper>
        );
    }
}

export default ComparisonQuestionRow;
