import styled from "styled-components";
import { NavLink } from "react-router-dom";

const MainNavLink = styled(NavLink).attrs({
    exact: true,
    activeClassName: "active"
})`
    display: block;
    width: 100%;
    margin: 0 ${props => props.theme.navigation.navLink.margin}em;
    padding ${props => props.theme.navigation.navLink.padding}em;
    text-decoration: none;
    color: ${props => props.theme.navigation.navLink.color};
    position: relative;
    outline:none;
    
    :hover {
        background: ${props => props.theme.colors.lightGray};
    }
    &.active{
        background: ${props => props.theme.colors.lightGray};
        color: ${props => props.theme.navigation.navLink.color};
    }
    &.disabled{
        color: ${props => props.theme.navigation.navLink.disabledColor};
        cursor:not-allowed;
    }
   
`;

MainNavLink.displayName = "MainNavLink";

MainNavLink.propTypes = {};

MainNavLink.defaultProps = {};
export default MainNavLink;
