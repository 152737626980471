import axios from "axios";

export const LOADING_CHAMPIONS_LEAGUE = "LOADING_CHAMPIONS_LEAGUE";
export const LOAD_CHAMPIONS_LEAGUE = "LOAD_CHAMPIONS_LEAGUE";
export const loadChampionsLeague = (groupUuid = null) => {
	return (dispatch, getState) => {
		//return axios.get(`/api/v1/champions-league/league/${groupUuid}`).then(response => {
		axios.get(`/api/v1/champions-league/league/${groupUuid}`).then(response => {
			const { data } = response.data;
			dispatch({
				type: LOAD_CHAMPIONS_LEAGUE,
				data
			});
		});

		return dispatch({
			type: LOADING_CHAMPIONS_LEAGUE
		});
	}
};
