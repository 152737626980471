import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import { Loading, Modal } from "@cortexglobal/rla-components";

import Header from "./PrivateHeader";
import Footer from "./Footer";
import QuickLinks from "./QuickLinks";
import AppContext from "./AppContext";
import FloatingButton from "./FloatingButton";

import Home from "../../modules/home/Home";
import Redemption from "../../modules/redemption/Redemption";
import Profile from "../../modules/profile/Profile";
import ProfileMyDetails from "../../modules/profile/ProfileMyDetails";
import ProfileQuiz from "../../modules/profile/ProfileQuiz";
import ProfileCompetition from "../../modules/profile/ProfileCompetition";
import EditProfile from "../../modules/profile/EditProfile";
import { Notifications } from "@cortexglobal/bmw-shared-components";
import Contact from "../../modules/contact/Contact";
import ChampionsLeague from "../../modules/championsLeague/ChampionsOverview";
import MonthlyPrizeDraw from "../../modules/competition/monthlyPrize";
import Game from "../../modules/game/Game";
// import VideoCompetition from "../../modules/videoCompetition/VideoCompetition";
// import VideoCompetitionWinners from "../../modules/videoCompetition/VideoCompetitionWinners";
import WeeklyQuiz from "../../modules/game/quiz/WeeklyQuiz";
import ProfileComparisonLeague from "../../modules/profile/ProfileComparisonLeague";
import Competition from "../../modules/competition/Competition";
import CareerPathway from "../../modules/careerPathway/CareerPathway";
import CareerPathwayDetail from "../../modules/careerPathway/CareerPathwayDetail";
import UserBadges from "../../modules/badges/UserBadges";
import ViewBadge from "../../modules/badges/ViewBadge";

import NewsPage from "../../modules/news/NewsPage";
import NewsDetail from "../../modules/news/NewsDetail";

import { OnboardingModal } from "../onboarding/OnboardingModal";

import { getPointsSummary } from "../../modules/points/pointsActions";
import { loadBadgeSummary } from "../../modules/badges/badgeActions";
import { getNotificationsSummary } from "@cortexglobal/bmw-shared-components";

import {
    app,
    breakpoints,
    VideoCompetition,
    VideoCompetitionWinners,
} from "@cortexglobal/bmw-shared-components";
import ComparisonGame from "../../modules/game/comparison/ComparisonGame";
import CanAccessLeague from "../routing/CanAccessLeague";

const AppContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 100%;
    padding-top: ${app.headerHeight};
    padding-bottom: ${app.headerHeight};
    padding-left: 0;
    //z-index: -9999;
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        padding-left: ${app.quickLinkWidth + 20}px;
    }
`;

const now = moment();
const videoCompetitionClose = moment("2020-04-01", "YYYY-MM-DD");

class PrivateApp extends Component {
    state = {
        mobileView: true,
    };

    throttledHandleWindowResize = () => {
        this.setState({ mobileView: window.innerWidth < breakpoints.large });
    };

    componentDidMount() {
        if (!this.props.points.loaded) {
            this.props.getPointsSummary();
        }
        if (!this.props.badgeSummary.loaded) {
            this.props.loadBadgeSummary();
        }
        if (!this.props.notifications.loaded) {
            this.props.getNotificationsSummary();
        }
        this.throttledHandleWindowResize();
        window.addEventListener("resize", this.throttledHandleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.throttledHandleWindowResize);
    }

    render() {
        const { user, group, points, badgeSummary, champions } = this.props;
        const { mobileView } = this.state;

        const notifications = this.props.notifications.data;
        const userCanViewRewards =
            user &&
            Array.isArray(user.permissions) &&
            user.permissions.some((permission) => {
                return permission.alias === "rewards-redeem";
            });

        const userCanViewCareerPathway =
            user && user.role && user.role.alias !== "service-advisor" && user.role.alias !== "sales-executive";
        return (
            <AppContext.Provider
                value={{
                    user,
                    group,
                    points,
                    mobileView,
                    notifications,
                    badgeSummary,
                    //champions
                }}
                style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                }}
            >
                <Header
                    userCanViewRewards={userCanViewRewards}
                    userCanViewCareerPathway={userCanViewCareerPathway}
                />
                <AppContainer
                    style={{
                        paddingTop:
                            user &&
                            user.role &&
                            user.role.type === "head-office"
                                ? parseInt(app.headerHeight) + 20
                                : app.headerHeight,
                    }}
                >
                    {user.loaded && !user.loading ? (
                        <React.Fragment>
                            <Switch
                                style={{
                                    paddingTop: app.headerHeight,
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Route
                                    exact
                                    path="/"
                                    render={() => (
                                        <Home
                                            userCanViewRewards={
                                                userCanViewRewards
                                            }
                                        />
                                    )}
                                />
                                {userCanViewRewards && (
                                    <Route
                                        exact
                                        path="/redeem"
                                        component={Redemption}
                                    />
                                )}
                                <Route exact path="/games" component={Game} />
                                {now.isBefore(videoCompetitionClose) && (
                                    <Route
                                        exact
                                        path="/vehicle-health-check-competition-winners"
                                        component={VideoCompetitionWinners}
                                    />
                                )}
                                {now.isBefore(videoCompetitionClose) && (
                                    <Route
                                        exact
                                        path="/vehicle-health-check-competition"
                                        component={VideoCompetition}
                                    />
                                )}
                                <Route
                                    exact
                                    path="/games/weekly-quiz"
                                    component={WeeklyQuiz}
                                />
                                <Route
                                    exact
                                    path="/competitions"
                                    component={Competition}
                                />
                                <Route
                                    exact
                                    path="/profile"
                                    component={Profile}
                                />
                                {userCanViewCareerPathway && (
                                    <Route
                                        exact
                                        path="/career-pathway"
                                        component={CareerPathway}
                                    />
                                )}
                                {userCanViewCareerPathway && (
                                    <Route
                                        exact
                                        path="/career-pathway/:roleAlias"
                                        component={CareerPathwayDetail}
                                    />
                                )}
                                <Route
                                    exact
                                    path="/profile/competition"
                                    component={ProfileCompetition}
                                />
                                <Route
                                    exact
                                    path="/profile/details"
                                    component={ProfileMyDetails}
                                />
                                <Route
                                    exact
                                    path="/profile/quiz"
                                    component={ProfileQuiz}
                                />
                                <Route
                                    exact
                                    path="/profile/badges"
                                    component={UserBadges}
                                />
                                <Route
                                    exact
                                    path="/profile/badges/:uuid"
                                    component={ViewBadge}
                                />
                                <Route
                                    exact
                                    path="/monthly-prize-draw"
                                    component={MonthlyPrizeDraw}
                                />
                                <Route
                                    exact
                                    path="/games/this-or-that"
                                    component={ComparisonGame}
                                />
                                <Route
                                    exact
                                    path="/profile/this-or-that"
                                    component={ProfileComparisonLeague}
                                />
                                <Route
                                    exact
                                    path="/profile/edit"
                                    component={EditProfile}
                                />
                                <Route
                                    exact
                                    path="/notifications"
                                    component={Notifications}
                                />
                                <Route
                                    exact
                                    path="/contact"
                                    component={Contact}
                                />
                                <Route
                                    exact
                                    path="/news"
                                    component={NewsPage}
                                />
                                <Route
                                    path="/news/:uuid"
                                    component={NewsDetail}
                                />
                            </Switch>
                            <OnboardingModal user={user} />
                        </React.Fragment>
                    ) : (
                        <Loading align="center" />
                    )}
                </AppContainer>
                <QuickLinks
                    userCanViewRewards={userCanViewRewards}
                    userCanViewCareerPathway={userCanViewCareerPathway}
                />
                <FloatingButton userCanViewRewards={userCanViewRewards} />
                <Footer />
            </AppContext.Provider>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, group } = state.auth;
    const { champions } = state;
    const { points } = state;
    const { badgeSummary } = state.badges;
    const notifications = state.notifications.summary;

    return { user, group, points, badgeSummary, notifications, champions };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        { getPointsSummary, getNotificationsSummary, loadBadgeSummary },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PrivateApp);
