import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column } from "@cortexglobal/rla-components";

import SummaryLinkBack from "../../components/SummaryLinkBack";

import UserSummary from "../../components/global/UserSummary";
import ComparisonLeague from "../game/comparison/ComparisonLeague";

import { PageTitle } from "@cortexglobal/bmw-shared-components";
import InfoCards from "../../components/infoCards/InfoCards";
import { loadLeague } from "../../modules/game/comparison/comparisonActions";

class ProfileComparisonLeague extends Component {
    componentDidMount() {
        if (!this.props.league.loaded) {
            this.props.loadLeague();
        }
    }
    render() {
        const { league, user, group } = this.props;
        const retailerLeague = league.data.filter(userScore => {
            return userScore.group_uuid === group.uuid;
        });
        return (
            <div className="page page-profile">
                <UserSummary />
                <Row>
                    <Column>
                        <SummaryLinkBack to="/profile">
                            <PageTitle
                                title="Your Retailer This or That League"
                                subtitle="Who's in the top spot"
                            />
                        </SummaryLinkBack>
                    </Column>
                </Row>

                <Row>
                    <Column large={12} xlarge={8}>
                        <ComparisonLeague league={retailerLeague} user={user} />
                    </Column>
                </Row>
                <InfoCards />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { league } = state.comparison;
    const { user, group } = state.auth;

    return { league, user, group };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadLeague }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileComparisonLeague);
