import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import Header from "./PublicHeader";
import Footer from "./Footer";

import Login from "../../modules/auth/Login";
import ForgottenPassword from "../../modules/auth/ForgottenPassword";
import ResetPassword from "../../modules/auth/ResetPassword";
import RequestAccount from "../../modules/auth/RequestAccount";
import ActivateAccount from "../../modules/auth/ActivateAccount";
import TermsUk from "../../modules/infoPages/TermsUk";
import TermsIe from "../../modules/infoPages/TermsIe";
import { app } from "@cortexglobal/bmw-shared-components";
import ExitSurvey from "../../modules/exitSurvey/ExitSurvey";

const Container = styled.div`
    width: 100%;
    margin: auto;
`;
const AppContainer = styled.div`
    padding-bottom: ${app.headerHeight};
`;

class PublicApp extends Component {
    render() {
        return (
            <Container>
                <Header />
                <AppContainer>
                    <Switch>
                        <Route path="/auth/login" component={Login} />
                        <Route
                            path="/auth/forgotten"
                            component={ForgottenPassword}
                        />
                        <Route path="/auth/reset" component={ResetPassword} />
                        <Route
                            path="/auth/request-account"
                            component={RequestAccount}
                        />
                        <Route
                            path="/auth/invitation/:activation"
                            component={ActivateAccount}
                        />
                        <Route
                            path="/info/uk/terms-and-conditions"
                            component={TermsUk}
                        />
                        <Route
                            path="/info/ie/terms-and-conditions"
                            component={TermsIe}
                        />
                        <Route
                            path="/exit-survey/:survey_code"
                            render={({ match }) => {
                                return (
                                    <ExitSurvey
                                        surveyCode={match.params.survey_code}
                                    />
                                );
                            }}
                        />
                    </Switch>
                </AppContainer>
                <Footer aboveQuickLinks={false} />
            </Container>
        );
    }
}

export default PublicApp;
