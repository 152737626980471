import React, { Component } from "react";
import { Row, Column, Button, PullRow } from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import UserSummary from "../../components/global/UserSummary";
import ProfileDetails from "../../components/ProfileDetails";
import AppContext from "../../components/global/AppContext";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import InfoCards from "../../components/infoCards/InfoCards";
import SummaryLinkBack from "../../components/SummaryLinkBack";

class ProfileMyDetails extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {({ user, group }) => {
                    //console.log(user, group);
                    return (
                        <div className="page page-profile">
                            <UserSummary />
                            <PullRow>
                                <Column large={8}>
                                    <Row>
                                        <Column medium={8}>
                                            {" "}
                                            <SummaryLinkBack to="/profile">
                                                <PageTitle
                                                    title="My details"
                                                    subtitle="A little information about you"
                                                />
                                            </SummaryLinkBack>
                                        </Column>
                                        <Column
                                            medium={4}
                                            style={{
                                                paddingTop: "1.5rem",
                                                textAlign: "right"
                                            }}
                                        >
                                            <Button
                                                element={Link}
                                                to="/profile/edit"
                                            >
                                                Edit profile
                                            </Button>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <ProfileDetails user={user} />
                                    </Row>
                                </Column>
                            </PullRow>
                                <Row style={{ marginTop: "1.5rem" }}>
                                    <Column>
                                    Please be aware your Elite account is reliant on S-Gate and TMSi data being live and accurate. To ensure your Elite account remains active and you continue to have access to all the programmes benefits and rewards, please regularly login into S-Gate.

S-Gate accounts are removed after one year of inactivity which in turn will result in your Elite account being disabled until a new S-Gate is created and re-linked.
                                    </Column>
                                    </Row>
                            <Row>
                                <Row>
                                    <Column
                                        style={{
                                            marginTop: "1.5rem",
                                            paddingTop: "1rem",
                                            borderTop:
                                                "1px solid rgba(0,0,0,0.1)"
                                        }}
                                    >
                                        &nbsp;
                                    </Column>
                                </Row>
                                <InfoCards />
                            </Row>
                        </div>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

// const mapStateToProps = state => {
//     const { user } = state.auth;

//     return { user };
// };

// export default connect(mapStateToProps)(Profile);

export default ProfileMyDetails;
