import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormikSelect } from "@cortexglobal/rla-components";

import { getStaffRoles } from "./roleActions";

class RoleSelect extends Component {
    componentDidMount() {
        if (!this.props.loaded) {
            this.props.getStaffRoles();
        }
    }
    render() {
        const { label, value, roles, loading } = this.props;
        //console.log(value);
        if (loading) {
            return null;
        }
        return (
            <FormikSelect
                name="role"
                label={label}
                value={value}
                options={roles}
            />
        );
    }
}

const mapStateToProps = state => {
    const { loaded, data: roles } = state.roles;
    return { roles, loaded };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getStaffRoles
        },
        dispatch
    );

RoleSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
};
RoleSelect.defaultProps = {
    label: "Job Role"
};
export default connect(mapStateToProps, mapDispatchToProps)(RoleSelect);
