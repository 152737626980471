import axios from "axios";

export const LOADING_WEEKLY_QUIZ = "LOADING_WEEKLY_QUIZ";
export const LOAD_WEEKLY_QUIZ = "LOAD_WEEKLY_QUIZ";
export const loadWeeklyQuiz = () => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADING_WEEKLY_QUIZ
        });
        return axios.get("/api/v1/quiz").then(response => {
            const {
                completed,
                started,
                uuid,
                questions,
                current_question_uuid
            } = response.data.data;
            dispatch({
                type: LOAD_WEEKLY_QUIZ,
                completed,
                started,
                uuid,
                questions,
                current_question_uuid
            });

            if (started && !completed) {
                displayQuestionCountdown(dispatch, getState);
            }
        });
    };
};

export const START_QUIZ = "START_QUIZ";

export const startQuiz = () => {
    return (dispatch, getState) => {
        return axios.put(`/api/v1/quiz`).then(response => {
            const { started, current_question_uuid } = response.data.data;

            dispatch({
                type: START_QUIZ,
                started,
                current_question_uuid
            });
            displayQuestionCountdown(dispatch, getState);
        });
    };
};

export const LOAD_NEXT_QUESTION = "LOAD_NEXT_QUESTION";
export const DECREMENT_ANSWER_COUNTDOWN_TIMER =
    "DECREMENT_ANSWER_COUNTDOWN_TIMER";
export const SELECT_QUIZ_ANSWER = "SELECT_QUIZ_ANSWER";
export const DISPLAY_QUIZ_ANSWER = "DISPLAY_QUIZ_ANSWER";

export const submitQuizAnswer = (answered, questionUuid, answerUuid = null) => {
    return (dispatch, getState) => {
        dispatch({
            type: SELECT_QUIZ_ANSWER,
            selectedAnswerUuid: answerUuid,
            answered: answered
        });
        return axios
            .put(`/api/v1/quiz`, {
                answered: answered,
                questionUuid: questionUuid,
                answerUuid: answerUuid
            })
            .then(response => {
                const {
                    completed,
                    started,
                    uuid,
                    questions,
                    current_question_uuid
                } = response.data.data;

                dispatch({
                    type: DISPLAY_QUIZ_ANSWER,
                    questions
                });

                const displayAnswerCountdown = () => {
                    const {
                        answerCountdownTimer,
                        completed: currentCompleted
                    } = getState().quiz.current;

                    if (answerCountdownTimer > 0 && !currentCompleted) {
                        dispatch({
                            type: DECREMENT_ANSWER_COUNTDOWN_TIMER
                        });

                        setTimeout(displayAnswerCountdown, 1000);
                    } else {
                        dispatch({
                            type: LOAD_NEXT_QUESTION,
                            completed,
                            started,
                            current_question_uuid,
                            uuid
                        });
                        displayQuestionCountdown(dispatch, getState);
                    }
                };
                displayAnswerCountdown();
            });
    };
};

export const DECREMENT_QUESTION_COUNTDOWN_TIMER =
    "DECREMENT_QUESTION_COUNTDOWN_TIMER";
const displayQuestionCountdown = (dispatch, getState) => {
    const {
        questionCountdownTimer,
        questionUuid,
        completed,
        selectedAnswerUuid
    } = getState().quiz.current;
    if (completed || selectedAnswerUuid !== "") {
        return;
    }
    if (questionCountdownTimer > 0) {
        dispatch({
            type: DECREMENT_QUESTION_COUNTDOWN_TIMER
        });

        setTimeout(displayQuestionCountdown, 1000, dispatch, getState);
    } else {
        dispatch(submitQuizAnswer(false, questionUuid));
    }
};

export const LOADING_QUIZ_HISTORY = "LOADING_QUIZ_HISTORY";
export const LOAD_QUIZ_HISTORY = "LOAD_QUIZ_HISTORY";
export const loadQuizHistory = () => {
    return dispatch => {
        dispatch({
            type: LOADING_QUIZ_HISTORY
        });
        return axios.get("/api/v1/quiz/history").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_QUIZ_HISTORY,
                data
            });
        });
    };
};
