import React from "react";
import { Modal, Row, Column } from "@cortexglobal/rla-components";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const TermsModal = (props) => {
    const { visible, closeModal, newTab } = props;

    return (
        <Modal visible={visible} onClose={closeModal}>
            <Row>
                <Column>
                    <h2>Terms and Conditions</h2>
                </Column>
            </Row>
            <Row>
                <Column>
                    <p>
                        I work at a BMW retailer based in the{" "}
                        <NavLink
                            onClick={closeModal}
                            to="/info/uk/terms-and-conditions"
                            target={newTab === true ? "_blank" : ""}
                        >
                            UK
                        </NavLink>
                    </p>
                </Column>
            </Row>
            <Row>
                <Column>
                    <p>
                        I work at a BMW retailer based in the{" "}
                        <NavLink
                            onClick={closeModal}
                            to="/info/ie/terms-and-conditions"
                            target={newTab === true ? "_blank" : ""}
                        >
                            Republic of Ireland
                        </NavLink>
                    </p>
                </Column>
            </Row>
        </Modal>
    );
};

TermsModal.defaultPropTypes = {
    visible: false,
    newTab: false,
};

TermsModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    newTab: PropTypes.bool,
};

export default TermsModal;
