import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const StyledLink = styled(Link)`
    display: flex;
    padding-left: 16px;
    position: relative;
    flex: 1;
    cursor: pointer;
    text-decoration: none;
    flex-direction: row;
    align-items: center;
    transition: all 0.5s ease;
    &:before {
        transition: all 0.2s ease;
        content: "";
        height: 10px;
        width: 10px;
        background: transparent;
        transform: rotate(45deg);
        border-left: 2px solid ${props => props.theme.colors.primary};
        border-bottom: 2px solid ${props => props.theme.colors.primary};
        position: absolute;
        left: 2px;
    }
    &:hover,
    &:focus,
    &:active {
        padding-left: 21px;
        &:before {
            left: 0;
        }
    }
`;

const SummaryContainer = styled.div`
    flex: 11;
`;

const SummaryLinkBack = props => {
    return (
        <StyledLink to={props.to}>
            <SummaryContainer>{props.children}</SummaryContainer>
        </StyledLink>
    );
};
export default SummaryLinkBack;
