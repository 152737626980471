import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "@cortexglobal/bmw-shared-components";

const ErrorWrapper = styled.div`
    overflow: auto;
    margin: 1rem 0;
    padding: 1.5rem;
    text-align: center;
    background: ${props =>
        props.background ? props.background : colors.alert};
    color: ${colors.white};
`;

const ErrorText = styled.h2`
    font-size: 20px;
    line-height: 20px;
    margin: 0;
`;

class ErrorColumn extends Component {
    render() {
        return (
            <ErrorWrapper {...this.props}>
                <ErrorText>{this.props.errorText}</ErrorText>
            </ErrorWrapper>
        );
    }
}

export default ErrorColumn;
