import React, { Component } from "react";
import { Row, Column } from "@cortexglobal/rla-components";

import { Link } from "react-router-dom";

import { Card, CardLabel } from "../../components/infoCards/cardComponents";
import QuizImg from "../../img/homepage-cards/1200x600px_Weekly-Quiz.jpg";
import ThisOrThatImg from "../../img/this-or-that-button.jpg";
import UserSummary from "../../components/global/UserSummary";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

class Game extends Component {
    render() {
        return (
            <div className="page">
                <UserSummary />
                <Row>
                    <Column large={4}>
                        <PageTitle
                            title="My games"
                            subtitle="Play the quiz and check your scores"
                        />
                    </Column>
                </Row>
                <Row>
                    <Column large={6} xlarge={4}>
                        <Card
                            className="slit-in-vertical"
                            style={{
                                backgroundImage: `url(${QuizImg})`,
                                animationDelay: "250ms"
                            }}
                        >
                            <Link to="/games/weekly-quiz">
                                <p>Take the weekly quiz</p>
                            </Link>
                        </Card>
                        <CardLabel>
                            {" "}
                            <small>
                                3 questions, 3 competition entries available
                                each week.
                            </small>
                        </CardLabel>
                    </Column>
                    <Column large={6} xlarge={4}>
                        <Card
                            className="slit-in-vertical"
                            style={{
                                backgroundImage: `url(${ThisOrThatImg})`,
                                animationDelay: "250ms"
                            }}
                        >
                            <Link to="/games/this-or-that">
                                <p>PLAY THIS OR THAT</p>
                            </Link>
                        </Card>
                        <CardLabel>
                            {" "}
                            <small>
                                How many questions can you get right in a row?
                            </small>
                        </CardLabel>
                    </Column>
                </Row>
            </div>
        );
    }
}

export default Game;
