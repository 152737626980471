import React from "react";
import {
    Row,
    Column,
    Button,
    InputField,
    Range,
    Modal
} from "@cortexglobal/rla-components";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RangeWrapper = styled(Row)`
    width: 100%;
    min-height: 60px;
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        width: 66%;
    }
    .input-range {
        padding-top: 50px;
        margin-bottom: -30px;
    }
    .input-range__track--active {
        background-color: #1c69d4;
    }
    .input-range__slider {
        background-color: black;
        width: 22px;
        height: 22px;
        top: -7px;
        color: white;
        border: none !important;
    }

    .input-range__slider:before {
        content: "£";
        display: block;
        text-align: center;
        font-weight: 800;
        line-height: 1.6em;
    }
    .input-range__label--value {
        display: none;
    }
    .input-range__track {
        border-radius: 0px;
        height: 12px;
    }
    .input-range__label--min,
    .input-range__label--max {
        bottom: 2.4rem;
        font-weight: 800;
    }
    .input-range__label--max {
        color: black;
    }
    .input-range__label--max:before {
        display: inline-block;
        content: "£";
        position: relative;
        left: -5px;
    }
    .input-range__label--max:after {
        display: inline-block;
        content: "|";
    }
    .input-range__label--min {
        display: none;
    }
    .input-range__label--max .input-range__label-container {
        left: -5px;
    }
`;
const InputWrapper = styled(Row)`
    width: 100%;
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        width: 33%;
    }
`;

const PointsInput = styled(InputField)`
    border: none !important;
    width: 100%;
    color: #9f9f9f;
    background-color: #eeeeee;
    border-radius: 0px;
    height: 40px;
`;

class OrderForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pointsToRedeem: 0,
            amazonValue: 0,
            showApproveRedemption: false
        };
    }

    onClose = () => {
        this.setState({ showApproveRedemption: false });
    };

    approveRedemption = event => {
        event.preventDefault();

        this.setState({ showApproveRedemption: true });
    };

    selectMaxPoints = event => {
        event.preventDefault();
        //this.setState(state => ({ pointsToRedeem: this.props.maxPoints }));
        this.updateTotals({ value: this.props.maxPoints });
    };

    updateTotals = selectedPoints => {
        let selectedPointsValue = parseInt(selectedPoints.value);

        if (
            selectedPointsValue > this.props.maxPoints ||
            selectedPointsValue < 0
        ) {
            return;
        }
        //console.log("selected points value: " + selectedPointsValue);
        this.setState({
            pointsToRedeem: selectedPointsValue,
            newPoints: this.props.maxPoints - selectedPointsValue,
            amazonValue: selectedPointsValue //This allows for a multiplier if required
        });
    };

    submitAmazonOrderPoints = () => {
        const { pointsToRedeem } = this.state;
        this.props.submitAmazonOrderPoints(pointsToRedeem);
    };

    render() {
        const { user } = this.props;
        const {
            amazonValue,
            pointsToRedeem,
            showApproveRedemption
        } = this.state;
        const amazonValueSafe = isNaN(amazonValue) ? 0 : amazonValue;

        return (
            <React.Fragment>
                <form onSubmit={this.submitAmazonOrderPoints}>
                    <RangeWrapper>
                        <Column
                            style={{
                                textAlign: "center",
                                marginBottom: 12
                            }}
                        >
                            <FontAwesomeIcon
                                icon={["fab", "amazon"]}
                                className="amazon"
                                size="2x"
                            />{" "}
                            <span style={{ fontSize: "2rem" }}>
                                &pound;
                                {amazonValueSafe.toFixed(2)}
                            </span>
                        </Column>
                        <Column medium={12}>
                            <Range
                                name="selectedPoints"
                                value={
                                    isNaN(pointsToRedeem) ? 0 : pointsToRedeem
                                }
                                minValue={0}
                                maxValue={this.props.maxPoints}
                                onChange={this.updateTotals}
                            />
                            <small
                                style={{ display: "block", margin: "1rem 0" }}
                            >
                                Slide to how much of your rewards you want to
                                redeem
                            </small>
                        </Column>
                    </RangeWrapper>
                    <InputWrapper>
                        <Column
                            style={{
                                textAlign: "center",
                                marginBottom: 12
                            }}
                        >
                            <p style={{ fontWeight: 800 }}>
                                Or enter the exact amount:
                            </p>

                            <PointsInput
                                name="selectedPoints"
                                type="number"
                                placeholder={`£${this.props.maxPoints}`}
                                min={0}
                                max={this.props.maxPoints}
                                value={
                                    isNaN(pointsToRedeem) ? "" : pointsToRedeem
                                }
                                onChange={this.updateTotals}
                            />
                            {/*
                                    <Button
                                        type="primary"
                                        expanded
                                        onClick={this.selectMaxPoints}
                                    >
                                        Redeem All Points
                                    </Button>
                                        */}
                        </Column>

                        <Column>
                            <Button
                                expanded
                                disabled={
                                    isNaN(pointsToRedeem) || pointsToRedeem <= 0
                                }
                                onClick={this.approveRedemption}
                            >
                                Generate Code
                            </Button>
                        </Column>

                        <Column style={{ marginTop: "1rem" }}>
                            <a
                                href="/info/terms-and-conditions"
                                target="_blank"
                            >
                                Terms and Conditions
                            </a>
                        </Column>
                    </InputWrapper>
                </form>
                {showApproveRedemption && (
                    <Modal
                        visible={showApproveRedemption}
                        onClose={this.onClose}
                    >
                        <h1>You are about to redeem &pound;{pointsToRedeem}</h1>
                        <p>
                            By clicking ‘Redeem’ an Amazon redemption email will
                            be sent to the following email address:
                            <br />
                            <strong>{user.email}</strong>
                        </p>
                        <p>Are you sure you want to proceed?</p>
                        <Button
                            expanded
                            disabled={
                                isNaN(pointsToRedeem) || pointsToRedeem <= 0
                            }
                            onClick={this.submitAmazonOrderPoints}
                        >
                            Redeem
                        </Button>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

export default OrderForm;
