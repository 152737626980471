import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Loading, Row, Column } from "@cortexglobal/rla-components";
import { formatPosition } from "@cortexglobal/bmw-shared-components";

import { NavLink } from "react-router-dom";
import UserSummary from "../../components/global/UserSummary";
import UserDetails from "../../components/global/UserDetails";
import AppContext from "../../components/global/AppContext";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import InfoCards from "../../components/infoCards/InfoCards";
import SummaryLink from "../../components/SummaryLink";

import { loadQuizHistory } from "../game/quiz/quizActions";
import { loadLeague } from "../game/comparison/comparisonActions";
import { loadCurrentCompetition } from "../competition/competitionActions";
import MyBadgesSummary from "../badges/MyBadgesSummary";

const SummaryContainer = styled.div`
    text-align: center;
    h3 {
        font-size: 0.8rem;
        color: ${props => props.theme.colors.darkGray};
        margin-bottom: 0.2rem;
    }
    strong {
        font-size: 3rem;
    }
`;
const FlexColumn = styled(Column)`
    display: flex;
    flex-direction: column;
`;
const CloseSpace = styled.div`
    margin-top: -1.8rem;
`;
const TextLink = styled(NavLink)`
    color: ${props => props.theme.colors.primary};
    position: absolute;
    right: 0;
    bottom: 30px;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
`;
class Profile extends Component {
    state = {
        currentCompetitionError: false
    };
    componentDidMount() {
        if (!this.props.quizHistory.loaded) {
            this.props.loadQuizHistory();
        }

        if (!this.props.comparisonLeague.loaded) {
            this.props.loadLeague();
        }

        this.props.loadCurrentCompetition().catch(e => {
            if (e.response && e.response.status && e.response.status === 404) {
                this.setState({
                    currentCompetitionError: e.response.data.message
                });
            }
        });
    }

    generateQuizTotal = history => {
        const totals = history.data.reduce(
            (totals, quiz) => {
                quiz.questions.map(question => {
                    totals.total++;
                    question.correct ? totals.correct++ : totals.wrong++;
                    return question;
                });
                return totals;
            },
            { total: 0, correct: 0, wrong: 0 }
        );

        return totals.total > 0
            ? Math.round((totals.correct / totals.total) * 100)
            : 0;
    };

    generateComparisonPosition = () => {
        const { comparisonLeague, user } = this.props;

        const userLeaguePosition =
            comparisonLeague.data.findIndex(userEntry => {
                return userEntry.user_uuid === user.uuid;
            }) + 1;

        return userLeaguePosition;
    };

    renderCompetitionSummary = () => {
        const { currentCompetitionError } = this.state;
        const { currentCompetition } = this.props;
        // console.log(currentCompetitionError);
        if (currentCompetitionError) {
            return (
                <SummaryContainer>
                    <h3>Current entries</h3>
                    <strong>0</strong>
                </SummaryContainer>
            );
        }

        if (!currentCompetition.loaded) {
            return <Loading align="center" />;
        } else {
            return (
                <SummaryContainer>
                    <h3>Current entries</h3>
                    <strong>
                        {currentCompetition.data.user_entries.length}
                    </strong>
                </SummaryContainer>
            );
        }
    };

    render() {
        const { quizHistory } = this.props;
        return (
            <AppContext.Consumer>
                {({ user, group, points }) => {
                    //console.log(user, group);
                    return (
                        <div className="page page-profile">
                            <UserSummary />
                            <Row equaliseChildHeight>
                                <FlexColumn large={6} xlarge={4}>
                                    <PageTitle
                                        title="My details"
                                        subtitle="A little information about you"
                                    />
                                    <CloseSpace />
                                    <SummaryLink to="/profile/details">
                                        <UserDetails showGroup={false} />
                                    </SummaryLink>
                                </FlexColumn>
                                <FlexColumn large={6} xlarge={4}>
                                    <PageTitle
                                        title="My weekly quiz stats"
                                        subtitle="What's you knowledge like?"
                                    />
                                    <CloseSpace />
                                    <SummaryLink to="/profile/quiz">
                                        {quizHistory.loaded ? (
                                            <SummaryContainer>
                                                <h3>Overall score</h3>
                                                <strong>
                                                    {this.generateQuizTotal(
                                                        quizHistory
                                                    )}
                                                    %
                                                </strong>
                                            </SummaryContainer>
                                        ) : (
                                            <Loading align="center" />
                                        )}
                                    </SummaryLink>
                                </FlexColumn>

                                <FlexColumn large={6} xlarge={4}>
                                    <PageTitle
                                        title="This or That rankings"
                                        subtitle="How do you match up?"
                                    />
                                    <CloseSpace />
                                    <SummaryLink to="/profile/this-or-that">
                                        {quizHistory.loaded ? (
                                            <SummaryContainer>
                                                <h3>National Position</h3>
                                                <strong>
                                                    {formatPosition(
                                                        this.generateComparisonPosition()
                                                    )}
                                                </strong>
                                            </SummaryContainer>
                                        ) : (
                                            <Loading align="center" />
                                        )}
                                    </SummaryLink>
                                </FlexColumn>
                                <FlexColumn large={6} xlarge={4}>
                                    <PageTitle
                                        title="Monthly competition"
                                        subtitle="See your monthly competition information"
                                    />
                                    <CloseSpace />
                                    <SummaryLink to="/profile/competition">
                                        {this.renderCompetitionSummary()}
                                    </SummaryLink>
                                </FlexColumn>

                                <FlexColumn large={6} xlarge={4}>
                                    <div
                                        style={{
                                            position: "relative",
                                            paddingRight: "60px"
                                        }}
                                    >
                                        <PageTitle
                                            title="My Badges"
                                            subtitle="Can you complete all the challenges?"
                                        />
                                        <TextLink to="/profile/badges">
                                            View all
                                        </TextLink>
                                    </div>
                                    <CloseSpace />
                                    <SummaryLink to="/profile/badges">
                                        <MyBadgesSummary />
                                    </SummaryLink>
                                </FlexColumn>
                            </Row>

                            <Row>
                                <Row>
                                    <Column
                                        style={{
                                            marginTop: "3rem",
                                            paddingTop: "1rem",
                                            borderTop:
                                                "1px solid rgba(0,0,0,0.1)"
                                        }}
                                    >
                                        &nbsp;
                                    </Column>
                                </Row>
                            </Row>
                            <InfoCards user={user} points={points}/>
                        </div>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

const mapStateToProps = state => {
    const { user } = state.auth;
    const { history: quizHistory } = state.quiz;
    const { league: comparisonLeague } = state.comparison;
    const { current: currentCompetition } = state.competition;

    return {
        user,
        quizHistory,
        comparisonLeague,
        currentCompetition
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadQuizHistory,
            loadLeague,
            loadCurrentCompetition
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);
