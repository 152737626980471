import React, { Component } from "react";
// import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { colors } from "@cortexglobal/bmw-shared-components";

class QuestionSummary extends Component {
    render() {
        const { selectedQuestion } = this.props;
        const userSelectedAnswer = selectedQuestion.question.answers.filter(
            answer => {
                return answer.uuid === selectedQuestion.answer;
            }
        )[0];
        return (
            <Row>
                <h2>{selectedQuestion.question.question}</h2>
                <Column medium={6}>
                    <h5>The correct answer</h5>
                    <Column medium={3}>
                        <FontAwesomeIcon
                            icon="check-circle"
                            transform="grow-3"
                            style={{
                                fontSize: "1.5rem",
                                color: colors.altSuccess
                            }}
                        />
                    </Column>
                    <Column medium={9}>
                        <h3>{selectedQuestion.correct_answer.answer}</h3>
                    </Column>
                </Column>
                <Column medium={6}>
                    <h5>Your answer</h5>
                    <Column medium={3}>
                        {selectedQuestion.correct ? (
                            <FontAwesomeIcon
                                icon="check-circle"
                                transform="grow-3"
                                style={{
                                    fontSize: "1.5rem",
                                    color: colors.altSuccess
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon="times-circle"
                                transform="grow-3"
                                style={{
                                    fontSize: "1.5rem",
                                    color: colors.alert
                                }}
                            />
                        )}
                    </Column>
                    <Column medium={9}>
                        <h3>
                            {userSelectedAnswer
                                ? userSelectedAnswer.answer
                                : "You didn't select an answer for this question"}
                        </h3>
                    </Column>
                </Column>

                <p>{selectedQuestion.correct_answer.summary}</p>
            </Row>
        );
    }
}

export default QuestionSummary;
