import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import find from "lodash/find";
import { Loading } from "@cortexglobal/rla-components";
import { PageTitle, formatPosition } from "@cortexglobal/bmw-shared-components";
import { loadLeague } from "./comparisonActions";
import ThisOrThatScore from "../../../components/ThisOrThatScore";
import ComparisonLeague from "./ComparisonLeague";

class ComparisonLeagueSummary extends Component {
    componentDidMount() {
        this.props.loadLeague();
    }
    render() {
        const { league, user, group } = this.props;

        if (!league.loaded || league.loading) {
            return <Loading />;
        }

        const retailerLeague = league.data.filter(userScore => {
            //console.log(userScore.group_uuid, group.uuid, user);
            return userScore.group_uuid === group.uuid;
        });
        const userLeagueEntry = find(league.data, userEntry => {
            return userEntry.user_uuid === user.uuid;
        });
        return (
            <React.Fragment>
                {" "}
                <PageTitle
                    title="National League"
                    subtitle="How are you doing nationally?"
                />
                <ThisOrThatScore
                    score={formatPosition(userLeagueEntry.national_position)}
                    circular={false}
                />
                {userLeagueEntry.region_position && (
                    <React.Fragment>
                        <PageTitle
                            title="Regional League"
                            subtitle="How are you doing regionally?"
                        />

                        <ThisOrThatScore
                            score={formatPosition(
                                userLeagueEntry.region_position
                            )}
                            circular={false}
                        />
                    </React.Fragment>
                )}
                <PageTitle
                    title="Your Retailer League"
                    subtitle="Who's in the top spot"
                />
                <ComparisonLeague
                    league={retailerLeague}
                    user={user}
                    loading={league.loading}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { league } = state.comparison;
    const { user, group } = state.auth;

    return { league, user, group };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ loadLeague }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComparisonLeagueSummary);
