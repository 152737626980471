import React from "react";
import { initialState } from "@cortexglobal/cortex-auth-react-router-dom";
import { initialState as pointsInitialState } from "../../modules/points/pointsReducer";
import { initialState as badgesInitialState } from "../../modules/badges/badgeReducer";
import { initialState as initialStateChampions } from "../../modules/championsLeague/championsReducer";

const AppContext = React.createContext({
    user: initialState.user,
    group: initialState.group,
    points: pointsInitialState,
    badgeSummary: badgesInitialState.badgeSummary,
    //champions: initialStateChampions,
    mobileView: true
});

export default AppContext;
