import axios from "axios";
export const RESET_ORDER = "RESET_ORDER";

export function resetOrder() {
    return {
        type: RESET_ORDER
    };
}

export const CHANGE_POINTS_BALANCE = "CHANGE_POINTS_BALANCE";
export function changePointsBalance(amount) {
    return (dispatch, getState) => {
        dispatch({ type: CHANGE_POINTS_BALANCE, amount });
    };
}

export const LOADING_POINTS_SUMMARY = "LOADING_POINTS_SUMMARY";
export const LOAD_POINTS_SUMMARY = "LOAD_POINTS_SUMMARY";

export function getPointsSummary() {
    return (dispatch, getState) => {
        if (getState().points.loading) {
            return false;
        }

        dispatch({ type: LOADING_POINTS_SUMMARY });

        return axios
            .get(`/api/v1/points/summary`)
            .then(response => response.data.data)
            .then(data => {
                dispatch({
                    type: LOAD_POINTS_SUMMARY,
                    data
                });
            })
            .catch(e => e);
    };
}
