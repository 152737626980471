import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Fab = styled.div`
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 10;

    &.open {
        > div {
            svg {
                transform: rotate(90deg);
            }
        }

        a {
            transition-duration: 250ms;

            &:nth-of-type(1) {
                transform: translate(-80px, 0);
            }

            &:nth-of-type(2) {
                transform: translate(-60px, -60px);
            }

            &:nth-of-type(3) {
                transform: translate(0, -80px);
            }
        }
    }

    @media screen and (min-width: ${(props) =>
            props.theme.breakpoints.large}px) {
        display: none;
    }
`;

const Button = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    text-align: center;
    z-index: 4;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.primary};
    box-shadow: 0px 2px 10px -1px ${(props) => props.theme.colors.darkGray};
    z-index: 4;
    font-size: 1.5rem;
    cursor: pointer;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: cubic-bezier(0.33, 0.44, 0.44, 1.14);

    &:hover {
        transform: translate(0, -2px);
        box-shadow: 0px 4px 10px 1px ${(props) => props.theme.colors.darkGray};
    }

    &:active {
        transform: translate(0, -1px);
        box-shadow: 0px 4px 10px 0px ${(props) => props.theme.colors.darkGray};
    }

    svg {
        transition-property: all;
        transition-duration: 350ms;
        transition-timing-function: cubic-bezier(0.33, 0.44, 0.44, 1.14);
    }
`;

const Action = styled(Link)`
    background-color: #f8f8f8;
    height: 54px;
    width: 54px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: cubic-bezier(0.33, 0.44, 0.44, 1.14);
    z-index: 1;
    flex-direction: column;
    color: #c7c7c7;
    font-size: 8px;

    &:nth-of-type(1) {
        transition-delay: 0ms;
    }

    &:nth-of-type(2) {
        transition-delay: 40ms;
    }

    &:nth-of-type(3) {
        transition-delay: 80ms;
    }
`;

const Icon = styled.div`
    display: block;
    margin: 0;
    font-size: 1.5rem;
    line-height: 0;
    padding: 0;
    color: ${(props) => props.theme.colors.primary};

    &.ico-pound {
        font-size: 0.75rem;
        border: solid 2px ${(props) => props.theme.colors.primary};
        border-bottom: 0;
        position: relative;
        padding: 2px 4px;
        margin-bottom: 10px;

        &::after,
        &::before {
            content: "";
            width: 70%;
            height: 2px;
            background-color: ${(props) => props.theme.colors.primary};
            position: absolute;
            bottom: 0;
            display: block;
        }

        &::before {
            left: 0;
            transform-origin: 0 50%;
            transform: rotate(30deg);
        }

        &::after {
            right: 0;
            transform-origin: 100% 50%;
            transform: rotate(-30deg);
        }
    }
`;

class FloatingButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    handleOnClick = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
        return true;
    };

    render() {
        const { userCanViewRewards } = this.props;
        return (
            <Fab className={this.state.isOpen ? "open" : ""}>
                <Button onClick={this.handleOnClick}>
                    <span className="fa fa-plus"></span>
                </Button>
                {userCanViewRewards && (
                    <Action exact to="/redeem" onClick={this.handleOnClick}>
                        <Icon className="ico-pound">
                            <FontAwesomeIcon icon="pound-sign" />
                        </Icon>{" "}
                        Redeem
                    </Action>
                )}
                <Action exact to="/games" onClick={this.handleOnClick}>
                    <Icon>
                        <FontAwesomeIcon icon="gamepad" />
                    </Icon>{" "}
                    Games
                </Action>
                <Action exact to="/news" onClick={this.handleOnClick}>
                    <Icon>
                        <FontAwesomeIcon icon="broadcast-tower" />
                    </Icon>{" "}
                    News
                </Action>
            </Fab>
        );
    }
}

export default FloatingButton;
