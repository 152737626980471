import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import { selectTime } from "./newsActions";

const Filter = styled.div`
    display: flex;
    justify-content: between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
        flex: 1;
        white-space: nowrap;
        margin: 0;
        font-family: "bmw_groupbold";
        font-size: 18px;
    }

    @media screen and (min-width: ${(props) =>
            props.theme.breakpoints.large}px) {
        position: absolute;
        right: 10px;
        top: 0;
        margin: 0;
        transform: translateY(calc(-100% - 20px));
        min-width: 250px;

        h2 {
            padding-right: 20px;
        }
    }
`;

const TimeSelect = styled.label`
    position: relative;
    flex: 1;
    text-align: right;
    color: ${(props) => props.theme.colors.primary};
    overflow: hidden;
    font-family: "bmw_groupbold";
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;

    &::after {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        border-left: solid 2px ${(props) => props.theme.colors.primary};
        border-bottom: solid 2px ${(props) => props.theme.colors.primary};
        transform-orgin: center;
        transform: translate(4px, -2px) rotate(-45deg);
    }

    select {
        position: absolute;
        opacity: 0;
        width: 100%;
        left: 0;
        top: 0;

        // &::-webkit-calendar-picker-indicator {
        //   background: transparent;
        //   bottom: 0;
        //   color: transparent;
        //   cursor: pointer;
        //   height: auto;
        //   left: 0;
        //   position: absolute;
        //   right: 0;
        //   top: 0;
        //   width: auto;
        // }
    }
`;

class NewsFilter extends Component {
    componentWillMount() {
        if (!this.props.selectedTime) {
            this.props.selectTime(new Date());
        }
    }

    generateTimeOptions = (pastYear) => {
        const d = new Date();
        const endYear = d.getFullYear();
        const startYear = endYear - (pastYear || 1);
        const arr = [];
        for (let i = endYear; i >= startYear; i--) {
            [...Array(12).keys()].reverse().map((x) => {
                if (i !== endYear) {
                    arr.push({
                        month: x,
                        year: i,
                        text: this.getMonthDisplay(
                            new Date(i, x, 1, 0, 0, 0, 0)
                        ),
                    });
                } else if (x <= d.getMonth()) {
                    arr.push({
                        month: x,
                        year: i,
                        text: this.getMonthDisplay(
                            new Date(i, x, 1, 0, 0, 0, 0)
                        ),
                    });
                }
            });
        }
        return arr;
    };

    getMonthDisplay = (date) => {
        const d = new Date(date);
        const m = d.getMonth();
        const y = d.getFullYear(0);

        const format = new Intl.DateTimeFormat("en-UK", { month: "long" })
            .format;
        const month = [...Array(12).keys()].map((m) =>
            format(new Date(Date.UTC(2022, m % 12)))
        )[m];

        return month + " " + y;
    };

    handleOnChange = (e) => {
        this.props.selectTime(e.target.value);
        this.props.onChange(e.target.value);
    };

    render() {
        const options = this.generateTimeOptions(1);
        return (
            <Filter>
                <h2>{this.getMonthDisplay(this.props.selectedTime)}</h2>
                <TimeSelect>
                    <select onChange={this.handleOnChange}>
                        {options.map((o, i) => {
                            return (
                                <option
                                    key={i}
                                    value={`${o.year}-${o.month + 1}`}
                                >
                                    {o.text}
                                </option>
                            );
                        })}
                    </select>
                    Change month
                </TimeSelect>
            </Filter>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedTime: state.news.selectedTime,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ selectTime: selectTime }, dispatch);
}
export default connect(mapStateToProps, matchDispatchToProps)(NewsFilter);
