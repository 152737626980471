import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Row,
    Column,
    SubmitButton,
    FormikInput
} from "@cortexglobal/rla-components";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { updateUser } from "./profileActions";

import UserSummary from "../../components/global/UserSummary";
import AppContext from "../../components/global/AppContext";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import SummaryLinkBack from "../../components/SummaryLinkBack";

const LoginSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    personal_email: Yup.string()
        .email("Invalid email")
        .nullable(),
    phone: Yup.string().nullable(),
    password: Yup.string()
        .min(8, "Your password must contain at least 8 characters")
        .max(70, "Too Long!")
        .matches(/^([a-zA-Z+]+[0-9+]+)$/, {
            excludeEmptyString: true,
            message:
                "You must provide a password containing letters and numbers"
        }),
    password_confirmation: Yup.string().when("password", {
        is: value => value !== undefined,
        then: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("You must enter a matching password")
    })
});

class EditProfile extends Component {
    state = { submitting: false };

    submitForm = (values, { setErrors }) => {
        console.log(values);
        // return false;
        this.setState(state => ({
            submitting: true
        }));

        this.props
            .updateUser(values)
            .then(data => {
                this.setState(state => ({
                    submitting: false
                }));
            })
            .catch(e =>
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    this.setState(state => ({
                        submitting: false
                    }));
                })
            );
    };

    render() {
        //console.log(this.props);
        const { submitting } = this.state;
        return (
            <AppContext.Consumer>
                {({ user, group }) => (
                    <div className="page page-profile">
                        <UserSummary />
                        <Row>
                            <Column medium={10}>
                                <SummaryLinkBack to="/profile/details">
                                    <PageTitle
                                        title="Edit Your details"
                                        subtitle="A little information about you"
                                    />
                                </SummaryLinkBack>
                            </Column>
                            <div className="form-container">
                                <React.Fragment>
                                    <Formik
                                        initialValues={{
                                            first_name: user.first_name,
                                            last_name: user.last_name,
                                            personal_email: user
                                                .additional_props.personal_email
                                                ? user.additional_props
                                                      .personal_email
                                                : "",
                                            phone: user.additional_props.phone
                                                ? user.additional_props.phone
                                                : "",
                                            password: "",
                                            password_confirmation: ""
                                        }}
                                        onSubmit={this.submitForm}
                                        validationSchema={LoginSchema}
                                        className="form-group"
                                    >
                                        {({ values, errors }) => (
                                            <Form className="form-group">
                                                <Column large={4}>
                                                    <FormikInput
                                                        type="text"
                                                        name="first_name"
                                                        label="First Name"
                                                        value={
                                                            values.first_name
                                                        }
                                                        placeholder="Joe"
                                                    />
                                                    <FormikInput
                                                        type="text"
                                                        name="last_name"
                                                        label="Last Name"
                                                        value={values.last_name}
                                                        placeholder="Bloggs"
                                                    />
                                                </Column>
                                                <Column large={4}>
                                                    <FormikInput
                                                        type="email"
                                                        name="personal_email"
                                                        label="Personal Email"
                                                        value={
                                                            values.personal_email
                                                        }
                                                        placeholder="joe.bloggs@email.com"
                                                        autoComplete="false"
                                                    />
                                                    <FormikInput
                                                        type="tel"
                                                        name="phone"
                                                        label="Mobile"
                                                        value={values.phone}
                                                        placeholder="0777123456"
                                                    />
                                                </Column>
                                                <Column large={4}>
                                                    <FormikInput
                                                        type="password"
                                                        name="password"
                                                        label="Password"
                                                    />
                                                    <FormikInput
                                                        type="password"
                                                        name="password_confirmation"
                                                        label="Password Confirmation"
                                                    />
                                                    {/*<FormikDatePicker
                                                        name="start_date"
                                                        label="Start Date"
                                                        value={
                                                            values.start_date
                                                        }
                                                        maxDate={new Date()}
                                                    />
                                                    <RoleSelect
                                                        value={values.role}
                                                    />*/}
                                                </Column>
                                                <Column small={4}>
                                                    <SubmitButton
                                                        expanded
                                                        submitting={submitting}
                                                        disabled={submitting}
                                                    >
                                                        Save
                                                    </SubmitButton>
                                                </Column>
                                            </Form>
                                        )}
                                    </Formik>
                                </React.Fragment>
                            </div>
                        </Row>
                    </div>
                )}
            </AppContext.Consumer>
        );
    }
}

const mapStateToProps = state => {
    const { user } = state.auth;

    return { user };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateUser
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProfile);
