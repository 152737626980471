import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    SubmitButton,
    FormikInput,
    FormikCheckbox,
    FormikDatePicker,
    InputError,
    Panel,
    Row,
    Column,
} from "@cortexglobal/rla-components";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { login } from "@cortexglobal/cortex-auth-redux";

// import LoginImg from "../../img/bmw-service-image-tester.jpg";
import LoginImg from "../../img/bmw-hero-min.jpeg";

import RoleSelect from "../../components/roles/RoleSelect";
import GroupSelect from "../../components/groups/GroupSelect";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import TermsModal from "../../components/global/TermsModal";

const ViewTerms = styled.span`
    font-weight: 800;
    font-size: 0.7rem;
    cursor: pointer;
`;

const Hero = styled.div`
    background: url(${LoginImg});
    background-size: cover;
    background-position: center;
    max-width: 100%;
    min-height: 200px;
    margin-bottom: 30px;
`;

const LoginSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string(),
    start_date: Yup.string().required("Please select a start date"),

    role: Yup.string().required("Please select a role"),
    group: Yup.string().required("Please select a retailer"),
    password: Yup.string()
        .min(8, "Your password must contain at least 8 characters")
        .max(70, "Too Long!")
        .matches(/^([a-zA-Z0-9]+)$/, {
            excludeEmptyString: true,
            message:
                "You must provide a password containing letters and numbers",
        })
        .required("Required"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    external_ref: Yup.string()
        .min(5, "Your TMSI ID is too short")
        .max(10, "Your TMSI ID is too long")
        .required("Required"),
    terms: Yup.boolean().oneOf([true], "Must Accept Terms and Conditions"),
});
class RequestAccount extends Component {
    constructor(props) {
        super(props);

        this.showTermsModal.bind(this);
        this.closeTermsModal.bind(this);
    }

    state = {
        credentialsError: false,
        submitting: false,
        success: false,
        termsModalVisible: false,
    };

    submitForm = (values, { setErrors }) => {
        // console.log(values);
        // return false;
        this.setState((state) => ({
            credentialsError: false,
            submitting: true,
        }));

        axios
            .post("/api/v1/request-account", values)
            .then(({ data: { data } }) => {
                console.log(data);
                this.setState((state) => ({
                    submitting: false,
                    success: true,
                }));
            })
            .catch(
                (e) =>
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);
                    })
                // .catch(e => {
                //     if (
                //         e.response &&
                //         e.response.status &&
                //         e.response.status === 401
                //     ) {
                //         this.setState(state => ({
                //             credentialsError: e.response.data.message
                //         }));
                //     }
                // })
            );

        return true;
    };

    showTermsModal = (e) => {
        this.setState({ termsModalVisible: true });
    };

    closeTermsModal = (e) => {
        this.setState({ termsModalVisible: false });
    };

    render() {
        const { redirectToReferer, loggedInChecked } = this.props;
        const { termsModalVisible } = this.state;

        if (redirectToReferer && loggedInChecked) {
            const { from } = this.props.location.state || {
                from: { pathname: "/" },
            };

            return <Redirect to={from} />;
        }
        const { credentialsError, submitting, success } = this.state;
        if (success) {
            return (
                <Panel type="success">
                    <p style={{ margin: "0 1.2rem", paddingBottom: "1.2rem" }}>
                        You have successfully requested an account. Your manager
                        will need to approve your account.
                    </p>
                </Panel>
            );
        }

        return (
            <div className="page page-register">
                <Hero />
                <div className="form-container">
                    <Row>
                        <Column large={3}>
                            <PageTitle
                                title="Welcome to the BMW Group Elite Portal"
                                subtitle="Create an account"
                            />
                        </Column>
                    </Row>
                    <React.Fragment>
                        <Formik
                            initialValues={{
                                first_name: "",
                                last_name: "",
                                email: "",
                                personal_email: "",
                                phone: "",
                                start_date: "",
                                role: "",
                                group: "",
                                password: "",
                                password_confirmation: "",
                                external_ref: "",
                                terms: false,
                            }}
                            onSubmit={this.submitForm}
                            validationSchema={LoginSchema}
                            className="form-group"
                        >
                            {({ values, errors }) => (
                                <Form className="form-group">
                                    <Row>
                                        <Column large={3}>
                                            <FormikInput
                                                type="text"
                                                name="first_name"
                                                label="First Name*"
                                                placeholder="Joe"
                                            />
                                            <FormikInput
                                                type="text"
                                                name="last_name"
                                                label="Last Name*"
                                                placeholder="Bloggs"
                                            />
                                            <FormikInput
                                                type="text"
                                                name="external_ref"
                                                label="TMSI User ID*"
                                                placeholder="c123456"
                                            />
                                        </Column>
                                        <Column large={3}>
                                            <FormikInput
                                                type="email"
                                                name="email"
                                                label="Email*"
                                                placeholder="joe.bloggs@email.com"
                                            />
                                            <FormikInput
                                                tooltip="By completing this field you are
                                            agreeing to receive emails relating
                                            to the BMW Group Elite Programme. If
                                            you leave this field blank your
                                            emails will be sent to your company
                                            email address."
                                                type="email"
                                                name="personal_email"
                                                label="Personal Email (optional)"
                                                placeholder="joe.bloggs@email.com"
                                            />
                                            <FormikInput
                                                tooltip="By completing this field you are
                                                agreeing to receive SMS messages
                                                relating to the BMW Group Elite
                                                Programme."
                                                type="tel"
                                                name="phone"
                                                label="Mobile (optional)"
                                                placeholder="0777123456"
                                            />
                                        </Column>
                                        <Column large={3}>
                                            <GroupSelect label="Retailer*" />
                                            <RoleSelect label="Job Role*" />
                                            <FormikDatePicker
                                                name="start_date"
                                                label="Start Date*"
                                                value={values.start_date}
                                            />
                                        </Column>
                                        <Column large={3}>
                                            <FormikInput
                                                type="password"
                                                name="password"
                                                label="Password*"
                                            />
                                            <FormikInput
                                                type="password"
                                                name="password_confirmation"
                                                label="Password Confirmation*"
                                            />
                                        </Column>
                                        {credentialsError && (
                                            <InputError
                                                error={credentialsError}
                                            />
                                        )}
                                    </Row>
                                    <Row>
                                        <Column large={3}>
                                            <FormikCheckbox
                                                name="terms"
                                                label="I agree to the terms and conditions*"
                                                inline
                                                errorStyle={{
                                                    marginTop: "-0.3rem",
                                                }}
                                            />{" "}
                                            <ViewTerms
                                                onClick={this.showTermsModal}
                                            >
                                                View
                                            </ViewTerms>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column large={3}>
                                            <SubmitButton
                                                expanded
                                                label="Sign Up"
                                                submitting={submitting}
                                                disabled={success}
                                            />
                                        </Column>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </React.Fragment>
                </div>
                <TermsModal
                    visible={termsModalVisible}
                    closeModal={this.closeTermsModal}
                    newTab={true}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { redirectToReferer, loggedInChecked } = state.auth;
    return { redirectToReferer, loggedInChecked };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            login,
        },
        dispatch
    );
RequestAccount.propTypes = {
    location: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestAccount);
