import {
    AMAZON_GIFT_CARD_ORDER_PROCESSING,
    LOAD_AMAZON_GIFT_CARD_ORDER,
    LOAD_AMAZON_GIFT_CARD_ORDER_ERROR,
    LOAD_REDEMPTION_HISTORY,
    REDEMPTION_HISTORY_LOADING
} from "./redemptionActions";

export const initialState = {
    processing: false,
    loaded: false,
    loading: false,
    order: {},
    history: [],
    error: false,
    errorMessage: ""
};

export default function summary(state = initialState, action) {
    switch (action.type) {
        case AMAZON_GIFT_CARD_ORDER_PROCESSING:
            return { ...state, processing: action.processing };

        case LOAD_AMAZON_GIFT_CARD_ORDER:
            return {
                ...state,
                processing: false,
                error: false,
                errorMessage: "",
                order: action.order
            };

        case LOAD_AMAZON_GIFT_CARD_ORDER_ERROR:
            return {
                ...state,
                processing: false,
                error: true,
                errorMessage: action.error
            };

        case REDEMPTION_HISTORY_LOADING:
            return { ...state, loading: action.loading };

        case LOAD_REDEMPTION_HISTORY:
            return {
                ...state,
                loading: false,
                loaded: true,
                history: action.data
            };

        default:
            return state;
    }
}
