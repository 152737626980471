import React from "react";
import { Column } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { Hero, Wrapper } from "./sharedComponents";

const Terms = () => {
    return (
        <div className="page page-home">
            <Hero />
            <Wrapper>
                <Column medium={12}>
                    <PageTitle
                        title="BMW & MINI Elite"
                        subtitle="Loyalty Programme T&amp;Cs"
                    />

                    <h2>BMW Elite Programme</h2>

                    <ol>
                        <li>
                            The BMW & MINI Ireland Rewards Programme described
                            below (the 'Programme') is only applicable to the
                            network of authorised technicians and/or maintenance
                            technicians ('Technicians'), Apprentices, and
                            Service Advisors operated by BMW Automotive
                            (Ireland) Limited ('BMW Ireland').
                        </li>
                        <li>
                            This Programme is meant for all current Technicians
                            and for all Technicians, Apprentices, and Service
                            Advisors about to be employed by BMW or MINI
                            authorised distributors ('Retailers').
                        </li>
                        <li>
                            The rewards and prizes for this Programme consist of
                            redeemable credit or at BMW Group Ireland's
                            discretion, another form of non-cash prize (the
                            'Rewards').
                        </li>
                        <li>
                            The Programme's portal may host leagues, challenges,
                            games, trips and spot prizes in which Technicians,
                            Apprentices, and Service Advisors may enter or
                            compete from time to time.
                        </li>
                        <li>
                            In order to be eligible for the Programme,
                            Technicians, Apprentices, and Service Advisors must
                            have passed all compulsory BMW training and must be
                            correctly signed up on TMSi and assigned eligible
                            Market Function Code.
                        </li>
                        <li>
                            Upon entry into this initiative, all participants
                            are deemed to have accepted and agreed to be bound
                            by the terms and conditions outlined in this
                            document and any additional terms and conditions
                            detailed in the relevant parts of this website or
                            programme communications, describing the programme,
                            of which the entry instructions form part. Any
                            infringement of such terms is a violation of the
                            Programme and may result in Rewards being forfeited.
                        </li>
                        <li>
                            Each Technician, Apprentice, and Service Advisor
                            must have a valid login and profile on the portal of
                            the Programme in order to qualify for Rewards.
                        </li>
                        <li>
                            Each Technician, Apprentice, and Service Advisor
                            enrolled on the Programme is representing their
                            respective Retailer and the BMW & MINI Brands, and
                            as such must conduct themselves in a professional
                            manner at all times.
                        </li>
                        <li>
                            Any potential winners of Rewards, trips or
                            experiences must be 18 years of age or older.
                        </li>

                        <li>
                            By participating in the Programme and related
                            initiatives, all entrants agree to participate in
                            any publicity arising from a Reward.
                        </li>

                        <li>
                            Any reward received under this programme may be
                            subject to tax, which shall be the primary
                            responsibility of the recipient. Without prejudice
                            to the foregoing and only should a tax liability
                            arise, BMW Ireland will pay to the Retailer the then
                            basic rate income tax liability (currently 20% as at
                            01.01.2020) in respect of all reward made to
                            Technician in this Programme and the Retailer is
                            responsible for discharging this amount. The
                            recipient is responsible for any USC, PRSI or other
                            social security costs and higher income tax
                            ratepayers are responsible for additional income tax
                            liabilities arising. BMW Ireland Limited will
                            provide upon request confirmation of the amounts
                            paid to the participant's Retailer. BMW Ireland may
                            exercise its discretion to contribute further to tax
                            liabilities for certain specified challenges, games,
                            trips, spot prizes or end of year events.
                        </li>

                        <li>
                            A Technician must be employed by the BMW Retailer
                            when receiving any Reward.
                        </li>

                        <li>
                            If a Technician's contract is terminated or notice
                            of termination is served, he/she will no longer be
                            part of the Programme nor entitled to any Rewards.
                            If a BMW Elite account has remaining rewards to be
                            redeemed after a contract is terminated or served
                            notice of termination, the rewards can no longer be
                            redeemed as the account will be deleted.
                        </li>

                        <li>
                            If a Technician serves notice of resignation BMW
                            Ireland reserves the right to disqualify a
                            Technician from the Technician Rewards Programme.
                            Any remaining rewards awaiting redemption post
                            notice of resignation will be rewarded at the
                            discretion of the BMW Group Retailer.
                        </li>

                        <li>
                            <strong>
                                BMW Ireland reserves the right to remove a
                                Technician, Apprentice or Service Advisor from
                                the Programme, the Programme's portal and/or any
                                additional leagues, challenges, games, trips and
                                spot prizes who has either infringed the terms
                                and conditions of this Programme or the spirit
                                in which the Programme is operated.{" "}
                            </strong>
                        </li>

                        <li>
                            Families of Technicians, Apprentices, Service
                            Advisors, or other Retailer employees, or other
                            people under the age of 18 are ineligible to
                            participate in the Programme. Entrants must reside
                            in the UK or Ireland and be employed by the official
                            BMW & MINI Retailers.
                        </li>

                        <li>
                            Any proposed Reward can be withdrawn from a
                            Technician, Apprentice or Service Advisor, who has
                            infringed the terms, conditions, or spirit of this
                            Programme.
                        </li>

                        <li>
                            BMW Ireland reserves the right in its sole
                            discretion to alter, amend or withdraw the Programme
                            in whole or in part at any time without notice or
                            compensation. BMW does not need to correspond about
                            the Rewards or the Programme.
                        </li>

                        <li>
                            In the event of any dispute, the decision of BMW
                            Ireland will be final in all matters and no
                            correspondence will be entered into in this regard.
                        </li>

                        <li>
                            All Technicians, Apprentices and Service Advisors
                            enrolled in the programme, must be subscribed to all
                            emails and communications from
                            info@bmwgroupelite.co.uk.{" "}
                        </li>

                        <li>
                            By entering competitions, quizzes and playing games,
                            Technicians, Apprentices and Service Advisors
                            consent to their data and results being shared
                            within BMW & MINI Group UK and ROI for training and
                            monitoring purposes.{" "}
                        </li>

                        <li>
                            Technicians, Apprentices, Service Advisors, and
                            managers may request help from the BMW Group Elite
                            support team but are responsible for the maintenance
                            and up-keep of their user accounts and TMSi. Please
                            refer to the Retailer guide for roles and
                            responsibilities.
                        </li>

                        <li>
                            Due to the responsibility of approving rewards and
                            Amazon credits, BMW Elite require only ONE
                            Aftersales responsible individual to have manager
                            access to the rewards programme for their current
                            BMW Group Retailer.{" "}
                        </li>

                        <li>
                            It is the responsibility of Technicians,
                            Apprentices, Service Advisors or Service Managers to
                            notify BMW Group Elite Support Team on
                            info@bmwgroupelite.co.uk of any Retailer changes,
                            including new starters, transfers, or manager
                            changes.
                        </li>
                    </ol>

                    <h2>Technician Rewards Programme</h2>

                    <ol>
                        <li>
                            All Technicians employed by Retailers prior to
                            01/03/2020 are each deemed an 'Existing Technician'.
                            Those employed by Retailers on or after that date is
                            each deemed a 'New Technician'.
                        </li>
                        <li>
                            Existing Technicians will be rewarded with £1,000
                            worth of Amazon credit every December on the date
                            agreed by BMW (UK) Limited.
                        </li>
                        <li>
                            New Technicians will be rewarded with £500 worth of
                            Amazon credit following the successful completion of
                            their probation and a further £500 worth of Amazon
                            credit reward on completing 12-months service. After
                            completion of 12 months service, new Technicians
                            will be rewarded £1,000 worth of Amazon credit every
                            year on completing another 12-months service. The
                            employing Retailer has the sole discretion to extend
                            this probation period where necessary and only
                            reward the new Technician on the successful
                            completion of this extended probation period.
                        </li>
                        <li>
                            In order to be eligible for the above rewards
                            (points 7 & 8), Technicians must be enrolled on the
                            Programme prior to the date of their first reward
                            due date. This includes the date set in December by
                            BMW Ireland & the anniversary of start date for new
                            starters.
                        </li>
                        <li>
                            In order to be eligible for rewards, Technicians
                            should be set up prior to their probation so their
                            rewards can be triggered automatically by our
                            system.
                        </li>
                    </ol>
                </Column>
            </Wrapper>
        </div>
    );
};

export default Terms;
