import React, { Component } from "react";
import ReactGA from "react-ga";
import Raven from "raven-js";
import axios from "axios";
//Google Analytics
const gtm = "UA-73251467-5";

ReactGA.initialize(gtm, {
    debug: false
});

const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = pathname => {
        axios
            .post("/api/v1/page-viewed", {
                pathname,
                hostname: window.location.hostname
            })
            .then(response => {})
            .catch(err => {
                //console.log("err", err);
                Raven.captureException(err);
            });
        if (!process.env.REACT_APP_LOG_PAGE_VIEWS) {
            return;
        }

        ReactGA.set({
            pathname,
            ...options
        });
        ReactGA.pageview(pathname);
    };

    const HOC = class extends Component {
        componentDidMount() {
            const { pathname } = this.props.location;
            trackPage(pathname);
        }

        componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;
            //console.log(nextProps.location);
            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
};

export default withTracker;
