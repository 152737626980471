import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading } from "@cortexglobal/rla-components";

import {
    BodyRow,
    BodyColumn,
    RowWrapper,
    HeaderColumn,
    HeaderRow
} from "../../components/table/index";

import { getRedemptionHistory } from "./redemptionActions";

import { SectionTitle } from "@cortexglobal/bmw-shared-components";

class RedemptionHistory extends React.Component {
    componentDidMount() {
        this.props.getRedemptionHistory();
    }

    render() {
        const { history, loading, loaded } = this.props;

        return (
            <div className="page page-redeem">
                <Row>
                    <Column>
                        <SectionTitle
                            title="Redemption History"
                            subtitle="Check your previous redemptions"
                        />
                    </Column>
                </Row>

                {loading || !loaded ?
                <Loading align="center" />
            :
                <Row>
                    {history.length > 0 ? (
                        <React.Fragment>
                            <HeaderRow alwaysShow>
                                <HeaderColumn small={8}>Date</HeaderColumn>
                                <HeaderColumn small={4}>Amount</HeaderColumn>
                            </HeaderRow>
                            {history.map(redemption => {
                                return (
                                    <RowWrapper key={redemption.uuid}>
                                        <BodyRow>
                                            <BodyColumn small={8}>
                                                <span>
                                                    {redemption.awarded_at}
                                                </span>
                                            </BodyColumn>
                                            <BodyColumn small={4}>
                                                &pound;{-redemption.amount}
                                            </BodyColumn>
                                        </BodyRow>
                                    </RowWrapper>
                                );
                            })}
                        </React.Fragment>
                    ) : (
                        <Column>
                            <h4>You have yet to make any redemptions</h4>
                        </Column>
                    )}
                </Row> }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { history, loading, loaded } = state.redemption;
    return {
        history,
        loading,
        loaded
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getRedemptionHistory
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedemptionHistory);
