import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const StyledLink = styled(Link)`
    transition: all 0.5s ease;
    position: relative;
    display: flex;
    flex: 1;
    margin-top: 1rem;
    padding: 1.25rem 1rem;
    border-radius: 5px;
    background: ${props => props.theme.colors.lightGray};
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
    flex-grow: 1;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    &:after {
        transition: all 0.2s ease;
        content: "";
        position: absolute;
        height: 15px;
        width: 15px;
        background: transparent;
        transform: rotate(45deg);
        border-right: 2px solid ${props => props.theme.colors.primary};
        border-top: 2px solid ${props => props.theme.colors.primary};
        right: 2rem;
    }
    &:hover,
    &:focus,
    &:focus {
        padding-left: 0.75rem;
        &:after {
            right: 1.5rem;
        }
    }
`;

const SummaryContainer = styled.div`
    flex: 11;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
const LinkIcon = styled.div`
    flex: 1;
    //font-size: 4rem;
    color: ${props => props.theme.colors.primary};
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SummaryLink = props => {
    return (
        <StyledLink to={props.to}>
            <SummaryContainer>{props.children}</SummaryContainer>
            <LinkIcon>{/* <FontAwesomeIcon icon="angle-right" /> */}</LinkIcon>
        </StyledLink>
    );
};
export default SummaryLink;
