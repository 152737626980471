import React, { Component } from "react";
import styled from "styled-components";
import { PullRow, Row, Column, Modal } from "@cortexglobal/rla-components";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SectionTitle } from "@cortexglobal/bmw-shared-components";
import LinkStyleButton from "../../../components/LinkStyleButton";
import { colors } from "@cortexglobal/bmw-shared-components";

import QuestionSummary from "./QuestionSummary";

const CurrentQuizQuestionResult = styled(Column)`
    text-align: center;
    position: relative;
    padding: 10px;
    background: ${props => {
        return props.theme.colors.lightGray;
    }};
    &:after {
        content: "";
        width: 1px;
        position: absolute;
        background: #dedede;
        right: 0;
        top: 18.5px;
        bottom: 18.5px;
    }
    &:last-of-type {
        &:after {
            display: none;
        }
    }
    h3 {
        font-size: 14px;
        line-height: 12px;
        color: ${props => {
            return props.theme.colors.altGray;
        }};
        font-weight: normal;
    }
    button {
        font-size: 12px;
        line-height: 12px;
        color: ${props => {
            return props.theme.colors.altGray;
        }};
        font-weight: normal;
    }
`;

const SummaryColumn = styled(Column)`
    margin-top: 1.2rem;
    color: ${props => props.theme.colors.mediumGray};
    p {
        margin-bottom: 0;
    }
    strong {
        display: block;
        font-weight: 900;
        font-family: "bmw_groupbold", "bmw_groupregular", "Arial", sans-serif;
        color: ${props => props.theme.colors.black};
    }
`;
const Link = styled(NavLink)`
    color: ${props => props.theme.colors.altGray};
    margin-top: ${props => props.theme.spacing.margin}rem;
    position: absolute;
    right: ${props => props.theme.spacing.margin / 2}rem;
    bottom: 0;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
`;
class CurrentQuizSummary extends Component {
    state = {
        selectedQuestion: {},
        showModal: false
    };

    showQuestionModal = question => {
        this.setState({
            selectedQuestion: question,
            showModal: true
        });
    };

    hideQuestionModal = () => {
        this.setState({
            selectedQuestion: {},
            showModal: false
        });
    };
    render() {
        const { currentQuiz } = this.props;
        const entryCount = currentQuiz.questions.reduce((count, question) => {
            if (question.correct) {
                count++;
            }

            return count;
        }, 0);
        const { showModal, selectedQuestion } = this.state;
        return (
            <div>
                {false && JSON.stringify(currentQuiz)}

                <Row>
                    <Column large={4}>
                        <SectionTitle
                            title="This week's Quiz"
                            subtitle="How did you get on?"
                        />
                    </Column>
                </Row>

                <Row>
                    <Column large={12} xlarge={8}>
                        {currentQuiz.questions.map(question => {
                            return (
                                <CurrentQuizQuestionResult
                                    medium={4}
                                    key={question.question.uuid}
                                >
                                    <h3>Question {question.order}</h3>
                                    <div>
                                        {question.correct ? (
                                            <FontAwesomeIcon
                                                icon="check-circle"
                                                transform="grow-3"
                                                style={{
                                                    fontSize: "1.5rem",
                                                    color: colors.altSuccess
                                                }}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon="times-circle"
                                                transform="grow-3"
                                                style={{
                                                    fontSize: "1.5rem",
                                                    color: colors.alert
                                                }}
                                            />
                                        )}
                                    </div>

                                    <LinkStyleButton
                                        onClick={this.showQuestionModal.bind(
                                            this,
                                            question
                                        )}
                                    >
                                        Review
                                    </LinkStyleButton>
                                </CurrentQuizQuestionResult>
                            );
                        })}
                    </Column>
                </Row>

                <Row>
                    <Column large={12} xlarge={8}>
                        <PullRow equaliseChildHeight>
                            <SummaryColumn small={8}>
                                <p>
                                    <strong>
                                        You've earned {entryCount}{" "}
                                        {entryCount === 1 ? "entry" : "entries"}
                                    </strong>{" "}
                                    into this months prize draw
                                </p>
                            </SummaryColumn>
                            <Column
                                small={4}
                                style={{
                                    margin: "1.2rem 0",
                                    position: "relative"
                                }}
                            >
                                <Link exact to="/competitions">
                                    More info
                                </Link>
                            </Column>
                        </PullRow>
                        <Row
                            style={{
                                margin: "1.2rem 0",
                                borderTop: "1px solid rgba(0,0,0,0.1)"
                            }}
                        />
                    </Column>
                </Row>
                {showModal && (
                    <Modal visible={showModal} onClose={this.hideQuestionModal}>
                        <QuestionSummary selectedQuestion={selectedQuestion} />
                    </Modal>
                )}
            </div>
        );
    }
}

export default CurrentQuizSummary;
